import styled from "styled-components";

export const OrderagainStyled = styled.div`
  .orderdetail {
    padding: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid rgba(233, 212, 212, 0.8);
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .orderdetail-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .orderdetail-header-left {
    display: flex;
  }

  .profile-pic {
    margin-right: 16px;
    border-radius: 8px;
    background: #e1ebf4;
  }

  .patient-info h2 {
    margin: 0;
    color: #313131;
    font-size: 16px;
    font-weight: 500;
  }

  .patient-info .patient-id {
    color: #8e8181;
    font-size: 14px;
    font-weight: 400;
  }

  .patient-info p {
    margin: 4px 0;
    color: #313131;
    font-size: 14px;
    font-weight: 400;
  }

  .orderdetail-header-right {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    display: inline-grid;
  }

  .accordion-item {
    border-radius: 5px;
    background: #f1faff;
    margin-bottom: 8px;
    border: 1px solid #f4f4f4;
  }

  .accordion {
    --bs-accordion-bg: #f1faff;
  }

  .order-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }

  .accordion-header {
    background-color: #f1faff;
  }

  .accordion-body {
    background-color: #f1faff;
  }
  .close-icon,
  .edit-icon {
    cursor: pointer;
  }

  .status {
    margin-right: 8px;
    color: #3ac00a;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.3px;
  }

  .total-margin {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .status.delivered {
    color: green;
  }

  .status.pending {
    color: orange;
  }

  .status.cancelled {
    color: red;
  }

  .orderdetail-footer {
    display: flex;
    gap: 12px;
    margin-left: auto;
    align-items: center;
  }

  .bill-info p,
  .bill-total p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }

  .order-again-button {
    cursor: pointer;
    border-radius: 5px;
    background: var(--Primary, #9747ff);
    color: var(--white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
  }

  .custom-table {
    width: 100%;
    overflow-x: auto;
  }

  .custom-table .ant-table-thead {
    background: #dcf1fd;
    border-radius: 5px;
  }

  .custom-table .ant-table-thead > tr > th {
    background: #dcf1fd;
    border: none;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 10px 22px;
  }

  .custom-table .ant-table-tbody > tr > td {
    color: #756767;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 10px 22px;
  }

  .custom-table .ant-table-tbody > tr {
    margin: 10px 0;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 20px;
  }

  .left-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .left-controls .search-bar {
    justify-content: left;
  }

  .title {
    color: #747070;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }

  .controls .search-bar {
    margin-bottom: 0;
  }

  .search-bar {
    display: flex;
    align-items: center;
    padding: 5px;
    width: 310px;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
  }

  .search-bar svg {
    margin-right: 5px;
  }

  .search-bar input {
    border: none;
    outline: none;
    padding: 5px;
    background: transparent;
    color: #ccc;
    font-size: 14px;
    font-weight: 400;
  }

  .savebtn {
    color: var(--white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--Primary, #9747ff);
  }

  .add-patient-button {
    padding: 12px 15px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 40px;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 10px;
    background: #fff;
  }

  .add-patient-button {
    margin-left: auto;
    padding: 12px 20px;
    border-radius: 10px;
    background: var(--Primary, #9747ff);
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
    color: var(--white, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .custom-dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    gap: 46px;
    width: 124px;
    height: 40px;
    padding: 9px 15px;
    flex-shrink: 0;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: #fff;
    border: none;
  }

  .custom-dropdown .dropdown-menu {
    border: none;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .button-sort {
    display: flex;
    gap: 18px;
  }

  .right-buttons {
    display: flex;
    gap: 12px;
  }

  .create-orderbtn {
    color: #9474ff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border: #fff;
  }

  .upload-prescription-button {
    border-radius: 3px;
    border: 1px solid #ccc;
    background: var(--white, #fff);
    color: #6f5e5e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    .controls {
      display: block;
      text-align: center;
    }
    .left-controls {
      display: block;
    }
    .search-bar {
      width: 100%;
      margin-bottom: 12px !important;
    }
    .upload-prescription-button {
      margin-bottom: 12px;
    }
    .right-buttons {
      justify-content: center;
    }
    .button-sort {
      text-align: center;
      justify-content: center;
    }
    .add-patient-button {
      margin: 0%;
    }
    .searchplace {
      display: block !important;
    }
    .lefttags button {
      margin-bottom: 12px !important;
    }
    .search-medicine-section {
      display: block !important;
    }

    .previewbtns .preview,
    .previewbtns .bookpatient {
      padding: 10px 30px !important;
    }
    .note-section textarea {
      width: 100% !important;
    }
    .add-button {
      margin-bottom: 12px !important;
    }
  }

  .edit-section {
    margin-top: 20px;
    padding: 20px;
    border-top: 1px solid #ddd;
  }

  .search-issue-section {
    display: flex;
    gap: 12px;
  }

  .search-medicine-section,
  .procedures-section {
    margin-bottom: 20px;
  }

  .search-bar {
    display: flex;
    align-items: center;
  }

  .search-bar input {
    width: 100%;
    border-radius: 4px;
  }
  .search-medicine-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    margin-bottom: 12px;
  }

  .search-bar {
    flex: 1;
  }

  .medicine-details {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex: 3;
  }

  .medicine-details input,
  .medicine-details select {
    padding: 8px;
    flex: 1;
    width: 130px;
    border-radius: 5px;
    border: 1px solid #efdada;
    background: var(--white, #fff);
  }

  .note-section {
    margin-top: 10px;
    width: 100%;
  }

  .note-section h4 {
    margin: 0;
    font-size: 16px;
    color: #777;
  }

  .note-section textarea {
    width: 50%;
    height: 80px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .add-button {
    /* display: inline-block; */
    padding: 10px 20px;
    background-color: #6f42c1;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: inline-end;
  }

  .searchplace {
    display: flex;
    margin-bottom: 12px;
  }

  .lefttags button {
    border-radius: 5px;
    border: 1px solid #efdada;
    background: var(--white, #fff);
    margin: 0px 4px;
    padding: 8px 20px;
  }
  .previewbtns {
    float: inline-end;
    display: flex;
    gap: 12px;
  }
  .preview {
    border-radius: 5px;
    border: 1px solid #0cae6a;
    background: var(--white, #fff);
    color: #0cae6a;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 10px 80px;
  }
  .bookpatient {
    border-radius: 5px;
    border: 1px solid var(--Primary, #9747ff);
    background: var(--Primary, #9747ff);
    color: var(--white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 10px 60px;
  }
  .modal-header-content{
    display: flex;
  }
`;
