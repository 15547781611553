import styled from "styled-components";

export const MyProfileStyled = styled.div`
  display: grid;
  grid-template-rows: 1fr 0.8fr 1fr 1fr 1fr;
  font-family: Poppins;
  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bannnerGradient {
    background: linear-gradient(200deg, #7b11f9 39%, #3636b2 100%);
    @media (max-width: 760px) {
      height: 100px;
      width: 100%;
    }
  }
  .userDiv {
    margin-top: -5%;
  }
  h2 {
    color: #313131;
    font-size: 18px;
  }
  p {
    color: #808080;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .imgDiv {
    background: #f5f5f5;
    padding: 5px;
    border-radius: 50%;
    width: fit-content;
  }
  .imgDiv > img {
    width: 7rem;
    height: 7rem;
    object-fit: cover;
    border-radius: 50%;
  }
  .spacedDiv {
    padding: 0 5rem;
  }
  .addrWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .addrWrapper,
  .otherInfo,
  .doctorInfo {
    padding: 1rem;
    border-radius: 15px;
    background: white;
    width: 95%;
    margin-bottom: 1rem;
  }
  .otherInfo {
    background: initial;
  }
  .addrItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    max-width: 50%;
    @media (max-width: 760px) {
      max-width: 100%;
    }
  }
  .otherInfo {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0;
    @media (max-width: 760px) {
      flex-direction: column;
    }
  }
  .medInfo,
  .eduInfo {
    width: 50%;
    padding: 1rem;
    border-radius: 15px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media (max-width: 760px) {
      width: 100%;
    }
  }
  .medInfo > div,
  .eduInfo > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .editBtn {
    background: white;
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    position: relative;
    float: right;
    top: 1rem;
    right: 1rem;
  }
  .doctorInfo img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .doctorInfo .userDoctor {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    border-bottom: 1px solid #313131;
  }
  .verifyProfile {
    h2 {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .imgDiv {
    position: relative;
    width: 150px; /* adjust based on your design */
    height: 150px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .profileOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
      p {
        color: white;
      }
    }

    &:hover .profileOverlay {
      opacity: 1;
    }
  }
`;
