import React from "react";
import { Button } from "react-bootstrap";
import Rightimg from "../../assets/images/Login/mobile.png";
import { useHistory } from "react-router-dom";
import { LoginStyled } from "./Login.Styled";

const VerificationProfile = () => {
  const history = useHistory();

  const goToProfileDetails = () => {
    history.push("/register/profile-details");
  };

  const goToIdentityProof = () => {
    history.push("/register/identity-proof");
  };

  const goToMapLocation = () => {
    window.open("/register/map-location", "_self");
  };

  return (
    <LoginStyled>
      <div className="profile-page">
        <div className="page-left">
          <div className="verification-profile">
            <h2>Profile is under verification</h2>
            <p>
              Your profile is currently under verification. Our team is
              reviewing the documents you've uploaded. You will be notified once
              the verification process is complete. Thank you for your patience.
            </p>

            <div>
              <h3 className="secclass">Section A: Profile details</h3>
              <p>
                Doctor's basic details, medical registration, education
                qualification, establishment details etc.
              </p>
              {/* <p>Status: Verified</p> */}
              <Button className="changebtn" onClick={goToProfileDetails}>
                Change
              </Button>
            </div>
            <hr />
            <div>
              <h3 className="secclass">Section B: Profile Verification</h3>
              <p>
                Doctor identity proof, registration proof, establishment
                ownership proof etc.
              </p>
              {/* <p>Status: Verified</p> */}
              <Button className="changebtn" onClick={goToIdentityProof}>
                Change
              </Button>
            </div>
            <hr />
            <div>
              <h3 className="secclass">Section C: Start getting patients</h3>
              <p>Location, Timings, Fees</p>
              <Button className="changebtn" onClick={goToMapLocation}>
                Change
              </Button>
            </div>
          </div>
        </div>
        <div className="pageright">
          <img src={Rightimg} alt="Profile Illustration" />
        </div>
      </div>
    </LoginStyled>
  );
};

export default VerificationProfile;
