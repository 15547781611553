import React, { useEffect, useRef, useState } from "react";
import { FaUser, FaPaperPlane } from "react-icons/fa";
import { ChatComponentStyled } from "./ChatComponent.styled";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientsAPI } from "../../redux/slices/myPatients/myPatientsService";
import {
  addMessage,
  getMessages,
  getUserChatId,
} from "../../redux/slices/chat/chatService";
import { toast } from "react-toastify";
import { CHAT_API } from "../../config";
import { io } from "socket.io-client"; // Import socket.io-client
import ChatContainer from "./ChatContainer";

// Updated User type with chatId
type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  chatId?: string;
};

type Message = {
  fromSelf: boolean;
  message: string;
};

const ChatComponent: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [users, setUsers] = useState<User[]>([]);
  const [currentUserChatId, setCurrentUserChatId] = useState<string | null>(
    null
  );
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [currentUserId, setCurrentUserId] = useState();
  const { patientsList } = useSelector(
    (ReduxState: any) => ReduxState.myPatients
  );
  const dispatch = useDispatch();
  // Parse userId from URL
  const userIdFromUrl = new URLSearchParams(window.location.search).get(
    "userId"
  );
  const [currentUser, setCurrentUser] = useState<any>(undefined);
  const socket = useRef<any>(null); // Use ref to store socket instance

  useEffect(() => {
    if (currentUserChatId) {
      socket.current = io(CHAT_API);
      socket.current.emit("add-user", currentUserChatId);
    }
  }, [currentUserChatId]);

  // useEffect(() => {
  //   if (socket.current) {
  //     // Listen for incoming messages from the server
  //     socket.current.on("msg-receive", (msg: any) => {
  //       if (msg.from === selectedUser?.chatId) {
  //         setMessages((prevMessages) => [
  //           ...prevMessages,
  //           { fromSelf: false, message: msg.message },
  //         ]);
  //       }
  //     });
  //   }
  // }, [selectedUser]);

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        setCurrentUserId(user.roles[0].linkable_id);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchPatients();
  }, []);

  useEffect(() => {
    fetchCurrentUserChatId();
  }, [currentUserId]);

  const getAllMessages = async (fromChatId: string, toChatId: string) => {
    try {
      const msgObj = { from: fromChatId, to: toChatId };
      const res = (await dispatch(getMessages(msgObj))) as any;
      if (res?.payload) {
        setMessages(res.payload);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      toast.error("Failed to fetch messages");
    }
  };

  const fetchPatients = async () => {
    try {
      await dispatch(fetchPatientsAPI());
    } catch (error) {
      console.error("Error fetching patients:", error);
      toast.error("Failed to fetch patients");
    }
  };

  const fetchCurrentUserChatId = async () => {
    try {
      const res = (await dispatch(getUserChatId(currentUserId))) as any;
      setCurrentUserChatId(res.payload?.user?._id);
    } catch (error) {
      console.error("Error fetching current user chat ID:", error);
      toast.error("Failed to fetch current user chat ID");
    }
  };

  useEffect(() => {
    if (patientsList?.patients) {
      fetchUsersChatIds();
    }
  }, [patientsList]);

  useEffect(() => {
    // Auto-select user when both users list and currentUserChatId are available
    if (users.length > 0 && currentUserChatId && userIdFromUrl) {
      const matchedUser = users.find((user) => user.id === userIdFromUrl);
      if (matchedUser) {
        handleUserSelect(matchedUser);
      }
    }
  }, [users, currentUserChatId, userIdFromUrl]);

  const fetchUsersChatIds = async () => {
    try {
      const updatedUsers = await Promise.all(
        patientsList.patients.map(async (patient: any) => {
          const res = (await dispatch(getUserChatId(patient.id))) as any;
          return {
            ...patient,
            id: patient.id.toString(),
            chatId: res.payload?.user?._id,
          };
        })
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error fetching users chat IDs:", error);
      toast.error("Failed to fetch users chat IDs");
    }
  };

  const handleUserSelect = (user: any) => {
    setSelectedUser(user);
    setCurrentUser({
      _id: user?.chatId,
      username: `${user?.first_name} ${user?.last_name}`,
      email: user?.email,
      avatarImage: user?.image,
    });
    if (currentUserChatId && user.chatId) {
      getAllMessages(currentUserChatId, user.chatId);
    }
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.trim() && selectedUser && currentUserChatId) {
      const msgObj = {
        from: currentUserChatId,
        to: selectedUser.chatId,
        message: newMessage.trim(),
      };

      try {
        const res = (await dispatch(addMessage(msgObj))) as any;
        if (res?.error) {
          toast.error(res.error.message);
          return;
        }

        // Emit message to server
        socket.current.emit("send-msg", {
          from: currentUserChatId,
          to: selectedUser.chatId,
          msg: newMessage.trim(),
        });

        // Add the new message to the local state
        setMessages([
          ...messages,
          { fromSelf: true, message: newMessage.trim() },
        ]);
        setNewMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
        toast.error("Failed to send message");
      }
    }
  };

  return (
    <ChatComponentStyled>
      <div className="chat-container">
        <div className="sidebar">
          <h2>Patients</h2>
          <ul>
            {users.map((user) => (
              <li
                key={user.id}
                onClick={() => handleUserSelect(user)}
                className={selectedUser?.id === user.id ? "selected" : ""}
              >
                <FaUser /> {user.first_name} {user.last_name}
              </li>
            ))}
          </ul>
        </div>
        <div className="chat-area">
          {currentUser === undefined ? (
            <p className="no-chat">Select a patient to start chatting</p>
          ) : (
            <ChatContainer
              currentUserChatId={currentUserChatId}
              currentChat={currentUser}
              socket={socket}
            />
          )}
        </div>
      </div>
    </ChatComponentStyled>
  );
};

export default ChatComponent;
