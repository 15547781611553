import styled from "styled-components";

export const OverviewbarchartStyled = styled.div`
  .chart-container {
    border-radius: 8px;
    border: 1px solid #9747ff;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(151, 71, 255, 0.1);
  }
  h4 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  .chart-head {
    display: flex;
    justify-content: space-between;
    margin: 12px;
  }
  .chart-head slect {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .recharts-wrapper {
    width: 100% !important;
  }
`;
