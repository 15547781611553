import React, { useEffect, useState } from "react";
import { PrescriptionStyled } from "./Prescription.styled";
import {
  FaSearch,
  FaMicrophone,
  FaTimes,
  FaRegUserCircle,
} from "react-icons/fa";
import {
  Input,
  Select,
  Button,
  InputNumber,
  Checkbox,
  Row,
  Col,
  Collapse as AntdCollapse,
} from "antd";
import { Modal } from "react-bootstrap";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {
  getAllMedicinesAPI,
  getAllTestsAPI,
} from "../../redux/slices/prescription/prescriptionService";
import { toast } from "react-toastify";
import {
  addDocPrescriptionAPI,
  getPrescriptionData,
} from "../../redux/slices/doctor/doctorService";

const { Option } = Select;
const Collapse: any = AntdCollapse;
const { Panel }: { Panel: any } = Collapse;

const Prescription = (props: any) => {
  const { id: prescriptionId } = props.match?.params;
  const [issue, setIssue] = useState<any>("");
  const [medicine, setMedicine] = useState<any>("");
  const [test, setTest] = useState("");
  const [issues, setIssues] = useState<any>([]);
  const [dose, setDose] = useState("");
  const [noOfDays, setNoOfDays] = useState(1);
  const [frequency, setFrequency] = useState({
    morning: 0,
    afternoon: 0,
    night: 0,
  });
  const [intake, setIntake] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [note, setNote] = useState("");
  const [showFrequencyModal, setShowFrequencyModal] = useState(false);
  const [diagnosis, setDiagnosis] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [nextVisit, setNextVisit] = useState(null);
  const [testNote, setTestNote] = useState("");
  const dispatch = useDispatch();
  const [allMedicines, setAllMedicines] = useState<any>();
  const [alltests, setAllTests] = useState<any>();
  const [selectedMedicines, setSelectedMedicines] = useState<any[]>([]);
  const [selectedTests, setSelectedTests] = useState<any[]>([]);

  useEffect(() => {
    const getAllMedicines = async () => {
      const res = (await dispatch(getAllMedicinesAPI())) as any;
      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occured");
      }
      if (res?.payload?.data?.medicines) {
        setAllMedicines(res?.payload?.data?.medicines);
      }
    };
    getAllMedicines();
  }, []);

  useEffect(() => {
    const getPrescription = async () => {
      const res = (await dispatch(getPrescriptionData(prescriptionId))) as any;
      if (res?.error) {
        toast.error("Failed to fetch prescription data.");
        return;
      }

      const data = res?.payload?.data;
      console.log("get presc api : ", data);

      if (data) {
        // Set symptoms as issues list
        setIssues(data.symptoms ? data.symptoms.split(", ") : []);

        // Set selected medicines
        setSelectedMedicines(
          data.prescriptions_medicines.map((med: any) => ({
            service_name: med.medicine_id, // Assuming medicine_id is the name or adjust accordingly
            dose: med.dosage,
            noOfDays: med.no_of_days,
            frequency: {
              morning: parseInt(med.frequency.split("-")[0], 10),
              afternoon: parseInt(med.frequency.split("-")[1], 10),
              night: parseInt(med.frequency.split("-")[2], 10),
            },
            intake: med.intake,
            startDate: dayjs(med.start_date).toDate(),
            note: med.note,
            price: {
              discounted_price: 0, // Adjust if price data is available in response
            },
          }))
        );

        setSelectedTests(
          [...data?.diagnostic_tests, ...data?.radiology_tests].map(
            (test: any) => ({
              service_name: test?.test_id, // Assuming test_id is the name or adjust accordingly
              diagnosis: test?.diagnosis,
              symptoms: test?.symptoms,
              nextVisit: test?.next_visit
                ? dayjs(test?.next_visit).toDate()
                : null,
              testNote: test?.note,
              type: test?.type,
              price: {
                discounted_price: 0, // Adjust if price data is available in response
              },
            })
          )
        );
      }
    };

    getPrescription();
  }, [prescriptionId, dispatch]);

  useEffect(() => {
    console.log("selectedTests : ", selectedTests);
  }, [selectedTests]);

  useEffect(() => {
    const getAllTests = async () => {
      const res = (await dispatch(getAllTestsAPI())) as any;
      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occured");
      }
      if (res?.payload?.data?.tests) {
        setAllTests(res?.payload?.data?.tests);
      }
    };
    getAllTests();
  }, []);

  const handleAddMedicine = () => {
    const selectedMedicine = allMedicines.find(
      (med: any) => med?.service_name === medicine
    );
    if (selectedMedicine) {
      const newMedicine = {
        ...selectedMedicine,
        dose,
        noOfDays,
        frequency,
        intake,
        startDate,
        note,
      };
      setSelectedMedicines([...selectedMedicines, newMedicine]);
      setMedicine("");
      setDose("");
      setNoOfDays(1);
      setFrequency({ morning: 0, afternoon: 0, night: 0 });
      setIntake("");
      setStartDate(new Date());
      setNote("");
    }
  };

  const handleAddTest = () => {
    const selectedTest = alltests.find(
      (testItem: any) => testItem.service_name === test
    );
    if (selectedTest) {
      const newTest = {
        ...selectedTest,
        diagnosis,
        symptoms,
        nextVisit,
        testNote,
      };
      setSelectedTests([...selectedTests, newTest]);
      setTest("");
      setDiagnosis("");
      setSymptoms("");
      setNextVisit(null);
      setTestNote("");
    }
  };

  const renderMedicineRows = () => {
    return selectedMedicines.map((medicine, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{medicine.service_name}</td>
        <td>{medicine.dose}</td>
        <td>{medicine.noOfDays}</td>
        <td>{`${medicine.frequency.morning}-${medicine.frequency.afternoon}-${medicine.frequency.night}`}</td>
        <td>{medicine.intake}</td>
        <td>{medicine.price.discounted_price}</td>
      </tr>
    ));
  };

  const renderTestRows = (type: string) => {
    return selectedTests
      .filter((test) => test?.type === type)
      .map((test, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{test?.service_name}</td>
          <td>{test?.diagnosis}</td>
          <td>{test?.symptoms}</td>
          <td>{test?.price?.discounted_price}</td>
          <td>
            {test?.nextVisit ? dayjs(test?.nextVisit).format("DD-MM-YYYY") : ""}
          </td>
        </tr>
      ));
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && issue.trim()) {
      setIssues([...issues, issue.trim()]);
      setIssue("");
    }
  };

  const handleDeleteIssue = (index: any) => {
    setIssues(issues.filter((_: any, i: any) => i !== index));
  };

  const handleFrequencyChange = (timeOfDay: any, value: any) => {
    try {
      setFrequency({ ...frequency, [timeOfDay]: value });
    } catch (error) {
      console.error("Error updating frequency:", error);
    }
  };

  const handlePrescriptionBooking = async () => {
    const prescriptionBody = {
      booking_id: prescriptionId,
      symptoms: issues?.join(", "), // Convert issues array to a comma-separated string
      prescriptions_medicines: selectedMedicines?.map((med) => ({
        medicine_id: med?.service_code,
        count: med?.noOfDays, // Assuming count refers to the number of days
        dosage: med?.dose,
        no_of_days: med?.noOfDays,
        frequency: `${med?.frequency.morning}-${med?.frequency.afternoon}-${med?.frequency.night}`,
        intake: med?.intake,
        note: med?.note,
        start_date: new Date(med?.startDate).toISOString().split("T")[0], // Format date to YYYY-MM-DD
      })),
      prescriptions_tests: selectedTests?.map((test) => ({
        test_id: test?.service_code,
        diagnosis: test?.diagnosis,
        symptoms: test?.symptoms,
        next_visit: new Date(test?.nextVisit)?.toISOString().split("T")[0], // Format date to YYYY-MM-DD
        note: test?.testNote,
        type: test?.type,
      })),
    };

    console.log("prescriptionBody : ", prescriptionBody);
    const res = (await dispatch(
      addDocPrescriptionAPI(prescriptionBody)
    )) as any;
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return;
    } else {
      toast.success("Prescription Added Succesfully");
    }
  };

  return (
    <PrescriptionStyled>
      {/* <div className="prescription-container">
        <div className="profile-section">
          <FaRegUserCircle size={32} />
          <div className="user-info">
            <h2 className="user-name">Alex Peter</h2>
            <p className="user-details">
              Age: <span>32</span> Gender: <span>Male</span> City:{" "}
              <span>Bangalore</span> Ticket no: <span>#4584</span>
            </p>
            <p className="purpose">
              Purpose: <span>Fever</span>
            </p>
          </div>
        </div>
        <div className="appointment-info">
          <p className="doctor-name">DR.Prabakaran PP</p>
          <p className="date">
            Date: <span>27-04-2024</span>
          </p>
          <p className="time">
            Time: <span>2:30 PM</span>
          </p>
          <p className="mode">
            Mode: <span>🏠</span>
          </p>
        </div>
      </div> */}

      <div className="accordion-container">
        <Collapse defaultActiveKey={["1"]} accordion>
          <Panel header={`Pharmacy (${selectedMedicines.length})`} key="1">
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Dose</th>
                  <th>Days</th>
                  <th>Frequency</th>
                  <th>Intake</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>{renderMedicineRows()}</tbody>
            </table>
          </Panel>
          <Panel
            header={`Lab Test (${
              selectedTests.filter((test) => test?.type === "diagnostic").length
            })`}
            key="2"
          >
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Diagnosis</th>
                  <th>Symptoms</th>
                  <th>Price</th>
                  <th>Next Visit</th>
                </tr>
              </thead>
              <tbody>{renderTestRows("diagnostic")}</tbody>
            </table>
          </Panel>
          <Panel
            header={`Radiology (${
              selectedTests.filter((test) => test?.type === "ctmri").length
            })`}
            key="3"
          >
            <table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Diagnosis</th>
                  <th>Symptoms</th>
                  <th>Price</th>
                  <th>Next Visit</th>
                </tr>
              </thead>
              <tbody>{renderTestRows("ctmri")}</tbody>
            </table>
          </Panel>
        </Collapse>
      </div>

      {/* Search and Issue Tags */}
      <div className="issue-container mt-4">
        <div className="search-bar">
          <FaSearch className="icon search-icon" />
          <input
            type="text"
            placeholder="Search Issue"
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <FaMicrophone className="icon mic-icon" />
        </div>
        <div className="issues-list">
          {issues.map((item: any, index: any) => (
            <div className="issue-tag" key={index}>
              {item}
              <FaTimes
                className="icon delete-icon"
                onClick={() => handleDeleteIssue(index)}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="medicine-container">
        <div className="search-bar">
          <FaSearch className="icon search-icon" />
          <Select
            showSearch
            placeholder="Search Medicine"
            value={medicine}
            onChange={(value) => {
              const selectedMedicine = allMedicines.find(
                (med: any) => med?.service_name === value
              );
              setMedicine(value);
              console.log("Selected Medicine Details:", selectedMedicine);
            }}
            filterOption={(input, option: any) =>
              option?.value?.toLowerCase()?.includes(input?.toLowerCase())
            }
            style={{ width: "100%" }}
          >
            {allMedicines?.map((med: any) => (
              <Option key={med?.service_code} value={med?.service_name}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={med?.image}
                    alt={med?.service_name}
                    style={{ width: 30, height: 30, marginRight: 8 }}
                  />
                  <span>{med?.service_name}</span>
                </div>
              </Option>
            ))}
          </Select>

          <FaMicrophone className="icon mic-icon" />
        </div>
        <div className="prescription-inputs">
          <Row gutter={16} className="align-items-center">
            <Col span={4} className="h-fit">
              <Select
                placeholder="Dose"
                value={dose || "Choose Dose"}
                showSearch
                onChange={setDose}
                style={{ width: "100%" }}
              >
                <Option value="MG 100">MG 100</Option>
                <Option value="MG 500">MG 500</Option>
              </Select>
            </Col>
            <Col span={4} className="h-fit">
              <InputNumber
                min={1}
                placeholder="No of days"
                value={noOfDays}
                onChange={(value) => setNoOfDays(value || 1)}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4} className="h-fit">
              <Button
                type="default"
                onClick={() => setShowFrequencyModal(true)}
              >
                Frequency
              </Button>
            </Col>
            <Col span={4} className="h-fit">
              <Select
                placeholder="Intake"
                value={intake || "Intake"}
                showSearch
                onChange={setIntake}
                style={{ width: "100%" }}
              >
                <Option value="after food">After Food</Option>
                <Option value="before food">Before Food</Option>
              </Select>
            </Col>
            <Col span={4} className="h-fit">
              <DatePicker
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
                minDate={new Date()} // Disable dates before today
                placeholderText="Start Date"
                dateFormat="dd-MM-yyyy"
                className="custom-datepicker"
              />
            </Col>
          </Row>
        </div>
        <div className="note-section">
          <p className="note-label">Note</p>
          <Input.TextArea
            placeholder="Add any additional notes here..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
            rows={3}
          />
        </div>
        <Button
          type="primary"
          className="add-button"
          style={{ marginTop: "8px" }}
          onClick={handleAddMedicine}
        >
          Add
        </Button>{" "}
      </div>

      <div className="tests-container">
        <Row gutter={16} className="align-items-center">
          <Col span={6}>
            <Select
              showSearch
              placeholder="Pick a Test"
              value={test || "Pick a Test"}
              onChange={(value) => {
                const selectedTest = alltests.find(
                  (testItem: any) => testItem.service_name === value
                );
                setTest(value);
                console.log("Selected Test Details:", selectedTest);
              }}
              style={{ width: "100%" }}
            >
              {alltests?.map((testItem: any) => (
                <Option
                  key={testItem.service_code}
                  value={testItem.service_name}
                >
                  {testItem.service_name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Diagnosis"
              value={diagnosis}
              onChange={(e) => setDiagnosis(e.target.value)}
            />
          </Col>
          <Col span={6}>
            <Input
              placeholder="Symptoms"
              value={symptoms}
              onChange={(e) => setSymptoms(e.target.value)}
            />
          </Col>
          <Col span={6}>
            <DatePicker
              selected={nextVisit}
              onChange={(date: any) => setNextVisit(date)}
              minDate={new Date()} // Disable dates before today
              placeholderText="Next Visit"
              dateFormat="dd-MM-yyyy"
              className="custom-datepicker"
            />
          </Col>
        </Row>

        {/* Note and Add Button */}
        <div style={{ marginTop: "16px" }}>
          <p className="note-label">Note</p>
          <Input.TextArea
            placeholder="Add any additional notes for tests..."
            value={testNote}
            onChange={(e) => setTestNote(e.target.value)}
            rows={3}
          />
          <Button
            type="primary"
            className="add-button"
            style={{ marginTop: "8px" }}
            onClick={handleAddTest}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="button-container">
        <button className="preview-button">Preview</button>
        <button className="book-button" onClick={handlePrescriptionBooking}>
          Book For Patient
        </button>
      </div>

      {/* Frequency Modal */}
      <Modal
        show={showFrequencyModal}
        onHide={() => setShowFrequencyModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Frequency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row gutter={8}>
            <Col span={8}>
              <Checkbox>Morning</Checkbox>
              <InputNumber
                min={0}
                value={frequency.morning}
                onChange={(value) =>
                  handleFrequencyChange("morning", value || 0)
                }
              />
            </Col>
            <Col span={8}>
              <Checkbox>Afternoon</Checkbox>
              <InputNumber
                min={0}
                value={frequency.afternoon}
                onChange={(value) =>
                  handleFrequencyChange("afternoon", value || 0)
                }
              />
            </Col>
            <Col span={8}>
              <Checkbox>Night</Checkbox>
              <InputNumber
                min={0}
                value={frequency.night}
                onChange={(value) => handleFrequencyChange("night", value || 0)}
              />
            </Col>
          </Row>
          <Row className="justify-content-end mt-4">
            <Button onClick={() => setShowFrequencyModal(false)}>Add</Button>
          </Row>
        </Modal.Body>
      </Modal>
    </PrescriptionStyled>
  );
};

export default Prescription;
