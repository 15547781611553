import styled from "styled-components";

export const ReportStyled = styled.div`
  width: 100%;
  padding-right: 20px;
  .searcharfilter {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .search-bar input {
    width: 420px;
    padding: 8px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    color: #ccc;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 30px;
  }
  .filterplace {
    margin-left: 30px;
  }
  .calendar {
    border-radius: 5px;
    border: 1px solid #eadbdb;
    background: #fff;
    width: 42px;
    height: 34px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
  .filterplace button {
    border-radius: 5px;
    border: 1px solid #fccece;
    background: #fff;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 93px;
    height: 40px;
  }
  .report-sec {
    display: flex;
    width: 100%;
    gap: 12px;
  }
  .reportappointment {
    width: 50%;
  }
  .reportattendance {
    width: 50%;
  }

  @media (max-width: 768px) {
    .report-sec {
      display: block;
    }
    .reportappointment {
      width: 100%;
    }
    .BarChart,
    .reportattendance {
      width: 100%;
    }
    .search-bar input {
      width: 100%;
    }
    .calendar {
      margin-left: 20px;
    }
  }
`;
