import styled from "styled-components";

export const BookingStyled = styled.div`
  width: 100%;
  .searcharfilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-bar input {
    width: 420px;
    padding: 8px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    color: black;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .book-video-call-sec {
    button {
      background: #9747ff;
      border: none;
      color: white;
      cursor: pointer;
      padding: 5px;
      border-radious: 4px;
    }
  }
  .filterplace {
    display: flex;
    gap: 10px;
  }

  .sort-select {
    position: relative;
    display: inline-block;
  }

  .sort-select select {
    padding: 8px 30px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #f8eeee;
    cursor: pointer;
    color: #2e2727;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-appearance: none; /* Remove default arrow in Chrome, Safari, Opera */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    appearance: none; /* Remove default arrow in Edge */
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTggMTJsLTYtNi43NWwuNzUtLjc1IDUuMjUgNS4xMzcgNS4yNS01LjEzN0wxNCA1LjI1eiIvPjwvc3ZnPg==")
      no-repeat right 8px center; /* Custom arrow */
    background-color: #fff; /* Set background color to white */
  }

  .filter-button {
    padding: 8px 30px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #f8eeee;
    background: #fff;
    cursor: pointer;
    color: #2e2727;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .filter-button:hover {
    background-color: #e2e2e2;
  }

  .appoint-order {
    padding-right: 20px;
  }
  .ordertable {
    margin-top: 12px;
  }

  .ordertable Table {
    --bs-table-bg: transparent !important;
    border-radius: 5px;
    border: 1px solid #d6cece;
  }
  .ordertable thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
  }
  .ordertable th {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ordertable td {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ordertable tr {
    border: 1px solid #d6cece;
  }
  .ordertable .action-report {
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  td.action-report {
    display: flex;
    justify-content: space-evenly;
  }
  .ordertable .action-report svg {
    cursor: pointer !important;
  }
  .orderTable .actions {
    display: flex;
    align-items: center;
  }
  .ordertable .actions {
    text-align: center;
    text-wrap: nowrap;
  }
  .ordertable .actions svg {
    cursor: pointer;
  }
  .ordertable .icon-space {
    margin: 0 6px;
  }
  @media (max-width: 768px) {
    .ordertable .icon-space {
      margin: 0 0;
    }
    .searcharfilter {
      display: block;
      padding: 12px;
    }
    .filterplace {
      padding: 6px;
      justify-content: center;
    }
    .search-bar input {
      width: 100%;
    }
  }
  .custom-png {
    border: none;
    background: none;
  }
  .pagination {
    justify-content: center;
  }
  .custom-pagination .page-item.active .page-link {
    background-color: #7c4dff;
    border-color: #7c4dff;
    color: white;
  }

  .custom-pagination .page-link {
    color: black;
  }

  .custom-pagination .page-link:hover {
    background-color: #ccbbfa;
  }

  .bk-btn {
    background: #9747ff;
    border: none;
    color: white;
    cursor: pointer;
  }

  .dropdown-menu.show {
    right: 0;
  }
`;
