import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { del, patch, post, put } from "../../../library/Requests/helpers";
import {
  fetchPatients,
  getPatientDetails,
} from "../../../Scenes/graphqlConfig";

export const fetchPatientsAPI = createAsyncThunk(
  "auth/fetchPatientsAPI",
  async () => await post(`${SERVER_IP}/graphql`, JSON.stringify(fetchPatients))
);

export const patientDetailAPI = createAsyncThunk(
  "auth/patientDetailAPI",
  async (patientId: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(getPatientDetails(patientId))
    )
);

export const addPatientAPI = createAsyncThunk(
  "auth/addPatientAPI",
  async (body: any) => await post(`${SERVER_IP}/api/v1/doctor/patient`, body)
);

export const editPatientAPI = createAsyncThunk(
  "auth/editPatientAPI",
  async (body: any) =>
    await put(`${SERVER_IP}/api/v1/doctor/patient/${body.id}`, body.editPayload)
);

export const deletePatientAPI = createAsyncThunk(
  "auth/deletePatientAPI",
  async (id: any) => await del(`${SERVER_IP}/api/v1/doctor/patient/${id}`)
);

export const signinAsUserAPI = createAsyncThunk(
  "auth/signinAsUserAPI",
  async (body: any) => await patch(`${SERVER_IP}/api/v1/auth/signinasuser`, body)
);
