import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const PiCharts = (props: any) => {
  const allData = props?.element?.patientsOverview;
  console.log("1243", allData);

  const data = [
    { name: "Group A", value: allData?.child, color: "#FFD700" },
    { name: "Group B", value: allData?.teen, color: " rgb(160, 170, 253)" },
    { name: "Group C", value: allData?.old, color: "#00FF00" },
    { name: "Group D", value: allData?.adult, color: "#FF6A6A" },
  ];
  return (
    <div className="d-flex justify-content-center">
      <PieChart width={100} height={100}>
        <Pie
          data={data}
          // cx={110}
          // cy={100}
          innerRadius={30}
          outerRadius={50}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

export default PiCharts;
