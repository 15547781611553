import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as Report } from "../../../../assets/icons/report.svg";
import { ReactComponent as Action } from "../../../../assets/icons/action1.svg";
import { ReactComponent as Share } from "../../../../assets/icons/share.svg";
import { ReactComponent as Download } from "../../../../assets/icons/download.svg";
import { ReactComponent as Call } from "../../../../assets/icons/call.svg";
import { ReactComponent as Searchbar } from "../../../../assets/icons/searchbar.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/icons/whats-app.svg";
import { ReactComponent as Gmail } from "../../../../assets/icons/gmail.svg";
import {
  getAllBookingListAPI,
  // getMyBookingsAPI,
} from "../../../../redux/slices/dashboard/dashboardService";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PatientDetailModal from "../../../../components/PatientDetailModal/Patientmodal";
import { useHistory } from "react-router-dom";

const Appointorder = () => {
  const dispatch = useDispatch();
  const { bookingsList } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  const [lgShow, setLgShow] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  const getLatestData = () => {
    dispatch(getAllBookingListAPI({ count: 20 }));
    // dispatch(getMyBookingsAPI(3));
  };

  useEffect(() => {
    getLatestData();
  }, []);

  const handleModalOpen = (patient: any, bkId: any) => {
    const patientObj = {
      ...patient,
      ...patient?.user,
    };
    const selectPatient = {
      ...patientObj,
      bkId: bkId,
    };
    setSelectedPatient(selectPatient);
    setLgShow(true);
  };

  const handleModalClose = () => {
    setLgShow(false);
    setSelectedPatient(null);
  };

  const handleShare = (platform: string, patient: any) => {
    const url = window.location.href; // Current URL
    const message = `Check out this appointment details for ${patient?.first_name}:\n${url}`;

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`,
          "_blank"
        );
        break;
      case "email":
        window.open(
          `mailto:?subject=Appointment Details&body=${encodeURIComponent(
            message
          )}`,
          "_self"
        );
        break;
      default:
        break;
    }
  };

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("SamplePDF.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SamplePDF.pdf";
        alink.click();
      });
    });
  };

  const handlePrescriptionClick = (bkId: any) => {
    history.push(`/prescription/${bkId}`);
  };

  const filteredBookings = bookingsList?.bookings?.filter(
    (item: any) =>
      item?.user?.first_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase()) ||
      item?.user?.id.toString().includes(searchQuery)
  );

  return (
    <div className="appoint-order">
      <div className="search-bar">
        <span className="search-icon">
          <Searchbar />
        </span>
        <input
          type="text"
          placeholder="For Patient or Serial Number"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="ordertable">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Serial No</th>
              <th>Name</th>
              <th>Age</th>
              <th>Date & Time</th>
              <th>Visit Purpose</th>
              <th>Status</th>
              <th className="action-report">Report</th>
              <th className="action-report">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredBookings?.slice(0, 20)?.map((item: any, index: any) => (
              <tr key={index}>
                <td>{item?.id}</td>
                <td
                  onClick={() => handleModalOpen(item, item?.id)}
                  style={{ color: "purple", cursor: "pointer" }}
                >
                  {item?.user?.first_name}
                </td>
                <td>{item?.user?.age}</td>
                <td>
                  {moment(parseInt(item?.collection_1_date)).format(
                    "MMMM DD, YYYY"
                  )}
                  {` ${item?.collection_1_slot?.toUpperCase()}`}
                </td>
                <td>
                  {item?.type
                    ?.split("_")
                    .map(
                      (word: any) =>
                        word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    .join(" ")}
                </td>
                <td className="action-report">{item.status}</td>
                <td className="action-report">
                  <Report />
                </td>
                <td className="actions">
                  <Action onClick={() => handlePrescriptionClick(item?.id)} />
                  <span className="icon-space"></span>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={Share}
                      id="dropdown-custom-components"
                    />

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleShare("whatsapp", item?.user)}
                      >
                        <Whatsapp /> WhatsApp
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => handleShare("email", item?.user)}
                      >
                        <Gmail /> Email
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <span className="icon-space" onClick={onButtonClick}></span>
                  <Download />
                  <span className="icon-space"></span>
                  <a href={`tel:${item?.user?.phone_number}`}>
                    <Call />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <PatientDetailModal
          show={lgShow}
          onHide={handleModalClose}
          patient={selectedPatient}
        />

        <div className="viewbtn">
          <button>View All</button>
        </div>
      </div>
    </div>
  );
};

export default Appointorder;
