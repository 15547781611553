import styled from "styled-components";
import { Layout, Card } from "antd";

const { Header, Content } = Layout;

export const TaskManagementStyled = styled(Layout)`
  min-height: 100vh;
  .boardTitle {
    background-color: white;
    padding: 0.5rem 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`;

export const BoardHeader = styled(Header)`
  background: #f6f9fd;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoardContent = styled(Content)`
  padding: 20px;
  display: flex;
  gap: 20px;
`;

export const Column = styled(Card)`
  flex: 1;
  min-width: 300px;
`;

export const TaskCard = styled(Card)`
  margin-bottom: 10px;
`;
