import styled from "styled-components";

export const OrderStyled = styled.div`
  .order-page {
    padding: 0px 20px;
  }
  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 950px) {
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  .left-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .title {
    color: #747070;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }

  .search-bar {
    display: flex;
    align-items: center;
    width: 406px;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
  }

  .search-bar svg {
    margin-right: 5px;
    margin-left: 5px;
  }

  .search-bar input {
    border: none;
    outline: none;
    padding: 5px;
    background: transparent;
    color: #ccc;
    font-size: 14px;
    font-weight: 400;
  }
  .savebtn {
    color: var(--white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--Primary, #9747ff);
  }

  .add-patient-button {
    padding: 12px 15px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 40px;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 10px;
    background: #fff;
  }

  .add-patient-button {
    margin-left: auto;
    padding: 12px 20px;
    border-radius: 10px;
    background: var(--Primary, #9747ff);
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
    color: var(--white, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .custom-dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    gap: 46px;
    width: 124px;
    height: 40px;
    padding: 9px 15px;
    flex-shrink: 0;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 2px;
    background: #fff;
    border: none;
  }
  .custom-dropdown .dropdown-menu {
    border: none;
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .button-sort {
    display: flex;
    gap: 18px;
  }
  .right-buttons {
    display: flex;
    gap: 12px;
    margin-right: 20px;
  }
  .right-buttons button {
    color: #fff;
    background: #9474ff;
  }
  .create-orderbtn {
    color: #9474ff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border: #fff;
  }

  @media (max-width: 768px) {
    .controls {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      text-align: center;
      div {
        width: 100%;
      }
    }
    .left-controls {
      display: block;
    }
    .search-bar {
      width: 100%;
      margin-bottom: 12px;
    }
    .button-sort {
      text-align: center;
      justify-content: center;
    }
    .custom-dropdown {
      margin-bottom: 12px;
    }
    .add-patient-button {
      margin: 0%;
    }
  }
  .orderdetail {
    padding: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid rgba(233, 212, 212, 0.8);
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .orderdetail-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .orderdetail-header-left {
    display: flex;
  }

  .profile-pic {
    margin-right: 16px;
    border-radius: 8px;
    background: #e1ebf4;
  }

  .patient-info h2 {
    margin: 0;
    color: #313131;
    font-size: 16px;
    font-weight: 500;
  }

  .patient-info .patient-id {
    color: #8e8181;
    font-size: 14px;
    font-weight: 400;
  }

  .patient-info p {
    margin: 4px 0;
    color: #313131;
    font-size: 14px;
    font-weight: 400;
  }

  .orderdetail-header-right {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }

  .accordion-item {
    border-radius: 5px;
    background: #f1faff;
    margin-bottom: 8px;
    border: 1px solid #f4f4f4;
  }

  .accordion {
    --bs-accordion-bg: #f1faff;
  }

  .order-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }

  .order-item-right {
    display: flex;
    gap: 12px;
  }

  .accordion-header {
    background-color: #f1faff;
  }

  .accordion-body {
    background-color: #f1faff;
  }

  .status {
    margin-right: 8px;
    color: #3ac00a;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.3px;
  }
  .total-margin {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .status.delivered {
    color: green;
  }

  .status.pending {
    color: orange;
  }

  .status.cancelled {
    color: red;
  }

  .orderdetail-footer {
    display: flex;
    gap: 12px;
    margin-left: auto;
    align-items: center;
  }

  .bill-info p,
  .bill-total p {
    margin: 0;
    font-size: 14px;
    color: #555;
  }

  .order-again-button {
    /* padding: 8px 16px; */

    cursor: pointer;
    border-radius: 5px;
    background: var(--Primary, #9747ff);
    color: var(--white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
  }

  /* Detailed items styling */
  .detailed-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #e1ebf4;
    border-radius: 10px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .detailed-header-name {
    flex: 1;
  }

  .detailed-header-price,
  .detailed-header-margin {
    width: 100px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.3px;
  }

  .detailed-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
  }

  .detailed-item-info {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .detailed-item-name {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.35px;
  }

  .detailed-item-description {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 200% */
    letter-spacing: 0.25px;
  }

  .detailed-item-price,
  .detailed-item-margin {
    width: 100px;
    color: #483e3e;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .categoryname {
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .orderID {
    color: #484040;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .IDDet {
    color: #484040;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .client-info {
    h2 {
      display: flex;
      gap: 10px;
      flex-direction: column;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: normal;
    }
    span {
      font-size: 1rem;
    }
  }
`;
