import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FaFacebook, FaGoogle, FaWhatsapp } from "react-icons/fa";
import docLoginHeroImg from "../../assets/images/docLoginHeroImg.png";
import "./styles.css";
import { LoginCompStyled } from "./LoginComp.styled";
import { SignUpCompStyled } from "./SignupComp.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  requestOtpAPI,
  validateOtpAPI,
} from "../../redux/slices/auth/authService";
import OTPInput from "react-otp-input";
import { IoMdArrowRoundBack } from "react-icons/io";
import SocialLoginButtons from "../../components/SocialLoginButtons/SocialLoginButtons";

const Signin = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [loginFormTitle, setLoginFormTitle] = useState(
    "Sign in to your existing account."
  );

  const location = useLocation();
  const history = useHistory();

  const LoginComp = ({ setActiveTab }) => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [errorMobileNumber, setErrorMobileNumber] = useState(false);
    const [loading, setLoading] = useState(false);
    const { error, user } = useSelector(({ auth }) => auth);
    const history = useHistory();
    const dispatch = useDispatch();
    const [otpCode, setOtpCode] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState("");
    const [timeRemainingVal, setTimeRemainingVal] = useState(60);
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const [isPhoneNumber, setIsPhoneNumber] = useState(false);
    const [validateOtp, setValidateOtp] = useState("");
    const [newUser, setNewUser] = useState(false);
    let timer = null;
    let timeRemaining = 60;

    useEffect(() => {
      if (user?.id) {
        history.push("/dashboard");
      }
    }, [user, history]);

    const handleContinueClick = () => {
      if (mobileNumber === "") {
        setErrorMobileNumber(true);
      } else {
        setErrorMobileNumber(false);
        requestOtp();
        // signInCall();
      }
    };

    const signInCall = () => {
      setLoading(true);
      const body = {
        email: "test.doctor@raphacure.com", // Assuming the API accepts an email or phone number
        password: "Test@123", // Password is optional or managed through OTP in this flow
        role: "doctor",
      };
      dispatch(loginUser(body)).finally(() => {
        setLoading(false);
      });
    };

    const handleChangeValue = (e) => {
      setMobileNumber(e.target.value);
      setErrorMobileNumber(false);
    };

    const checkPhoneNumberOrEmail = (inputV) => {
      const phoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (phoneRegex.test(inputV)) {
        return "phoneNo";
      }
      if (emailRegex.test(inputV)) {
        return "email";
      }
      return "invalid";
    };

    const resetTImer = async () => {
      timer = setInterval(updateTimer, 1000);
    };

    const updateTimer = () => {
      timeRemaining--;
      if (timeRemaining === 0) {
        clearInterval(timer);
      } else {
        setTimeRemainingVal(timeRemaining);
      }
    };
    const requestOtp = async () => {
      const phoneNumber = mobileNumber;
      const inputType = checkPhoneNumberOrEmail(phoneNumber);
      if (inputType === "phoneNo") {
        sendOTPCall({ phone: phoneNumber });
        setIsPhoneNumber(true);
      } else if (inputType === "email") {
        sendOTPCall({ email: phoneNumber });
        setIsPhoneNumber(false);
      } else {
        setIsPhoneNumberError(true);
      }
    };
    const sendOTPCall = async (bodyJ) => {
      setLoading(true);
      const response = await dispatch(requestOtpAPI(bodyJ));
      setLoading(false);
      setNewUser(response?.payload?.data?.isNewUser);
      if (response?.payload?.success) {
        setOtpSent(true);
        setOtpErrorMsg("");
        setValidateOtp("");
        timeRemaining = 60;
        resetTImer();
      } else {
        setIsPhoneNumberError(true);
      }
    };

    const handlePinChange = (pinCode) => {
      console.log("pinCode : ", pinCode);
      setValidateOtp(pinCode); // Set state for other dependencies
      if (pinCode.length === 6) {
        console.log("Triggering OTP Validation with: ", pinCode);
        handleValidateOtp(pinCode); // Use the pinCode directly
      }
    };

    const handleResedOTP = () => {
      requestOtp();
    };

    const checkOtpDisabled = () => {
      return validateOtp?.length < 6 ? true : false;
    };

    const handleValidateOtp = async (otp) => {
      const otpToValidate = otp || validateOtp; // Fallback to state if needed
      const reqBody = isPhoneNumber
        ? { phone: mobileNumber, otp: otpToValidate.toString(), role: "doctor" }
        : {
            email: mobileNumber,
            otp: otpToValidate.toString(),
            role: "doctor",
          };

      setLoading(true);
      const response = await dispatch(validateOtpAPI(reqBody));
      console.log("response main", response);

      setLoading(false);
      if (response?.payload?.success) {
        setOtpSent(true);
        if (newUser) history.push("/register");
      } else {
        setOtpErrorMsg("Please enter Valid OTP");
      }
    };

    console.log("otpErrorMsg", otpErrorMsg);

    return (
      <LoginCompStyled>
        <div className="login-container">
          <div className="login-box">
            <h2>Log In / Sign Up</h2>
            {!otpSent ? (
              <>
                <form>
                  <label htmlFor="phone">Phone / Email</label>
                  <input
                    type="text" // Use text for better input handling
                    id="phone"
                    placeholder="Enter your number / email"
                    value={mobileNumber}
                    className={errorMobileNumber ? "error" : ""}
                    onChange={handleChangeValue}
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   if (/^\d*$/.test(value) && value.length <= 10) {
                    //     // Allow only numbers if input is numeric
                    //     handleChangeValue(e);
                    //   } else if (value.includes("@") || value.includes(".")) {
                    //     // Allow email input
                    //     handleChangeValue(e);
                    //   }
                    // }}
                  />
                  <p className="otp-text">
                    OTP will be sent to this number for verification
                  </p>
                  {error && <p className="errorMessage">{error}</p>}

                  <button
                    type="button"
                    onClick={handleContinueClick}
                    disabled={
                      (mobileNumber &&
                        mobileNumber.length < 10 &&
                        /^\d+$/.test(mobileNumber)) ||
                      !mobileNumber
                    }
                  >
                    {loading ? "Loading..." : "Continue"}
                  </button>
                </form>
                {/* <div className="signup-text">
                  <p>
                    Don't have an account?{" "}
                    <a href="#" onClick={() => setActiveTab(1)}>
                      Signup
                    </a>
                  </p>
                </div> */}
                <div className="or-divider">
                  <span>OR</span>
                </div>
                <div className="social-login">
                  {/* <button className="google-btn">
                    <FaGoogle size={20} />
                    Google
                  </button>
                  <button className="whatsapp-btn">
                    <FaWhatsapp size={20} />
                    Whatsapp
                  </button>
                  <button className="facebook-btn">
                    <FaFacebook size={20} />
                    Facebook
                  </button> */}
                  <div className="g-butttons">
                    <SocialLoginButtons
                      closeModel={() => {}}
                      isSignUp={true}
                      showPageLoader={() => {}}
                      navigateUserSocial={() => {}}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="login-step2">
                {otpSent && (
                  <>
                    <div className="back-btn-sec text-white">
                      <span
                        onClick={() => {
                          setOtpSent(false);
                        }}
                        className=" text-xl cursor-pointer top-category-sec font-bold mx-2 back-btn-sec opacity-75 "
                      >
                        <IoMdArrowRoundBack />
                        &nbsp;
                        {` Back`}
                      </span>
                    </div>
                    <div className="login-header-left text-white">
                      Verification code
                    </div>
                    <p className="verify-new-line-sec text-white">
                      We have sent a 6-digit OTP on your mobile number +91
                      {` `} {mobileNumber}
                    </p>

                    <div className="otp-enter-box">
                      <OTPInput
                        inputType="number"
                        inputStyle={{
                          marginRight: "14px",
                          width: "100%",
                          height: "100%",
                          fontSize: "24px",
                          // fontFamily: "Montserrat",
                          MozAppearance: "textfield",
                        }}
                        value={validateOtp}
                        onChange={(e) => {
                          handlePinChange(e);
                          setOtpErrorMsg("");
                        }}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    {otpErrorMsg && (
                      <p className="error_message">{otpErrorMsg}</p>
                    )}
                    <div className="resend-time-sec">
                      <p className="text-white">
                        Did not receive OTP?
                        <div>
                          {timeRemainingVal > 1 ? (
                            <>
                              {` `} Resend in {` `}
                              <span>
                                {`0:`}
                                {timeRemainingVal}
                              </span>
                              {` `}Seconds
                            </>
                          ) : (
                            <>
                              <button
                                onClick={handleResedOTP}
                                className="re-send-btn"
                              >
                                Resend
                              </button>
                            </>
                          )}
                        </div>
                      </p>
                    </div>
                    <div className="proceed-btn text-white">
                      <button
                        onClick={handleValidateOtp}
                        disabled={checkOtpDisabled()}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </LoginCompStyled>
    );
  };

  function SignUpComp({ setActiveTab }) {
    return (
      <SignUpCompStyled>
        <div className="signup-container">
          <div className="signup-box">
            <h2>Patients are eager to find doctors like you.</h2>
            <p>
              Join RaphaCure and connect with millions of patients seeking the
              right doctor through your digital profile.
            </p>
            <form>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" placeholder="Type full name" />
              <label htmlFor="phone">Phone</label>
              <input
                type="number"
                min={0}
                max={9999999999}
                id="phone"
                placeholder="Type number"
              />
              <p className="otp-text">
                OTP will be sent to this number for verification
              </p>
              <button type="button">Continue</button>
            </form>
            <div className="login-text">
              <p>
                If you already have an account. Please{" "}
                <a href="#" onClick={() => setActiveTab(0)}>
                  login
                </a>
              </p>
            </div>
            <div className="or-divider">
              <span>OR</span>
            </div>
            <div className="social-login">
              <button className="google-btn">
                <FaGoogle size={20} />
                Google
              </button>
              <button className="whatsapp-btn">
                <FaWhatsapp size={20} />
                Whatsapp
              </button>
              <button className="facebook-btn">
                <FaFacebook size={20} />
                Facebook
              </button>
            </div>
          </div>
        </div>
      </SignUpCompStyled>
    );
  }

  useEffect(() => {
    let urlQueryString = location.search;
    let signInType = new URLSearchParams(urlQueryString).get("returnUrl");
  }, [location.search]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      history.push("/dashboard");
    }
  }, []);

  return (
    <div className="loginsignupContainer">
      {activeTab === 0 && <LoginComp setActiveTab={setActiveTab} />}
      {activeTab === 1 && <SignUpComp setActiveTab={setActiveTab} />}
      <div className="docImgWrapper">
        <img src={docLoginHeroImg} alt="Doctor Login" />
      </div>
      {/* <div className="data-loginsignupContainer">
        <div className="freshbag_signin_header">
          {activeTab === 0 ? loginFormTitle : "Create an account for faster ."}
        </div>
        <Row className={"tabHeaderDesktop"}>
          <Col
            xs={6}
            onClick={() => setActiveTab(0)}
            className={activeTab === 0 ? " tabActive" : "tabInActive"}
          >
            <span>Sign In</span>
          </Col>
        </Row>
        {activeTab === 0 && <SignInTab gotoDelivery={gotoDelivery} />}
        {activeTab === 1 && (
          <SignUpTab onSignUpSuccess={() => setActiveTab(0)} />
        )}
      </div> */}
    </div>
  );
};

export default Signin;
