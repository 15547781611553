import React, { useState, useCallback, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { useHistory } from "react-router-dom";
import { MAP_KEY } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getDoctorProfileDetails,
  updateMapLocationAPI,
} from "../../redux/slices/doctor/doctorService";
import { InputNumber } from "antd";
import { Input } from "antd";
import AutocompleteField from "../../components/AutoComplete";

const containerStyle = {
  width: "100%",
  height: "200px",
};

interface Errors {
  email?: string;
  address?: string;
  phoneNumber?: string;
}

const MapLocation: React.FC = () => {
  const history = useHistory();
  const [center, setCenter] = useState({
    lat: 12.9716,
    lng: 77.5946,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_KEY,
  });
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);

  // State variables for form inputs and marker position
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(center);
  const [errors, setErrors] = useState<Errors>({});
  const [isAddressAutoFilled, setIsAddressAutoFilled] = useState(false);

  // Allow editing of the phone number
  const handlePhoneNumberChange = (val: any) => setPhoneNumber(val);
  const handleEmailChange = (e: any) => setEmail(e.target.value);
  const handleAddressChange = (e: any) => {
    setAddress(e.target.value);
    setIsAddressAutoFilled(false); // If manually editing, disable the auto-filled indication
  };

  const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    const newPosition = { lat: event.latLng!.lat(), lng: event.latLng!.lng() };
    setMarkerPosition(newPosition);
    fetchAddress(newPosition); // Fetch the address when marker position changes
  }, []);

  // Geocode the selected position to get the street address
  const fetchAddress = (position: { lat: number; lng: number }) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        setAddress(results[0].formatted_address);
        setIsAddressAutoFilled(true);
      }
    });
  };

  const validateForm = () => {
    const newErrors: Errors = {};
    if (!email) newErrors.email = "Email address is required.";
    else if (!/\S+@\S+\.\S+/.test(email))
      newErrors.email = "Email address is invalid.";

    if (!address) newErrors.address = "Street address is required.";
    if (!phoneNumber) newErrors.phoneNumber = "Mobile Number is required.";

    return newErrors;
  };

  const [doctorId, setDoctorId] = useState<any>();
  const dispatch = useDispatch();
  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, []);

  const prefillData = (data: any) => {
    const lat = data?.payload?.data?.doctor?.lat;
    const lng = data?.payload?.data?.doctor?.long;

    if (lat && lng) {
      const initialPosition = { lat, lng };
      setCenter(initialPosition);
      setMarkerPosition(initialPosition);
      fetchAddress(initialPosition); // Fetch address
    }

    setEmail(data?.payload?.data?.doctor?.clinic_email);
    setPhoneNumber(data?.payload?.data?.doctor?.clinic_phone || ""); // Pre-fill phone number
  };

  // Handle Next Step Function
  const handleNextStep = async () => {
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      const payload = {
        id: doctorId,
        mapObj: {
          lat: markerPosition.lat,
          long: markerPosition.lng,
          clinic_email: email,
          // clinic_phone: phoneNumber,
        },
      };

      const res = await dispatch(updateMapLocationAPI(payload));
      const resJson = JSON.parse(JSON.stringify(res));
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        toast.success("Map Location updated successfully");
        history.push("/register/consultant-doctor");
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handlePrevStep = () => {
    history.push("/register/progress-summary-two");
  };

  if (loadError) {
    return <div>Error loading map</div>;
  }

  return (
    <LoginStyled>
      <div className="map-location-container">
        <div className="content">
          <h2>Map Location</h2>
          <p>Cure clinic, HSR Layout</p>

          <Form>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone number</Form.Label>
              <Input
                type="text" // Use text for full control
                value={phoneNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
                  if (value.length <= 10) {
                    handlePhoneNumberChange(value); // Update state if within 10 digits
                  }
                }}
                maxLength={10} // Limit input to 10 digits
                status={errors.phoneNumber ? "error" : ""} // Apply error status if needed
                style={{ width: "100%" }}
                placeholder="Enter phone number"
                onBlur={() => {
                  if (phoneNumber.length < 10) {
                    setErrors({
                      phoneNumber: "Phone number must be exactly 10 digits.",
                    });
                  } else {
                    setErrors({ phoneNumber: "" }); // Clear error if valid
                  }
                }}
              />
              {errors.phoneNumber && (
                <p
                  className="error"
                  style={{ color: "red", fontSize: "0.8rem" }}
                >
                  {errors.phoneNumber}
                </p>
              )}
              <p className="secclass">
                Note: Patients' calls will be directed to this number. You can
                update it later also.
              </p>
            </Form.Group>

            <Form.Group controlId="formEmailAddress" className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email address"
                value={email}
                onChange={handleEmailChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <AutocompleteField
              onPlaceSelected={(place: any) => {}}
              autoFocus={true}
              name="fromAddress"
              id="fromAddress"
              defaultValue={address}
              placeholder="Type street address"
              onChange={handleAddressChange}
            />

            {/* <Form.Group controlId="formStreetAddress">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type street address"
                value={address}
                onChange={handleAddressChange}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
              {isAddressAutoFilled && (
                <small>
                  Address auto-filled based on the selected location.
                </small>
              )}
            </Form.Group> */}
          </Form>

          <div className="map-container">
            <p>Drag and drop the pin to your location</p>
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={markerPosition}
                zoom={15}
                onClick={onMapClick}
              >
                <Marker position={markerPosition} draggable={true} />
              </GoogleMap>
            ) : (
              <p>Loading map...</p>
            )}
          </div>

          <div className="button-container flex-row">
            <Button className="back-button" onClick={handlePrevStep}>
              <BackIcon /> Back
            </Button>
            <Button className="continue-button" onClick={handleNextStep}>
              Next
            </Button>
          </div>
        </div>

        <div className="pageright">
          <img src={Rightimg} alt="Profile Illustration" />
        </div>
      </div>
    </LoginStyled>
  );
};

export default MapLocation;
