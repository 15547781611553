import React, { useEffect, useState } from "react";
import { Form, Input, Button, Image } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "antd/dist/antd.css";
import { BlogStyled } from "./Blog.styled";
import { useDispatch, useSelector } from "react-redux";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import axios from "axios";
import { createBlogAPI } from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const Blog = () => {
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [doctorId, setDoctorId] = useState<any>();
  const history = useHistory();
  const onFinish = async (values: any) => {
    if (!doctorId) {
      toast.error("Doctor Id Not found, Please register as a doctor first");
      history.push("/register");
      return;
    }
    const res = await dispatch(
      createBlogAPI({
        doctor_id: parseInt(doctorId),
        image: imageUrl,
        title: values.title,
        summary: content,
      })
    );
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson.payload.success) {
      toast.success("Blog Added");
    }
  };

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        setDoctorId(user.roles[0].linkable_id);
      }
    }
  }, []);

  const uploadImageToS3 = async (image: any) => {
    try {
      const presignBody = {
        id: `${user.id}`,
        ext: ".png",
      };
      const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
      const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));
      console.log("presignedUrlResp", presignedUrlResp);
      const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
      const publicUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;
      await axios.put(presignedUrl, image, {
        headers: {
          "Content-Type": "image/png",
        },
      });
      console.log("publicUrl", publicUrl);
      return publicUrl;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleImageChange = async (event: any) => {
    const file = event.target.files ? event.target.files[0] : null;
    setImageFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImageUrl(reader.result as string);

        try {
          const imgUrl = await uploadImageToS3(file);
          setImageUrl(imgUrl);
        } catch (error) {
          console.error("Error uploading image. Please try again.", error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <BlogStyled>
      <h1>Blog Post Editor</h1>
      <Form name="blogPostForm" onFinish={onFinish} layout="vertical">
        <Form.Item label="Cover Image">
          <input type="file" accept="image/png" onChange={handleImageChange} />
        </Form.Item>

        {imageUrl && (
          <Image
            width={200}
            src={imageUrl}
            alt="Cover image preview"
            style={{ marginBottom: 16 }}
          />
        )}

        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input placeholder="Enter blog post title" />
        </Form.Item>

        <Form.Item label="Summary">
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            style={{ height: 300, marginBottom: 50 }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </BlogStyled>
  );
};

export default Blog;
