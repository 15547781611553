import React from "react";
import { OverviewbarchartStyled } from "./Overviewbarchart.styled";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";

const data = [
  {
    name: "Sun 04",
    attendance: 80,
  },
  {
    name: "Mon 05",
    attendance: 100,
  },
  {
    name: "Tue 06",
    attendance: 100,
  },
  {
    name: "Wed 07",
    attendance: 90,
  },
  {
    name: "Thu 08",
    attendance: 90,
  },
  {
    name: "Fri 08",
    attendance: 90,
  },
];

const CustomizedBar = (props: any) => {
  const { x, y, height, fill } = props;
  return (
    <g>
      <rect
        x={x + 12}
        y={y}
        width={26}
        height={height}
        fill={fill}
        stroke="#CF8CF9"
        strokeWidth="1"
        rx="5"
        ry="5"
      />
    </g>
  );
};

const OverviewBar = () => {
  return (
    <OverviewbarchartStyled>
      <div className="chart-container">
        <div className="chart-head">
          <h4>Attendance Over View</h4>
          <select name="today" id="Today" onClick={(e)=>e.preventDefault()}>
            <option>Today</option>
            <option>Today1</option>
            <option>Today2</option>
            <option>Today3</option>
          </select>
        </div>
        <BarChart
          width={420}
          height={240}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 2,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis
            tickFormatter={(tick) => `${tick}%`}
            domain={[10, 100]}
            ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          />
          <Tooltip />
          <Bar
            dataKey="attendance"
            fill="url(#colorUv)"
            shape={<CustomizedBar />}
          />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF961C" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8DEFB4" stopOpacity={0.8} />
            </linearGradient>
          </defs>
        </BarChart>
      </div>
    </OverviewbarchartStyled>
  );
};
export default OverviewBar;
