import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as Man } from "../../../../assets/icons/man.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppointmentsListAPI } from "../../../../redux/slices/dashboard/dashboardService";
import PatientDetailModal from "../../../../components/PatientDetailModal/Patientmodal";
import moment from "moment";
import docImg from "../../../../assets/images/doctor-male.png";

const Appointment = () => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [lgShow, setLgShow] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment()); // Track the current selected date
  const [selectedDateText, setSelectedDateText] = useState("Today"); // New state for selected date text
  const appointRef = useRef(null);
  const dispatch = useDispatch();
  const [patient, setPatient] = useState({
    name: "Priyanka",
    age: "23",
    gender: "Female",
    city: "BLR",
  });

  const { appointmentsList } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  console.log("appointmentsList", appointmentsList);

  useEffect(() => {
    const body = {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    };
    getAllAppointments(body);
  }, []);

  const getAllAppointments = (body: any) => {
    dispatch(getAllAppointmentsListAPI(body));
  };

  const handleChangesDate = (e: any) => {
    const selectedValue = e.target.value;
    getDatesFormat(selectedValue);
    setSelectedDateText(formatDateText(selectedValue));
  };

  const formatDateText = (dateText: string) => {
    switch (dateText) {
      case "today":
        return "Today";
      case "tomorrow":
        return "Tomorrow";
      case "thisweek":
        return "This Week";
      case "nextweek":
        return "Next Week";
      case "nextmonth":
        return "Next Month";
      case "prevmonth":
        return "Previous Month";
      default:
        return "Today";
    }
  };

  const getDatesFormat = (dateText: any) => {
    let body = {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    };

    if (dateText === "today") {
      body = {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      };
    } else if (dateText === "tomorrow") {
      body = {
        from: moment().add(1, "days").format("YYYY-MM-DD"),
        to: moment().add(1, "days").format("YYYY-MM-DD"),
      };
    } else if (dateText === "thisweek") {
      const daysUntilWeekend = 7 - moment().day();
      console.log("daysUntilWeekend", daysUntilWeekend, moment().day());
      body = {
        from: moment().format("YYYY-MM-DD"),
        to: moment().add(daysUntilWeekend, "days").format("YYYY-MM-DD"),
      };
    } else if (dateText === "nextweek") {
      const daysUntilWeekend = 7 - moment().day();
      body = {
        from: moment().add(daysUntilWeekend, "days").format("YYYY-MM-DD"),
        to: moment()
          .add(daysUntilWeekend + 7, "days")
          .format("YYYY-MM-DD"),
      };
    } else if (dateText === "nextmonth") {
      body = {
        from: moment().add(1, "months").format("YYYY-MM-01"),
        to: moment().add(1, "months").format("YYYY-MM-31"),
      };
    } else if (dateText === "prevmonth") {
      body = {
        from: moment().add(1, "months").format("YYYY-04-01"),
        to: moment().add(1, "months").format("YYYY-07-31"),
      };
    }

    getAllAppointments(body);
  };

  const handlePrevClick = () => {
    setCurrentDate((prevDate) => moment(prevDate).subtract(1, "day")); // Go to the previous day
  };

  const handleNextClick = () => {
    setCurrentDate((prevDate) => moment(prevDate).add(1, "day")); // Go to the next day
  };

  const handleDateClick = (date: any) => {
    setCurrentDate(date);
    getAllAppointments({
      from: date.format("YYYY-MM-DD"),
      to: date.format("YYYY-MM-DD"),
    });
  };

  const datesToDisplay = [
    moment(currentDate).subtract(1, "day"),
    moment(currentDate),
    moment(currentDate).add(1, "day"),
    moment(currentDate).add(2, "day"),
  ];

  const handleViewAll = () => {
    setShowAll(true);
    if (appointRef.current) {
      // appointRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleModalClose = () => {
    setLgShow(false);
    setSelectedPatient(null);
  };

  const handleModalOpen = (patient: any, bkId: any) => {
    const patientObj = {
      ...patient,
      ...patient?.user,
    };
    const selectPatient = {
      ...patientObj,
      bkId: bkId,
    };
    console.log("selectPatient : ", selectPatient);
    setSelectedPatient(selectPatient);
    setLgShow(true);
  };

  useEffect(() => {
    console.log("lgShow : ", lgShow);
  }, [lgShow]);

  return (
    <div className="appoint-left chk">
      <div className="filtersec">
        <label>Appointments</label>
        <select name="Appointments" onChange={handleChangesDate} id="today">
          <option value="today">Today</option>
          <option value="tomorrow">Tomorrow</option>
          <option value="thisweek">This Week</option>
          <option value="nextweek">Next Week</option>
          <option value="nextmonth">Next Month</option>
          <option value="prevmonth">Prev Month</option>
        </select>
      </div>

      <div className="datesection">
        <div className="lefticon" onClick={handlePrevClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
          >
            <path d="M6 10L6 0L0 5L6 10Z" fill="#9474FF" />
          </svg>
        </div>

        {datesToDisplay.map((date, index) => (
          <div
            key={index}
            className={`dateinside ${
              date.isSame(currentDate, "day") ? "active-date" : ""
            }`}
            onClick={() => handleDateClick(date)}
          >
            <p className="week">{date.format("ddd")}</p>
            <p className="date">{date.format("D")}</p>
          </div>
        ))}

        <div className="righticon" onClick={handleNextClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
          >
            <path
              d="M0.350503 0.0987726L0.421889 9.30911L6.91362 4.68134L0.350503 0.0987726Z"
              fill="#9474FF"
            />
          </svg>
        </div>
      </div>

      <div className="selected-date-text">{selectedDateText}</div>

      <div className="Scrolling-div">
        {appointmentsList?.bookings?.map((item: any, index: any) => {
          return (
            <div
              key={index}
              className="appointdet"
              onClick={() => handleModalOpen(item, item?.id)}
            >
              <div>
                <img
                  src={item?.user?.image || docImg}
                  alt={`${item?.user?.first_name} ${item?.user?.last_name}`}
                />
              </div>
              <div className="appoint-info">
                <div>
                  <div className="name">{`${item?.user?.first_name} ${item?.user?.last_name}`}</div>
                  <div className="name2">{item.type}</div>
                  <div>
                    {moment(parseInt(item?.collection_1_date)).format(
                      " MMMM DD, YYYY"
                    )}
                  </div>
                  <div className="time">{item.collection_1_slot}</div>
                </div>
              </div>
            </div>
          );
        })}
        {appointmentsList?.bookings?.length === 0 && (
          <div className="view-allbtn">
            <br />
            No Appointments Available.
            <br />
          </div>
        )}
        {!showAll && appointmentsList?.bookings?.length > 10 && (
          <div className="view-allbtn" ref={appointRef}>
            <button onClick={handleViewAll}>View all</button>
          </div>
        )}
      </div>

      <PatientDetailModal
        show={lgShow}
        onHide={handleModalClose}
        patient={selectedPatient}
      />
    </div>
  );
};

export default Appointment;
