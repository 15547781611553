import React from "react";
import "./AttendanceModal.css";

const AttendanceRow = ({ name }:any) => {
  return (
    <div className="attendance-row">
      <div className="name">{name}</div>
      <button className="check-in">Check In</button>
      <button className="check-out">Check Out</button>
      <button className="over-time">Over Time</button>
      <div className="mark-leave">
        <input type="checkbox" id={`leave-${name}`} />
        <label htmlFor={`leave-${name}`}>Mark Leave</label>
      </div>
      <div className="savebtn">
        <button className="save">Save</button>
      </div>
    </div>
  );
};

const MarkAttendance = () => {
  const attendees = [
    "Karthik Surya",
    "Aarav Sharma",
    "Riya Singh",
    "Ishaan Verma",
    "Mira Patel",
  ];

  return (
    <div className="attendance-list">
      <div className="list-heading">
        <div className="Date">21-08-2024</div>
        <div className="listhead">Mark Attendance</div>
      </div>
      {attendees.map((name, index) => (
        <AttendanceRow key={index} name={name} />
      ))}
    </div>
  );
};

export default MarkAttendance;
