import React, { useState } from "react";
import { ReactComponent as Calendaricon } from "../../../../assets/icons/calendar.svg";
import Calendar from "react-calendar";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import Bookings from "../../../../components/Bookings/Bookings";

const AttendanceOverview = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="bookings-sec">
      <div className="searcharfilter">
        <div className="search-bar">
          <input type="text" placeholder="Search Patient Name Serial No" />
        </div>
        <div className="calendar">
          <Calendaricon onClick={toggleModal} />
          <Modal
            show={showModal}
            onHide={toggleModal}
            centered
            className="custom-modal"
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <Calendar onChange={(date) => console.log(date)} />
            </Modal.Body>
          </Modal>
        </div>
        <div className="filterplace">
          <button>
            Filter <Filter />
          </button>
        </div>
      </div>
      <Bookings />
    </div>
  );
};

export default AttendanceOverview;
