import React, { useState } from "react";
import { Modal, Form, Row, Col, Dropdown, Button } from "react-bootstrap";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { useHistory } from "react-router-dom";
import { FiDownloadCloud } from "react-icons/fi";
// import * as XLSX from "xlsx";

interface FormData {
  name: string;
  mobileNumber: string;
  email: string;
  employeeId: string;
  age: string;
  gender: string;
  address: string;
  state: string;
  city: string;
  pincode: string;
}

const SearchOrder = ({ searchQuery, setSearchQuery, clientOrders }: any) => {
  const [searchText, setSearchText] = useState();
  console.log("clorder : ", clientOrders);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    mobileNumber: "",
    email: "",
    employeeId: "",
    age: "",
    gender: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const history = useHistory();

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleSearchInputChange = (e: any) => {
    setSearchText(e.target.value);
    setSearchQuery({
      text: e.target.value,
      status: searchQuery?.status || "",
    });
    // setSearchQuery(...searchQuery, text: e.target.value);
  };

  const handleCreateOrder = () => {
    history.push("/orders/create-order");
  };

  const handleExportExcel = () => {
    // const worksheet = XLSX.utils.json_to_sheet(clientOrders);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Client Orders");
    // XLSX.writeFile(workbook, "client_orders.xlsx");
  };

  // Function to handle input changes
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    let formErrors: Partial<FormData> = {};
    if (!formData.name.trim()) formErrors.name = "Please enter Patient name";
    if (!formData.mobileNumber.trim())
      formErrors.mobileNumber = "Please enter Mobile number";
    if (!formData.email.trim()) formErrors.email = "Please enter Email Id";
    if (!formData.employeeId.trim())
      formErrors.employeeId = "Please enter Employee Id";
    if (!formData.age.trim()) formErrors.age = "Please enter Age";
    if (!formData.gender.trim()) formErrors.gender = "Please select Gender";
    if (!formData.address.trim()) formErrors.address = "Please enter Address";
    if (!formData.state.trim()) formErrors.state = "Please select State";
    if (!formData.city.trim()) formErrors.city = "Please select City";
    if (!formData.pincode.trim()) formErrors.pincode = "Please enter Pin code";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSaveDetails = () => {
    if (validateForm()) {
      // Submit form
      handleClose();
    }
  };

  const handleStatusSelect = (status: string) => {
    setSelectedStatus(status);
    // Pass the selected status to the parent component
    setSearchQuery({ ...searchQuery, status });
  };

  return (
    <>
      <div className="controls">
        <div className="left-controls">
          {/* <div className="title">Orders</div> */}
          <div className="search-bar">
            <Search />
            <input
              type="text"
              placeholder="Search Order"
              onChange={handleSearchInputChange}
              value={searchText}
              style={{ color: "#000" }}
            />
          </div>
          <Button variant="outline-primary" onClick={handleExportExcel}>
            <FiDownloadCloud /> Export as Excel
          </Button>
        </div>
        <div className="right-buttons">
          {/* <Dropdown className="custom-dropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Status
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleStatusSelect("Completed")}>
                Completed
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatusSelect("Cancelled")}>
                Cancelled
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatusSelect("On Going")}>
                On Going
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatusSelect("Rejected")}>
                Rejected
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleStatusSelect("Pending")}>
                Pending
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Patient Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Patient name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formMobileNumber">
                  <Form.Label>Mobile Number *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Mobile number"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    isInvalid={!!errors.mobileNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobileNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email Id *</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Please enter Email Id"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmployeeId">
                  <Form.Label>Employee Id *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Employee Id"
                    name="employeeId"
                    value={formData.employeeId}
                    onChange={handleInputChange}
                    isInvalid={!!errors.employeeId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.employeeId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formAge">
                  <Form.Label>Age *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Age"
                    name="age"
                    value={formData.age}
                    onChange={handleInputChange}
                    isInvalid={!!errors.age}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.age}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formGender">
                  <Form.Label>Gender *</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    isInvalid={!!errors.gender}
                  >
                    <option>Select Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please type & select address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    isInvalid={!!errors.address}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formState">
                  <Form.Label>State *</Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    isInvalid={!!errors.state}
                  >
                    <option>Select State</option>
                    {/* Add options for states here */}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.state}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formCity">
                  <Form.Label>City *</Form.Label>
                  <Form.Control
                    as="select"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    isInvalid={!!errors.city}
                  >
                    <option>Select City</option>
                    {/* Add options for cities here */}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formPincode">
                  <Form.Label>Pin code *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter Pin code"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    isInvalid={!!errors.pincode}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pincode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveDetails}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchOrder;
