import React, { useEffect, useState } from "react";
import { Form, Button, Radio, Upload, message, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import axios from "axios";
import { toast } from "react-toastify";
import {
  getDoctorProfileDetails,
  updateProofUploadAPI,
} from "../../redux/slices/doctor/doctorService";

const IdentityProof = () => {
  const [identityProof, setIdentityProof] = useState("");
  const [errorData, setErrorData] = useState("");
  const [frontImageUrl, setFrontImageUrl] = useState("");
  const [backImageUrl, setBackImageUrl] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const handleInputChange = (e: any) => {
    setIdentityProof(e.target.value);
    setErrorData(""); // Clear error when user selects a valid option
  };

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();
  const [name, setName] = useState<any>();

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, [user, dispatch]);

  const prefillData = (data: any) => {
    const doctor = data?.payload?.data?.doctor;

    if (doctor?.name) {
      setName(`${doctor.name}`);
    }
    if (doctor?.identity_proof_type) {
      setIdentityProof(doctor.identity_proof_type); // Set the identity proof type
    }
    if (doctor?.identity_proof_file_front) {
      setFrontImageUrl(doctor.identity_proof_file_front);
    }
    if (doctor?.identity_proof_file_back) {
      setBackImageUrl(doctor.identity_proof_file_back);
    }
  };

  const validateForm = () => {
    if (!identityProof) {
      setErrorData("Please select an identity proof");
      return false;
    }
    if (!frontImageUrl || !backImageUrl) {
      setErrorData("Please upload both front and back images");
      return false;
    }
    return true;
  };

  const uploadImageToS3 = async (image: File) => {
    try {
      const presignBody = {
        id: `${doctorId}`,
        ext: ".png",
      };
      const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
      const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));
      console.log("presignedUrlResp", presignedUrlResp);

      const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
      const publicUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;

      await axios.put(presignedUrl, image, {
        headers: {
          "Content-Type": "image/png", // Ensure the correct content type is set
        },
      });
      console.log("Uploaded Image URL:", publicUrl);
      return publicUrl;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleFrontFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const frontUrl = await uploadImageToS3(file);
        setFrontImageUrl(frontUrl);
        console.log("Front image uploaded:", frontUrl);
      } catch (error) {
        message.error("Error uploading front image. Please try again.");
      }
    }
  };

  const handleBackFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const backUrl = await uploadImageToS3(file);
        setBackImageUrl(backUrl);
        console.log("Back image uploaded:", backUrl);
      } catch (error) {
        message.error("Error uploading back image. Please try again.");
      }
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const payload = {
        id: doctorId,
        proofObj: {
          identity_proof_type: identityProof,
          identity_proof_file_front: frontImageUrl,
          identity_proof_file_back: backImageUrl,
        },
      };
      const res = await dispatch(updateProofUploadAPI(payload));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        toast.success("Identity proof updated successfully");
        history.push("/register/med-register-proof");
      }
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Identity Proof</h2>
            <p>Dr. {name}</p>
            <Form onFinish={handleSubmit} className="identity-proof-form">
              <Form.Item
                label="Please select your identity proof"
                validateStatus={errorData ? "error" : ""}
                help={errorData}
              >
                <Radio.Group
                  onChange={handleInputChange}
                  value={identityProof} // Bind the value to the state
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Radio value="Aadhar">Aadhar</Radio>
                  <Radio value="License">License</Radio>
                  <Radio value="VoterID">VoterID</Radio>
                  <Radio value="Other">Other</Radio>
                </Radio.Group>
              </Form.Item>

              <div className="upload-photo">
                <div className="widthset">
                  <div className="frontphoto">
                    <h6>Front</h6>
                    {frontImageUrl && (
                      <Image
                        src={frontImageUrl}
                        alt="Front of ID"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    )}
                    <Upload
                      accept="image/png"
                      showUploadList={false}
                      onChange={handleFrontFileChange}
                    >
                      <Button
                        style={{ height: "100%" }}
                        icon={<UploadOutlined />}
                      >
                        Upload Front Image
                      </Button>
                    </Upload>
                  </div>
                  <p className="support">Supported format: PNG</p>
                </div>
                <div className="widthset">
                  <div className="backphoto">
                    <h6>Back</h6>
                    {backImageUrl && (
                      <Image
                        src={backImageUrl}
                        alt="Back of ID"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    )}
                    <Upload
                      accept="image/png"
                      showUploadList={false}
                      onChange={handleBackFileChange}
                    >
                      <Button
                        style={{ height: "100%" }}
                        icon={<UploadOutlined />}
                      >
                        Upload Back Image
                      </Button>
                    </Upload>
                  </div>
                  <p className="maxi">Maximum size: 25 MB</p>
                </div>
              </div>

              <div className="paragraph-text">
                <p>
                  Please upload your identity proof to ensure that the ownership
                  of your profile remains with only you.
                </p>
                <p>
                  Ensure all text and images are clear, legible, and readable
                  (no blurriness or pixelation). Provide a clear document title
                  and description.
                </p>
              </div>

              <div className="button-container flex-row">
                <Button
                  type="default"
                  style={{ height: "100%" }}
                  onClick={() => history.push("/register/progress-summary")}
                  className="back-button"
                >
                  <BackIcon /> Back
                </Button>
                <Button
                  style={{ height: "100%" }}
                  type="primary"
                  htmlType="submit"
                  className="continue-button"
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default IdentityProof;
