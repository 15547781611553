import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { useHistory } from "react-router-dom";
import { Image, message, Upload, Button as AntdButton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import { toast } from "react-toastify";
import {
  getDoctorProfileDetails,
  updateProofUploadAPI,
} from "../../redux/slices/doctor/doctorService";

const MedRegisterProof: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [errorData, setErrorData] = useState<string>("");
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState<string>("");
  const dispatch = useDispatch();

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();
  const [name, setName] = useState<any>();

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, []);

  const prefillData = (data: any) => {
    console.log("prefillData : ", data?.payload);
    if (data?.payload?.data?.doctor?.name) {
      setName(`${data?.payload?.data?.doctor?.name}`);
    }
    if (data?.payload?.data?.doctor?.medical_registration_file) {
      setImageUrl(`${data?.payload?.data?.doctor?.medical_registration_file}`);
    }
  };

  const uploadImageToS3 = async (image: File) => {
    try {
      const presignBody = {
        id: `${doctorId}`,
        ext: ".png",
      };
      const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
      const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));
      console.log("presignedUrlResp", presignedUrlResp);

      const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
      const publicUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;

      await axios.put(presignedUrl, image, {
        headers: {
          "Content-Type": "image/png", // Ensure the correct content type is set
        },
      });
      console.log("Uploaded Image URL:", publicUrl);
      return publicUrl;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleImgFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const frontUrl = await uploadImageToS3(file);
        setImageUrl(frontUrl);
        console.log("Front image uploaded:", frontUrl);
      } catch (error) {
        message.error("Error uploading front image. Please try again.");
      }
    }
  };

  const handleSubmit = async () => {
    if (imageUrl) {
      const payload = {
        id: doctorId,
        proofObj: {
          medical_registration_file: imageUrl,
        },
      };
      const res = await dispatch(updateProofUploadAPI(payload));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        toast.success("Medical registration proof updated successfully");
        history.push("/register/establishment-proof");
      }
    } else {
      setErrorData("Please upload your medical registration proof.");
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Medical Registration Proof</h2>
            <p>Dr. {name}</p>
            <Form>
              <div className="photo">
                <div className="frontphoto">
                  {imageUrl && (
                    <Image
                      src={imageUrl}
                      alt="Front of ID"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  )}
                  <Upload
                    accept="image/png"
                    showUploadList={false}
                    onChange={handleImgFileChange}
                  >
                    <AntdButton
                      style={{ height: "100%" }}
                      icon={<UploadOutlined />}
                    >
                      Upload Front Image
                    </AntdButton>
                  </Upload>
                </div>
                {errorData && (
                  <Form.Control.Feedback type="invalid">
                    {errorData}
                  </Form.Control.Feedback>
                )}
                <div className="photodet">
                  <p>Supported format: PNG, SVG, JPG</p>
                  <p>Maximum size: 25 MB</p>
                </div>
                <div className="paragraph-text">
                  <p>
                    Please upload your medical registration proof. Only licensed
                    and genuine doctors are listed on RaphaCure.
                  </p>
                </div>
              </div>
              <div className="button-container flex-row">
                <Button
                  className="back-button"
                  onClick={() => history.push("/register/identity-proof")} // Ensure this matches the route path
                >
                  <BackIcon /> Back
                </Button>
                <Button
                  type="button"
                  className="continue-button"
                  onClick={() => handleSubmit()}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default MedRegisterProof;
