import React, { useState } from "react";
import { PatientStyled } from "./Patients.Styled";
import SearchPatient from "./SearchPatient";
import PatientsTable from "./PatientsTable";

const MyPatients = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <PatientStyled>
      <div className="patient-page">
        <SearchPatient onSearch={handleSearch} />
        <PatientsTable searchTerm={searchTerm} />
      </div>
    </PatientStyled>
  );
};

export default MyPatients;
