// EducationQualification.js
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { useDispatch, useSelector } from "react-redux";
import {
  doctorQualification,
  getDoctorProfileDetails,
} from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { Select } from "antd";
import { degrees } from "../../utils/constants";

const { Option } = Select;

const EducationQualification = () => {
  const history = useHistory();
  const [existingUser, setExistingUser] = useState(false);
  const dispatch = useDispatch();
  const [educationDetails, setEducationDetails] = useState({
    degree: "",
    college: "",
    completionYear: "",
    experience: "",
  });

  const [errorData, setErrorData] = useState({
    degree: "",
    college: "",
    completionYear: "",
    experience: "",
  });

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();

  const [secondDocLinkableId, setSecondDocLinkableId] = useState<any>(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const childDocId = searchParams.get("doctorId");
    setSecondDocLinkableId(childDocId);
  }, [location]);

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, []);

  const prefillData = (data: any) => {
    console.log("prefillData : ", data?.payload);
    const searchParams = new URLSearchParams(location.search);
    const childDocId = searchParams.get("doctorId");
    if (childDocId) {
      return;
    }
    setEducationDetails({
      degree: `${data?.payload?.data?.doctor?.highest_education || ""}`,
      college: `${data?.payload?.data?.doctor?.college || ""}`,
      completionYear: `${
        data?.payload?.data?.doctor?.college_completion_year || ""
      }`,
      experience: `${data?.payload?.data?.doctor?.work_experience_years || ""}`,
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEducationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      ...(name === "completionYear" && {
        experience: (new Date().getFullYear() - parseInt(value)).toString(),
      }),
    }));

    if (value.trim() !== "") {
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {} as any;

    if (!educationDetails?.degree?.trim()) {
      errors.degree = "Please select your Degree";
    }
    if (!educationDetails?.college?.trim()) {
      errors.college = "Please select your College/Institute";
    }
    if (!educationDetails?.completionYear?.trim()) {
      errors.completionYear = "Please select Year of Completion";
    }
    if (!educationDetails?.experience?.trim()) {
      errors.experience = "Please select Year of Experience";
    }

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        id: doctorId,
        qualificationObj: {
          doctor: {
            work_experience_years: educationDetails.experience,
            highest_education: educationDetails.degree,
            college: educationDetails.college,
            college_completion_year: educationDetails.completionYear,
          },
        },
      };
      const res = await dispatch(doctorQualification(payload));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        toast.success("Educational Qualification updated successfully");
        if (secondDocLinkableId) {
          history.push(
            `/register/connect-practice?doctorId=${secondDocLinkableId}`
          );
        } else history.push("/register/connect-practice");
      }
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Education Qualification</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="degree">
                <Form.Label className="form-label">Degree</Form.Label>
                <Select
                  showSearch
                  placeholder="Type & Select degree"
                  value={educationDetails.degree}
                  onChange={(value) =>
                    handleInputChange({ target: { name: "degree", value } })
                  }
                  className={!!errorData.degree ? "is-invalid" : ""}
                >
                  {degrees.map((degree, index) => (
                    <Option key={index} value={degree}>
                      {degree}
                    </Option>
                  ))}
                </Select>

                {errorData.degree && (
                  <div className="invalid-feedback d-block">
                    {errorData.degree}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="college">
                <Form.Label className="form-label">
                  College/Institute
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter College/Institute"
                  name="college"
                  value={educationDetails.college}
                  onChange={handleInputChange}
                  className={!!errorData.college ? "is-invalid" : ""}
                />
                {errorData.college && (
                  <div className="invalid-feedback d-block">
                    {errorData.college}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="completionYear">
                <Form.Label className="form-label">
                  Year of completion
                </Form.Label>
                <Select
                  showSearch
                  placeholder="Select year of completion"
                  value={educationDetails.completionYear}
                  onChange={(value) =>
                    handleInputChange({
                      target: { name: "completionYear", value },
                    })
                  }
                  className={!!errorData.completionYear ? "is-invalid" : ""}
                >
                  {Array.from(
                    { length: new Date().getFullYear() - 1950 + 1 },
                    (_, index) => {
                      const year = new Date().getFullYear() - index;
                      return (
                        <Option key={year} value={year.toString()}>
                          {year}
                        </Option>
                      );
                    }
                  )}
                </Select>

                {errorData.completionYear && (
                  <div className="invalid-feedback d-block">
                    {errorData.completionYear}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="experience">
                <Form.Label className="form-label">
                  Year of experience (between 0-70 years)
                </Form.Label>
                <Select
                  showSearch
                  placeholder="Type year of experience"
                  value={educationDetails.experience}
                  onChange={(value) =>
                    handleInputChange({ target: { name: "experience", value } })
                  }
                  className={!!errorData.experience ? "is-invalid" : ""}
                >
                  {Array.from({ length: 71 }, (_, index) => (
                    <Option key={index} value={index.toString()}>
                      {index}
                    </Option>
                  ))}
                </Select>
                {errorData.experience && (
                  <div className="invalid-feedback d-block">
                    {errorData.experience}
                  </div>
                )}
              </Form.Group>

              <div className="button-container flex-row">
                <Button
                  className="back-button"
                  onClick={() => history.push("medicalregistration")}
                >
                  <BackIcon /> Back
                </Button>
                <Button className="continue-button" type="submit">
                  Next
                </Button>
              </div>
            </Form>
            <div className="info-box">
              On RaphaCure, doctors are classified by their specializations
              according to their academic degrees. Education and years of
              experience highlight a doctor’s expertise and are among the top
              three factors patients consider when making their decision.
            </div>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default EducationQualification;
