import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import axios from "axios";
import {
  message,
  Upload,
  Button as AntdButton,
  Image as AntdImage,
} from "antd";
import {
  getDoctorProfileDetails,
  updateProofUploadAPI,
} from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { UploadOutlined } from "@ant-design/icons";

const EstablishmentProof: React.FC = ({ prevStep, nextStep }: any) => {
  const [establishmentType, setEstablishmentType] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [errorData, setErrorData] = useState({
    establishmentType: "",
    file: "",
  });
  const [imageUrl, setImageUrl] = useState<string>("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (selectedFile.size > 25 * 1024 * 1024) {
        // 25 MB
        setErrorData((prev) => ({ ...prev, file: "File size exceeds 25 MB" }));
        setFile(null);
      } else if (
        !["image/png", "image/jpeg", "image/svg+xml"].includes(
          selectedFile.type
        )
      ) {
        setErrorData((prev) => ({
          ...prev,
          file: "Invalid file format. Only PNG, JPG, and SVG are allowed.",
        }));
        setFile(null);
      } else {
        setFile(selectedFile);
        setErrorData((prev) => ({ ...prev, file: "" }));
      }
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEstablishmentType(e.target.value);
    setErrorData((prev) => ({ ...prev, establishmentType: "" }));
  };

  const validateForm = () => {
    let isValid = true;
    if (!establishmentType) {
      setErrorData((prev) => ({
        ...prev,
        establishmentType: "Please select an establishment type.",
      }));
      isValid = false;
    }
    if (!file) {
      setErrorData((prev) => ({
        ...prev,
        file: "Please upload your establishment proof.",
      }));
      isValid = false;
    }
    console.log("Form validation:", isValid); // Debugging
    return isValid;
  };

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, [user, dispatch]);

  const prefillData = (data: any) => {
    console.log("prefillData : ", data.payload);
    if (data?.payload?.data?.doctor?.establishment_type) {
      setEstablishmentType(
        `${data?.payload?.data?.doctor?.establishment_type}`
      );
    }
    if (data?.payload?.data?.doctor?.establishment_proof) {
      setImageUrl(`${data?.payload?.data?.doctor?.establishment_proof}`);
    }
  };

  const uploadImageToS3 = async (image: File) => {
    try {
      const presignBody = {
        id: `${doctorId}`,
        ext: ".png",
      };
      const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
      const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));
      console.log("presignedUrlResp", presignedUrlResp);

      const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
      const publicUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;

      await axios.put(presignedUrl, image, {
        headers: {
          "Content-Type": "image/png", // Ensure the correct content type is set
        },
      });
      console.log("Uploaded Image URL:", publicUrl);
      return publicUrl;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleImgFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const frontUrl = await uploadImageToS3(file);
        setImageUrl(frontUrl);
        console.log("Front image uploaded:", frontUrl);
      } catch (error) {
        message.error("Error uploading front image. Please try again.");
      }
    }
  };

  const handleSubmit = async () => {
    if (establishmentType && imageUrl && doctorId) {
      const payload = {
        id: doctorId,
        proofObj: {
          establishment_proof: imageUrl,
          establishment_proof_file: establishmentType,
        },
      };
      const res = await dispatch(updateProofUploadAPI(payload));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        toast.success("Establishment proof updated successfully");
        history.push("/register/progress-summary-two");
      }
    } else {
      setErrorData((prev) => ({
        ...prev,
        file: "Please upload your establishment proof.",
      }));
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Establishment Proof</h2>
            <p>Cure Clinic</p>
            <Form>
              <Form.Group>
                <Form.Label className="form-label">I am</Form.Label>
                <div className="establishment-options">
                  <div onClick={() => setEstablishmentType("owner")}>
                    <Form.Check
                      inline
                      type="radio"
                      label="The owner of establishment"
                      value="owner"
                      name="establishmentType"
                      checked={establishmentType === "owner"}
                      onChange={handleRadioChange}
                      isInvalid={!!errorData.establishmentType}
                    />
                  </div>
                  <div onClick={() => setEstablishmentType("rented")}>
                    <Form.Check
                      inline
                      type="radio"
                      label="Have rented at other establishment"
                      value="rented"
                      name="establishmentType"
                      checked={establishmentType === "rented"}
                      onChange={handleRadioChange}
                      isInvalid={!!errorData.establishmentType}
                    />
                  </div>
                  <div onClick={() => setEstablishmentType("consultant")}>
                    <Form.Check
                      inline
                      type="radio"
                      label="A consultant doctor"
                      value="consultant"
                      name="establishmentType"
                      checked={establishmentType === "consultant"}
                      onChange={handleRadioChange}
                      isInvalid={!!errorData.establishmentType}
                    />
                  </div>
                  <div onClick={() => setEstablishmentType("home")}>
                    <Form.Check
                      inline
                      type="radio"
                      label="Practicing at home"
                      value="home"
                      name="establishmentType"
                      checked={establishmentType === "home"}
                      onChange={handleRadioChange}
                      isInvalid={!!errorData.establishmentType}
                    />
                  </div>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errorData.establishmentType}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="photoupd">
                <p className="textdata">Accepted Proof (Any one)</p>
                <p className="textdata">
                  Clinic Registration Proof/ Waste Disposal Proof/ Tax receipt
                </p>
                <div className="frontphoto">
                  {imageUrl && (
                    <AntdImage
                      src={imageUrl}
                      alt="Front of ID"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  )}
                  <Upload
                    accept="image/png"
                    showUploadList={false}
                    onChange={handleImgFileChange}
                  >
                    <AntdButton
                      style={{ height: "100%" }}
                      icon={<UploadOutlined />}
                    >
                      Upload Front Image
                    </AntdButton>
                  </Upload>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errorData.file}
                </Form.Control.Feedback>
                <div className="photodet">
                  <p>Supported format: PNG</p>
                  <p>Maximum size: 25 MB</p>
                </div>
                <div className="paragraph-text">
                  <p>
                    Please upload your establishment proof to verify your
                    practice location.
                  </p>
                </div>
              </div>
              <div className="button-container flex-row">
                <Button
                  className="back-button"
                  onClick={() => history.push("/register/med-register-proof")} // Navigate to MedRegisterProof component
                >
                  <BackIcon /> Back
                </Button>
                <Button
                  type="button"
                  className="continue-button"
                  onClick={() => handleSubmit()}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default EstablishmentProof;
