import React, { useEffect, useState } from "react";
import { ClinicStyled } from "./Clinic.Styled";
import { FaPlus } from "react-icons/fa";
import { Modal, Button, Form, Pagination } from "react-bootstrap";
import "./Clinics.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addClinicAPI,
  deleteClinicAPI,
  getAllClinicsAPI,
  getAllDoctorClinicsAPI,
  updateClinicAPI,
} from "../../redux/slices/doctor/doctorService";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";
// import Select from "react-select";
import { Input } from "antd";
import { Select } from "antd";
import { toast } from "react-toastify";
import Loader from "../../components/Common/Loader";
const { Option } = Select;

interface City {
  id: string;
  name: string;
  image: string;
  state_name: string | null;
}

const Clinics = () => {
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentClinic, setCurrentClinic] = useState<any>(null);
  const [clinicName, setClinicName] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [clinicState, setClinicState] = useState("");
  const [clinicCity, setClinicCity] = useState<any>(null);
  const [clinicPin, setClinicPin] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [cities, setCities] = useState<any>(configInfo?.cities || []);
  const [clinics, setClinics] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const clinicsPerPage = 6;
  const [docId, setDocId] = useState();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clinicToDelete, setClinicToDelete] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState(false);

  const [errors, setErrors] = useState({
    clinicName: "",
    clinicAddress: "",
    clinicState: "",
    clinicCity: "",
    clinicPin: "",
    timings: "",
  });
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const generateTimeOptions = () => {
    const options = [];
    const startHour = 7; // 7 AM
    const endHour = 21; // 9 PM

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 30) {
        const time = new Date(2024, 0, 1, hour, minutes);
        const timeString = time.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
        options.push({
          value: time.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
          label: timeString,
        });
      }
    }
    return options;
  };

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        setDocId(user.roles[0].linkable_id);
      }
    }
  }, [user]);

  useEffect(() => {
    console.log("cities : ", cities);
  }, [cities]);

  const getClinics = async () => {
    if (!docId) {
      return;
    }
    setShowLoader(true);
    const res = await dispatch(getAllDoctorClinicsAPI(docId));
    const resJson = JSON.parse(JSON.stringify(res));
    console.log("resjson : ", resJson);

    if (resJson?.error) {
      toast.error(resJson?.error?.message);
      setShowLoader(false);
      return;
    }
    if (resJson.payload) {
      const filteredClinics = resJson.payload.doctorClinics.filter(
        (clinic: any) => clinic.status !== "deleted"
      );
      const sortedClinics = filteredClinics.sort(
        (a: any, b: any) => a.id - b.id
      );
      setClinics(sortedClinics);
      console.log("sortedClinics: ", sortedClinics);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getClinics();
    dispatch(getConfigInfoAPI());
  }, [dispatch, docId]);

  useEffect(() => {
    if (configInfo?.cities) {
      setCities(configInfo.cities);
    }
  }, [configInfo]);

  const handleClose = () => {
    setShow(false);
    setEditMode(false);
    setCurrentClinic(null);
    clearForm();
  };

  const handleShow = (city: any) => {
    setClinicCity(city);
    setShow(true);
  };

  const handleAddClinic = () => {
    setEditMode(false);
    setCurrentClinic(null);
    clearForm();
    handleShow("");
  };

  const handleEditClinic = (clinic: any) => {
    setEditMode(true);
    setCurrentClinic(clinic);
    setClinicName(clinic?.name);
    setClinicAddress(clinic?.address);
    setClinicState(clinic?.state || "");
    const city = clinic?.city?.trim();
    console.log("cities: ", cities);
    setClinicCity(cities?.find((city: any) => city.id === city) || null);
    setClinicPin(clinic?.zip?.toString() || "");
    setStartTime(clinic?.slot_start_time?.slice(0, 5) || "");
    setEndTime(clinic?.slot_end_time?.slice(0, 5) || "");
    handleShow(city);
  };

  const clearForm = () => {
    setClinicName("");
    setClinicAddress("");
    setClinicState("");
    setClinicCity(null);
    setClinicPin("");
    setStartTime("");
    setEndTime("");
    setErrors({
      clinicName: "",
      clinicAddress: "",
      clinicState: "",
      clinicCity: "",
      clinicPin: "",
      timings: "",
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!clinicName?.trim()) newErrors.clinicName = "Clinic name is required";
    if (!clinicAddress?.trim())
      newErrors.clinicAddress = "Clinic address is required";
    if (!clinicState?.trim()) newErrors.clinicState = "State is required";
    if (!clinicCity) newErrors.clinicCity = "City is required";
    if (!clinicPin?.trim()) newErrors.clinicPin = "Pin code is required";
    if (!startTime || !endTime) {
      newErrors.timings = "Please provide start and end times";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveChanges = async () => {
    const clinicData = {
      clinicName,
      address: clinicAddress,
      state: clinicState,
      city: clinicCity?.name?.toLowerCase() || clinicCity,
      pinCode: clinicPin,
      timings: {
        start_time: startTime + ":00",
        end_time: endTime + ":00",
      },
    };

    if (validateForm()) {
      setShowLoader(true);
      const res = await dispatch(addClinicAPI(clinicData));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res : ", resJson);
      if (resJson.error) {
        toast.error(resJson?.error?.message);
        setShowLoader(false);
      } else {
        toast.success("Clinic added successfully");
        const newClinic = resJson?.payload?.clinic;
        setClinics((prevClinics) => [newClinic, ...prevClinics]);
        setShowLoader(false);
      }
      handleClose();
    }
  };

  const handleEdit = async () => {
    if (validateForm()) {
      setShowLoader(true);
      const clinicData = {
        clinicName,
        address: clinicAddress,
        state: clinicState,
        city: clinicCity?.id || clinicCity,
        pinCode: clinicPin,
        timings: {
          start_time: startTime + ":00",
          end_time: endTime + ":00",
        },
      };

      const payload = {
        id: currentClinic.id,
        myclinic: {
          myclinic: clinicData,
        },
      };

      const res = await dispatch(updateClinicAPI(payload));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resjson : ", resJson);

      getClinics();
      setShowLoader(false);
      handleClose();
    }
  };

  const handleDeleteClinic = async (clinicId: string) => {
    if (window.confirm("Are you sure you want to delete this clinic?")) {
      await dispatch(deleteClinicAPI(clinicId));
      getClinics();
    }
  };

  const filteredClinics = clinics.filter((clinic) =>
    clinic.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastClinic = currentPage * clinicsPerPage;
  const indexOfFirstClinic = indexOfLastClinic - clinicsPerPage;
  const currentClinics = filteredClinics.slice(
    indexOfFirstClinic,
    indexOfLastClinic
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  let items = [];
  for (
    let number = 1;
    number <= Math.ceil(filteredClinics.length / clinicsPerPage);
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => paginate(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const stateList = [
    {
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
    { label: "Daman and Diu", value: "Daman and Diu" },
    { label: "Delhi", value: "Delhi" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Puducherry", value: "Puducherry" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
  ];

  const handleShowDeleteModal = (clinicId: string) => {
    setClinicToDelete(clinicId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (clinicToDelete) {
      await dispatch(deleteClinicAPI(clinicToDelete));
      getClinics();
      setShowDeleteModal(false);
    }
  };

  return (
    <ClinicStyled>
      {showLoader && <Loader />}
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            <Input
              placeholder="Search clinics"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div
              className="clinic-card card add-clinic-card"
              onClick={handleAddClinic}
            >
              <div className="card-body text-center">
                <FaPlus size={50} />
                <p className="add-clinic-text">Add Clinic</p>
              </div>
            </div>
          </div>

          {currentClinics.map((clinic: any, index: any) => (
            <div className="col-12 col-md-4" key={index}>
              <div className="clinic-card card">
                <div className="card-body">
                  <h5 className="card-title">{clinic?.name}</h5>
                  <p className="card-text">{clinic?.address}</p>
                  <p className="card-text">
                    {[clinic?.city, clinic?.state, clinic?.zip]
                      .filter(Boolean)
                      .join(", ")}
                  </p>
                  <p className="time-text">
                    Timings: {clinic?.slot_start_time?.slice(0, 5)} to{" "}
                    {clinic?.slot_end_time?.slice(0, 5)}
                  </p>
                  <div className="card-buttons">
                    <button
                      className="btn btn-link"
                      onClick={() => handleEditClinic(clinic)}
                    >
                      Edit
                    </button>
                    <span> | </span>

                    <button
                      className="btn btn-link remove"
                      onClick={() => handleShowDeleteModal(clinic.id)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-center">
            <Pagination>
              <Pagination.Prev
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {items.length > 7 ? (
                <>
                  {items.slice(0, 3)}
                  <Pagination.Ellipsis />
                  {items.slice(items.length - 3, items.length)}
                </>
              ) : (
                items
              )}
              <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredClinics.length / clinicsPerPage)
                }
              />
            </Pagination>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Clinic Details" : "Add Clinic Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bodymodal">
          <Form>
            <Form.Group controlId="clinicName">
              <Form.Label>Clinic Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter clinic name"
                value={clinicName}
                onChange={(e) => setClinicName(e.target.value)}
                isInvalid={!!errors.clinicName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.clinicName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="clinicAddress" className="mt-3">
              <Form.Label>Address *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please type & select address"
                value={clinicAddress}
                onChange={(e) => setClinicAddress(e.target.value)}
                isInvalid={!!errors.clinicAddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.clinicAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="clinicState" className="mt-3">
              <Form.Label>State *</Form.Label>
              <Select
                showSearch
                value={clinicState}
                style={{ width: "100%" }}
                placeholder="Select a state"
                optionFilterProp="children"
                onChange={(value) => setClinicState(value)}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                dropdownStyle={{ zIndex: 9999 }}
              >
                {stateList.map((state) => (
                  <Select.Option key={state.value} value={state.value}>
                    {state.label}
                  </Select.Option>
                ))}
              </Select>
              {errors.clinicState && (
                <div className="text-danger">{errors.clinicState}</div>
              )}
            </Form.Group>

            <div className="row mt-3">
              <Form.Group
                controlId="clinicCity"
                className="col-6 d-flex flex-column"
              >
                <Form.Label>City *</Form.Label>
                <Select
                  showSearch
                  value={clinicCity}
                  onChange={(selectedOption) =>
                    setClinicCity(selectedOption as City)
                  }
                  options={cities.map((city: any) => ({
                    value: city.id,
                    label: city.name, // this is what will be displayed
                    ...city,
                  }))}
                  placeholder="Select city"
                  optionFilterProp="label"
                  filterOption={(input, option: any) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                  dropdownStyle={{ zIndex: 9999 }}
                />
                {errors.clinicCity && (
                  <div className="text-danger">{errors.clinicCity}</div>
                )}
              </Form.Group>

              <Form.Group controlId="clinicPin" className="col-6">
                <Form.Label>Pin Code *</Form.Label>
                <Form.Control
                  type="text" // Use text for better input control
                  inputMode="numeric" // Opens numeric keypad on mobile
                  pattern="\d*" // Allows only numeric input
                  maxLength={6} // Limits input to 6 digits
                  placeholder="Enter pin code"
                  value={clinicPin}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ""); // Filter non-numeric characters
                    setClinicPin(value); // Update state with valid input
                  }}
                  isInvalid={!!errors.clinicPin}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.clinicPin}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <h5 className="mt-4 timings-text">Add Timings</h5>
            <div className="row mt-3">
              <div className="col-6">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select start time"
                  optionFilterProp="label"
                  className="timeSelect"
                  value={startTime}
                  onChange={(value) => {
                    setStartTime(value);
                    if (endTime && value >= endTime) setEndTime("");
                  }}
                  options={generateTimeOptions()}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  dropdownStyle={{ zIndex: 1100 }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </div>
              <div className="col-6">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select end time"
                  optionFilterProp="label"
                  value={endTime}
                  className="timeSelect"
                  onChange={(value) => setEndTime(value)}
                  options={generateTimeOptions().filter(
                    (option) => option.value > startTime
                  )}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  disabled={!startTime}
                  dropdownStyle={{ zIndex: 1100 }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </div>
            </div>
            {errors.timings && <p className="text-danger">{errors.timings}</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn-save"
            onClick={editMode ? handleEdit : handleSaveChanges}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this clinic? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </ClinicStyled>
  );
};

export default Clinics;
