import React, { useEffect } from "react";
import Table from "react-bootstrap/Table";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as Action } from "../../assets/icons/action1.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Call } from "../../assets/icons/call.svg";
import { BookingStyled } from "../../components/Bookings/Bookings.styled";
import { useDispatch, useSelector } from "react-redux";
import { getAllBookingListAPI } from "../../redux/slices/dashboard/dashboardService";

const order = [
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
  {
    serialno: "#3456",
    name: "Abhay Gupta",
    age: 30,
    datetime: "21 Jan 10:30PM	",
    appointfor: "Fever & cough",
    source: "Instagram",
  },
];

const Bookings = () => {
  const dispatch = useDispatch();

  const { bookingList, myBookings } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  const bookingInfo = bookingList?.bookings || [];

  const formatDate = (timestamp: any) => {
    const date = new Date(parseInt(timestamp, 10));
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }${ampm}`;
    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    getAllBookingListAPI({ count: 100 });
  }, []);
  return (
    <BookingStyled>
      <div className="appoint-order">
        <div className="ordertable">
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Name</th>
                <th>Age</th>
                <th>Date & Time</th>
                <th>Visit Purpose</th>
                <th>Source</th>
                <th className="action-report">Report</th>
                <th className="action-report">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bookingInfo.map((item: any, index: any) => (
                <tr key={index}>
                  <td>#{item?.user?.id} </td>
                  <td>{item?.user?.first_name}</td>
                  <td>{item?.user?.age}</td>
                  <td>{`${formatDate(item.collection_1_date)}`} </td>
                  <td>{item.notes} </td>
                  <td>{item.source}</td>
                  <td className="action-report">
                    <Report />
                  </td>
                  <td className="actions">
                    <Action />
                    <span className="icon-space"></span> <Share />
                    <span className="icon-space"></span> <Download />
                    <span className="icon-space"></span> <Call />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </BookingStyled>
  );
};

export default Bookings;
