import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  doctorEstablishmentDetails,
  getDoctorProfileDetails,
} from "../../redux/slices/doctor/doctorService";
import { Select } from "antd";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";

const { Option } = Select;

interface Errors {
  establishmentName?: string;
  city?: string;
  locality?: string;
}

const EstablishmentDetails = () => {
  const history = useHistory();
  const [establishmentName, setEstablishmentName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [locality, setLocality] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});
  const [practice, setPractice] = useState<any>();
  const [docDetails, setDocDetails] = useState<any>({});
  const dispatch = useDispatch();
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [cities, setCities] = useState<any>(configInfo?.cities || []);

  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, [dispatch]);
  useEffect(() => {
    if (configInfo?.cities) {
      setCities(configInfo.cities);
    }
  }, [configInfo]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const practice =
      docDetails?.establishment_type || searchParams.get("practice");
    setPractice(practice);
    console.log("practice", practice);
    if (!practice) {
      console.error("Please connect a practice first");
      // history.push("/register/connect-practice");
    }
  }, [docDetails]);

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, []);

  const prefillData = (data: any) => {
    const doctor = data?.payload?.data?.doctor;
    setDocDetails(doctor);
    if (doctor?.establishment_name) {
      setEstablishmentName(doctor.establishment_name);
    }
    if (doctor?.establishment_city) {
      setCity(doctor.establishment_city);
    }
    if (doctor?.establishment_locality) {
      setLocality(doctor.establishment_locality);
    }
    if (doctor?.establishment_type) {
      setPractice(doctor.establishment_type);
    }
  };

  const validateForm = () => {
    const newErrors: Errors = {};
    if (!establishmentName)
      newErrors.establishmentName = "Establishment Name is required.";
    if (!city) newErrors.city = "City is required.";
    if (!locality) newErrors.locality = "Locality is required.";
    return newErrors;
  };

  const handleNextStep = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      const payload = {
        id: doctorId,
        establishmentObj: {
          establishment_name: establishmentName,
          establishment_city: city,
          establishment_locality: locality,
        },
      };
      const res = await dispatch(doctorEstablishmentDetails(payload));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        toast.success("Establishment details updated successfully");
        history.push("/register/progress-summary");
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleLocalityChange = (e: any) => {
    const value = e.target.value;
    setLocality(value);

    // Example validation
    if (!value) {
      setErrors({ locality: "Locality is required" });
    } else {
      setErrors({});
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Establishment basic details</h2>
            <Form>
              <Form.Group controlId="establishmentName">
                <Form.Label className="form-label">
                  Establishment Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Type establishment name"
                  value={establishmentName}
                  onChange={(e) => setEstablishmentName(e.target.value)}
                  isInvalid={!!errors.establishmentName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.establishmentName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label className="form-label">City</Form.Label>
                <Select
                  showSearch
                  placeholder="Select City"
                  value={city}
                  onChange={(value) => setCity(value)}
                  style={{ width: "100%" }}
                  options={cities.map((city: any) => ({
                    value: city.id,
                    label: city.name,
                  }))}
                />
                {errors.city && (
                  <div className="invalid-feedback d-block">{errors.city}</div>
                )}
              </Form.Group>

              <Form.Group controlId="locality">
                <Form.Label className="form-label">Locality</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter or select a locality"
                  value={locality}
                  onChange={handleLocalityChange}
                  className={errors.locality ? "is-invalid" : ""}
                  list="locality-options"
                />
                {errors.locality && (
                  <div className="invalid-feedback d-block">
                    {errors.locality}
                  </div>
                )}
              </Form.Group>

              <div className="button-container flex-row">
                <Button
                  className="back-button"
                  onClick={() => history.push("/register/connect-practice")}
                >
                  <BackIcon /> Back
                </Button>
                <Button className="continue-button" onClick={handleNextStep}>
                  Next
                </Button>
              </div>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default EstablishmentDetails;
