import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";
import {
  createOrderAPI,
  getVendorsForCityAPI,
  getVendorSlotsAPI,
} from "../../redux/slices/orders/orderService";
import { CentreStyled, SlotSelectionPopupstyled } from "./Centre.styled";
import {
  Button,
  Card,
  Row,
  Col,
  Input,
  Select,
  Form,
  Table,
  Radio,
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { toast } from "react-toastify";
import { FiDownloadCloud } from "react-icons/fi";
import { useHistory } from "react-router-dom";
// import * as XLSX from "xlsx";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TimeSlotsComponent from "./TimeSlotsComponent";
import group_501 from "../../assets/images/home/Group_501.png";
import group_502 from "../../assets/images/home/Group_502.png";
import group_503 from "../../assets/images/home/Group_503.png";
import group_504 from "../../assets/images/home/Group_504.png";
import { Modal } from "react-bootstrap";

const { Option } = Select;

const Centre = ({ id, clientId }: any) => {
  const dispatch = useDispatch();
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [cities, setCities] = useState(configInfo?.cities || []);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const history = useHistory();
  const [showOverviewComponent, setShowOverviewComponent] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState<any>({
    name: "",
    phone: "",
    email: "",
    employee_id: "",
    remarks: "",
    age: null,
    address: "",
    gender: "",
    relation: "",
  });
  const [employeeList, setEmployeeList] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, [dispatch]);

  useEffect(() => {
    setCities(configInfo?.cities || []);
  }, [configInfo]);

  useEffect(() => {
    const userSelectedCity = localStorage.getItem("userCity");
    if (userSelectedCity) {
      const cityObj = JSON.parse(userSelectedCity);
      setSelectedCity(cityObj?.id);
      handleCitySelect(cityObj.id);
    }
  }, []);

  const handleCitySelect = async (cityName: string) => {
    setSelectedCity(cityName);
    const res = await dispatch(
      getVendorsForCityAPI({ city: cityName.toLowerCase(), packageCode: id })
    );
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.error?.message) {
      toast.error(resJson?.error?.message || "Something went wrong");
      return;
    } else {
      const vendorsData = resJson.payload.data.vendorsForPackageAndCity || [];
      setVendors(vendorsData);
    }
  };

  const handleVendorSelect = (vendor: any) => {
    setSelectedVendor(vendor);
    if (employeeList.length > 0) {
      setShowOverviewComponent(true);
    }
    // setIsModalVisible(true);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEmployeeDetails((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (value: string, field: string) => {
    setEmployeeDetails((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddEmployee = () => {
    setEmployeeList([...employeeList, employeeDetails]);
    setEmployeeDetails({
      name: "",
      phone: "",
      email: "",
      employee_id: "",
      remarks: "",
      age: null,
      address: "",
      gender: "",
      relation: "",
    });
  };

  // Modify the handleBulkUpload function to use exceljs
  const handleBulkUpload = async (e: any) => {
    // const file = e?.target?.files[0];
    // if (file) {
    //   const reader = new FileReader();
    //   reader.readAsBinaryString(file);
    //   reader.onload = (e) => {
    //     const data = e.target?.result;
    //     if (data) {
    //       const workbook = XLSX.read(data, { type: "binary" });
    //       const sheetName = workbook.SheetNames[0];
    //       const sheet = workbook.Sheets[sheetName];
    //       const jsonData = XLSX.utils.sheet_to_json(sheet);
    //       setEmployeeList(jsonData as any[]);
    //       toast.success(`${jsonData.length} employees added successfully`);
    //     }
    //   };
    // }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Employee ID", dataIndex: "employee_id", key: "employee_id" },
    { title: "Age", dataIndex: "age", key: "age" },
    { title: "Gender", dataIndex: "gender", key: "gender" },
    { title: "Relation", dataIndex: "relation", key: "relation" },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "Remarks", dataIndex: "remarks", key: "remarks" },
  ];

  const [uploadType, setUploadType] = useState("single");
  const slotButtonsConfig = [
    { key: "slot1", placeholder: "Preffered Slot1" },
    { key: "slot2", placeholder: "Preffered Slot2 (Optional)" },
  ];

  // const handleShow = (item: any) => {};

  const OverviewComponent = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [loadingSlots, setLoadingSlots] = useState(false);
    const [slotsAvailable, setSlotsAvailable] = useState(true);

    const handleDateChange = async (e: any) => {
      console.log("handleDateChange");

      const date = e;
      console.log("selectedVendor : ", selectedVendor);

      setSelectedDate(date);
      if (selectedVendor) {
        const res = await dispatch(
          getVendorSlotsAPI({
            vendorId: selectedVendor.id,
            date: moment(date).format("YYYY-MM-DD"),
          })
        );
        const resJson = JSON.parse(JSON.stringify(res));
        console.log("resJson date : ", resJson);

        if (resJson?.error?.message) {
          toast.error(resJson?.error?.message || "Something went wrong");
          return;
        } else {
          const slots = resJson.payload.data.timeslots.map(
            (slot: any) => slot.slot
          );
          console.log("slots : ", slots);
          setTimeSlots(slots);
        }
      }
    };

    const handleTimeSlotSelect = (slot: any) => {
      console.log("selectedslot : ", slot);
      setSelectedSlot(slot);
    };

    const handleBookOrder = async () => {
      if (selectedDate && selectedSlot) {
        const orderDetails = {
          order: {
            client_id: clientId,
            package_code: id,
            vendor_id: selectedVendor.id,
            selected_date: moment(selectedDate).format("YYYY-MM-DD"),
            selected_timeslot: selectedSlot,
            users: employeeList.map((employee) => ({
              ...employee,
              phone: String(employee.phone), // Ensure phone is a string
              employee_id: String(employee.employee_id), // Ensure employee_id is a string
            })),
          },
        };

        console.log("orderDetails", orderDetails);
        const res = await dispatch(createOrderAPI(orderDetails));
        const resJson = JSON.parse(JSON.stringify(res));
        if (resJson?.error?.message) {
          toast.error(resJson?.error?.message || "Something went wrong");
          return;
        } else {
          toast.success("Order created successfully");
          setEmployeeDetails({
            name: "",
            phone: "",
            email: "",
            employee_id: "",
            remarks: "",
            age: null,
            address: "",
            gender: "",
            relation: "",
          });
          setEmployeeList([]);
          setShowOverviewComponent(false);
        }
        // Implement your booking logic here
      } else {
        alert("Please select both date and time slot before booking.");
      }
    };

    return (
      <SlotSelectionPopupstyled>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <h4>Select a Date:</h4>
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              minDate={new Date()}
            />
          </Col>
          <Col span={12}>
            <h4>Available Time Slots:</h4>
            {loadingSlots ? (
              <div>Fetching time slots...</div>
            ) : !slotsAvailable ? (
              <div>No time slots available for this day</div>
            ) : (
              <TimeSlotsComponent
                timeSlots={timeSlots}
                handleClick={handleTimeSlotSelect}
              />
            )}
          </Col>
        </Row>
        <Button
          onClick={handleBookOrder}
          disabled={!selectedDate || !selectedSlot}
          style={{ marginTop: "20px" }}
        >
          Book Order
        </Button>
      </SlotSelectionPopupstyled>
    );
  };

  const handleFinalCheckout = () => {};
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigateToPage = (url: string) => {
    if (
      employeeDetails.name === "" ||
      employeeDetails.phone === "" ||
      employeeDetails.email === ""
    ) {
      handleShow();
      return;
    }
    window.location.href = url; // Navigate if data is valid
  };

  return (
    <CentreStyled>
      {!showOverviewComponent}
      <div>
        {!selectedCity && <h3>Please select a City from Navbar</h3>}
        {!showOverviewComponent && (
          <div className="create-order-title-div mt-4">
            <h5>
              Diagnostic Centers in {selectedCity} ({vendors.length || 0})
            </h5>
          </div>
        )}
        {!showOverviewComponent && vendors.length > 0 && (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Form layout="vertical" style={{ maxWidth: "50%" }}>
              <Row
                style={{
                  marginBottom: "20px",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Radio.Group
                  value={uploadType}
                  onChange={(e) => setUploadType(e.target.value)}
                >
                  <Radio
                    value="single"
                    className="create-order-checkbox-div me-4"
                  >
                    Single Upload
                  </Radio>
                  <Radio value="bulk" className="create-order-checkbox-div">
                    Bulk Upload
                  </Radio>
                </Radio.Group>
              </Row>
              {uploadType === "single" && (
                <Col className="singleUpload">
                  <Row style={{ width: "100%" }}>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Name">
                        <Input
                          placeholder="Enter Employee Name"
                          name="name"
                          type="text"
                          value={employeeDetails.name}
                          onChange={handleInputChange}
                          className="shadow-none "
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Number">
                        <Input
                          placeholder="Enter Employee Phone Number"
                          name="phone"
                          type="number"
                          value={employeeDetails.phone}
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Email Id">
                        <Input
                          placeholder="Enter Employee Email Id"
                          name="email"
                          type="email"
                          value={employeeDetails.email}
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Employee Id">
                        <Input
                          placeholder="Enter Employee Id"
                          name="employee_id"
                          type="text"
                          value={employeeDetails.employee_id}
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Age">
                        <Input
                          placeholder="Enter Age"
                          name="age"
                          type="number"
                          value={employeeDetails.age}
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Gender">
                        <Select
                          placeholder="Gender"
                          value={employeeDetails.gender}
                          onChange={(value) =>
                            handleSelectChange(value, "gender")
                          }
                        >
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                          <Option value="others">Others</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Select Relationship">
                        <Select
                          placeholder="Select Relationship"
                          value={employeeDetails.relation}
                          onChange={(value) =>
                            handleSelectChange(value, "relation")
                          }
                        >
                          <Option value="Self">Self</Option>
                          <Option value="Spouse">Spouse</Option>
                          <Option value="Father">Father</Option>
                          <Option value="Mother">Mother</Option>
                          <Option value="Grand Mother">Grand Mother</Option>
                          <Option value="Grand Father">Grand Father</Option>
                          <Option value="Child 1">Child 1</Option>
                          <Option value="Child 2">Child 2</Option>
                          <Option value="Child 3">Child 3</Option>
                          <Option value="Sibling">Sibling</Option>
                          <Option value="Mother In Law">Mother In Law</Option>
                          <Option value="Father In Law">Father In Law</Option>
                          <Option value="Other">Other</Option>
                          <Option value="Sister">Sister</Option>
                          <Option value="Brother">Brother</Option>
                          <Option value="Daughter">Daughter</Option>
                          <Option value="Husband">Husband</Option>
                          <Option value="Son">Son</Option>
                          <Option value="Wife">Wife</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "50%" }}>
                      <Form.Item label="Address">
                        <Input
                          placeholder="Enter Address"
                          name="address"
                          type="text"
                          value={employeeDetails.address}
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: "100%" }}>
                      <Form.Item label="Remark">
                        <Input.TextArea
                          placeholder="Write Remark"
                          name="remarks"
                          typeof="text"
                          value={employeeDetails.remarks}
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {uploadType === "bulk" && (
                <Col className="bulkUpload">
                  <Form.Item label="Bulk Upload">
                    <input
                      type="file"
                      accept=".xlsx,.xls"
                      onChange={handleBulkUpload}
                    ></input>

                    <p style={{ marginTop: "10px", color: "#888" }}>
                      Upload an Excel file containing multiple employee details.
                      The file should include columns for Name, Phone, Email,
                      Employee ID, Age, Gender, Relation, Address, and Remarks.
                    </p>
                    <Button
                      icon={<FiDownloadCloud />}
                      onClick={() =>
                        window.open(
                          "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/order-xcel-template.xlsx",
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      Download Template
                    </Button>
                    <p style={{ marginTop: "10px", color: "#888" }}>
                      Fill up this template and upload it to add multiple
                      employees at once.
                    </p>
                  </Form.Item>
                </Col>
              )}
              <Col style={{ width: "100%" }}>
                <Button
                  type="default"
                  style={{ marginTop: "10px", float: "right" }}
                  onClick={handleAddEmployee}
                >
                  Add
                </Button>
              </Col>
              {employeeList.length > 0 && (
                <Table
                  dataSource={employeeList}
                  columns={columns}
                  rowKey="employee_id"
                  style={{ marginTop: "20px" }}
                  pagination={false}
                />
              )}
            </Form>
            {employeeList.length > 0 && selectedVendor && (
              <Button onClick={handleFinalCheckout}>Submit</Button>
            )}
            <div
              style={{
                maxHeight: "30rem",
                overflowY: "scroll",
                width: "30%",
                padding: "1rem",
              }}
            >
              <h3 className="text-center">Select Service</h3>
              {/* {vendors.map((vendor: any) => (
                <Col key={vendor.id} className="mb-2">
                  <Card
                    hoverable
                    className="vendor-card"
                    onClick={() => handleVendorSelect(vendor)}
                  >
                    <img
                      src={vendor.image || "https://placehold.co/400"}
                      alt={vendor.name}
                      style={{
                        width: 80,
                        height: 80,
                        objectFit: "contain",
                        marginRight: "10px",
                      }}
                    />
                    <div>
                      <h4>{vendor.name}</h4>
                      <p>Address or details can go here</p>
                    </div>
                  </Card>
                </Col>
              ))} */}
              <div
                className="our-services-box mb-3 me-4 d-flex flex-column align-items-center justify-content-center"
                onClick={() => {
                  navigateToPage("https://www.raphacure.com/doctor");
                }}
                style={{
                  cursor: "pointer",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <img src={group_501} className="mb-2 mt-2" alt="Doctor" />
                <p className="border-nine mb-1"></p>
                <h6>Doctor Consultation</h6>
              </div>
              <div
                className="our-services-box mb-3 me-4 d-flex flex-column align-items-center justify-content-center"
                onClick={() => {
                  navigateToPage("https://www.raphacure.com/labtest");
                }}
                style={{
                  cursor: "pointer",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <img src={group_502} className="mb-2 mt-2" alt="Doctor" />
                <p className="bordser-nine"></p>
                <h6>Lab Test</h6>
              </div>
              <div
                className="our-services-box mb-3 me-4 d-flex flex-column align-items-center justify-content-center"
                onClick={() => {
                  navigateToPage("https://www.raphacure.com/radiology");
                }}
                style={{
                  cursor: "pointer",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <img src={group_503} />
                <p className="bordser-nine"></p>
                <h6>Radiology</h6>
              </div>
              <div
                className="our-services-box mb-3 me-4 d-flex flex-column align-items-center justify-content-center"
                onClick={() => {
                  navigateToPage("https://www.raphacure.com/pharmacy");
                }}
                style={{
                  cursor: "pointer",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <img src={group_504} />
                <p className="bordser-nine"></p>
                <h6>Pharmacy</h6>
              </div>
            </div>
          </div>
        )}
        {showOverviewComponent && <OverviewComponent />}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <CentreStyled>
          <Modal.Body className="d-flex flex-column align-items-center justify-content-center text-center">
            <div>
              <h5>Add The User Data</h5>
            </div>
            <button
              className="btn btn-primary wraning-btn-div mt-3"
              onClick={handleClose}
            >
              OK
            </button>
          </Modal.Body>
        </CentreStyled>
      </Modal>
    </CentreStyled>
  );
};

export default Centre;
