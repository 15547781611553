import React, { useEffect, useState } from "react";
import { Button, Select } from "antd"; // Import Select from Ant Design
import Rightimg from "../../assets/images/Login/mobile.png";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import { useHistory } from "react-router-dom";
import { LoginStyled } from "./Login.Styled";
import { useDispatch, useSelector } from "react-redux";
import {
  doctorBankDetails,
  getDoctorProfileDetails,
} from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
const BankAccount: React.FC = () => {
  const history = useHistory();
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [reenterAccountNumber, setReenterAccountNumber] = useState("");

  const [errors, setErrors] = useState<{
    accountHolderName?: string;
    bankName?: string;
    accountNumber?: string;
    reenterAccountNumber?: string;
    ifscCode?: string;
  }>({});

  const [message, setMessage] = useState(null); // Stores message object
  const [messageType, setMessageType] = useState(""); // 'error' or 'success'

  const displayMessage = (msg: any, type: any) => {
    setMessage(msg);
    setMessageType(type);
    setTimeout(() => {
      setMessage(null);
      setMessageType("");
    }, 5000); // Clear message after 3 seconds
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!accountHolderName)
      newErrors.accountHolderName = "Account Holder Name is required.";
    if (!bankName) newErrors.bankName = "Bank Name is required.";
    if (!accountNumber) newErrors.accountNumber = "Account Number is required.";
    if (accountNumber !== reenterAccountNumber)
      newErrors.reenterAccountNumber = "Account numbers do not match.";
    if (!ifscCode) newErrors.ifscCode = "IFSC Code is required.";
    return newErrors;
  };

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, [dispatch, user]);

  const prefillData = (data: any) => {
    const bankDetails = data?.payload?.data?.doctor?.bank_details;
    console.log("prefillData : ", bankDetails);
    setAccountNumber(bankDetails?.account_number);
    setReenterAccountNumber(bankDetails?.account_number);
    setAccountHolderName(bankDetails?.name);
    setBankName(bankDetails?.bank_name);
    setIfscCode(bankDetails?.ifsc_code);
  };

  const handleNextStep = async () => {
    if (!reenterAccountNumber) {
      displayMessage("Please re enter Account Number", "error");
      return;
    }
    if (accountNumber !== reenterAccountNumber) {
      displayMessage("Account Numbers do not match", "error");
      return;
    }
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      const bankObj = {
        name: accountHolderName,
        bank: bankName,
        accountnumber: accountNumber,
        ifsccode: ifscCode,
      };

      const res = await dispatch(doctorBankDetails(bankObj));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        toast.success("Bank Account updated successfully");
        history.push("/register/verification-profile");
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handlePrevStep = () => {
    history.push("/register/consultant-doctor"); // Navigate back to ConsultantDoctor
  };

  // Define a list of banks for the dropdown
  const banks = [
    "Bank A",
    "Bank B",
    "Bank C",
    // Add more banks as needed
  ];

  return (
    <LoginStyled>
      <div className="profile-page">
        <div className="page-left">
          <div className="bank-account">
            <h2>Add Bank Account</h2>
            <Form>
              <Form.Group controlId="accountHolderName">
                <Form.Label>Account Holder Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Full Name"
                  value={accountHolderName}
                  onChange={(e) => setAccountHolderName(e.target.value)}
                  isInvalid={!!errors.accountHolderName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.accountHolderName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="bankName">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  isInvalid={!!errors.bankName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bankName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="accountNumber">
                <Form.Label>Account Number</Form.Label>
                <input
                  type="number"
                  placeholder="Enter Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  style={{
                    width: "100%",
                    border: "0.5px solid #b8b8b8",
                    padding: "10px",
                  }}
                  min={0}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.accountNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="reenterAccountNumber">
                <Form.Label>Re-enter Account Number</Form.Label>
                <input
                  type="number"
                  placeholder="Re-enter Account Number"
                  value={reenterAccountNumber}
                  onChange={(e) => setReenterAccountNumber(e.target.value)}
                  style={{
                    width: "100%",
                    border: "0.5px solid #b8b8b8",
                    padding: "10px",
                  }}
                  min={0}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.reenterAccountNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="ifscCode">
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter IFSC Code"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                  isInvalid={!!errors.ifscCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.ifscCode}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="button-container flex-row">
                <Button className="back-button h-auto" onClick={handlePrevStep}>
                  <BackIcon /> Back
                </Button>
                <Button
                  className="continue-button h-auto"
                  onClick={handleNextStep}
                >
                  Next
                </Button>
              </div>
              {message && (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "10px",
                    borderRadius: "5px",
                    color: "#fff",
                    backgroundColor: messageType === "error" ? "red" : "green",
                  }}
                >
                  {message}
                </div>
              )}
            </Form>
          </div>
        </div>

        <div className="pageright">
          <img src={Rightimg} alt="Profile Illustration" />
        </div>
      </div>
    </LoginStyled>
  );
};

export default BankAccount;
