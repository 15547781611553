import styled from "styled-components";

export const MarketingStyled = styled.div`
  margin-right: 20px;
  .tab-buttons {
    display: flex;
    justify-content: center;
    /* margin-bottom: 20px; */

    button {
      border-radius: 5px;
      border: 1px solid #d8bebe;
      background: var(--white, #fff);
      padding: 10px 20px;
      margin: 0 5px;
      cursor: pointer;

      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      color: #5e5050;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      img {
        margin-right: 8px;
        width: auto;
      }

      &.active {
        color: var(--Primary, #9747ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 5px;
        border: 1px solid var(--Primary, #9747ff);
        background: var(--white, #fff);
      }
    }
  }

  .row {
    margin: 0 !important;
  }
  .search-bar {
    display: flex;
    align-items: center;
    padding: 5px;
    width: 300px;
    border-radius: 5px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
  }

  .search-bar svg {
    margin-right: 5px;
  }

  .search-bar input {
    border: none;
    outline: none;
    padding: 5px;
    background: transparent;
    color: #ccc;
    font-size: 14px;
    font-weight: 400;
    width: 250px;
  }

  /* History section */
  .history-sec {
    overflow-x: auto;
  }
  .ant-table-wrapper {
    border-radius: 8px;
    border: 1px solid #e2cece;
  }

  .ant-table {
    border-radius: 10px;
    padding: 6px;
  }
  .ant-table-thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
    height: 45px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
  }
  .ant-table-cell {
    white-space: nowrap;
    height: 45px;
  }
  .ant-table-tbody td {
    color: #766666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    border-bottom: 1px solid #dcd2d2;
  }

  .ant-btn-primary {
    background-color: #f0f0ff;
    color: #9254de;
    border: none;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #9254de;
    color: white;
  }
  .pagination {
    justify-content: center;
    margin-top: 20px;
  }
  .custom-pagination .page-item.active .page-link {
    background-color: #7c4dff;
    border-color: #7c4dff;
    color: white;
  }

  .custom-pagination .page-link {
    color: black;
  }

  .custom-pagination .page-link:hover {
    background-color: #ccbbfa;
  }

  .communication .row {
    display: flex;
  }

  .communication .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .communication .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .communication .profile-pic {
    max-width: 100%;
  }

  .communication .tab-content {
    margin-top: 20px;
  }
  .tab-content{
    margin-top: 20px;
  }

  .communication .card {
    --bs-card-border-width:0 !important;
    background: transparent;
  }
  .communication .card .card-body{
    background: transparent !important;
    border: none  !important;
    padding: 0;

  }

  .communication .message {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }

  .communication .message p {
    margin: 0 0 10px;
  }

  .communication .message p:last-child {
    margin-bottom: 0;
  }

  .communication input[type="checkbox"] {
    margin-top: 15px;
    transform: scale(1.2);
  }

  .communication .nav-tabs {
    border-radius: 5px;
    background: #EAF5FF;
    padding:16px 0;
  }
  .communication .nav-item {
    padding-right: 20px;
  }

  .communication .nav-item.show .nav-link,
  .communication .nav-link.active {
    color: var(--Primary, #9747ff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;

    border: none;
    background-color: transparent;
  }

  .communication .nav-link {
    color: #7d7171;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    padding: 2px;
  }

  .create-aud {
    margin-top: 44px;
    border-radius: 5px;
    border: 1px solid var(--Primary, #9747ff);
    background: var(--Primary, #9747ff);
    color: #f6f9fd;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 30px;
  }
  .communication-select {
  padding: 8px 16px;
  margin-right: 10px; /* Space between select and tab buttons */
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
}
`;
