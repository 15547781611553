import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ChatInput from "./ChatInput";
import axios from "axios";
import { CHAT_API } from "../../config";

export default function ChatContainer({
  currentUserChatId,
  currentChat,
  socket,
}) {
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const sendMessageRoute = `${CHAT_API}/api/messages/addmsg`;
  const recieveMessageRoute = `${CHAT_API}/api/messages/getmsg`;
  console.log("currentChat : ", currentChat);
  console.log("socket : ", socket);

  useEffect(() => {
    const fetchMessages = async () => {
      if (currentChat?._id && currentUserChatId) {
        const response = await axios.post(recieveMessageRoute, {
          from: currentUserChatId,
          to: currentChat._id,
        });
        setMessages(response.data);
      }
    };
    fetchMessages();
  }, [currentChat]);

  const handleSendMsg = async (msg) => {
    console.log("calling handleSendMsg");

    socket.current.emit("send-msg", {
      to: currentChat._id,
      from: currentUserChatId,
      msg,
    });
    await axios.post(sendMessageRoute, {
      from: currentUserChatId,
      to: currentChat._id,
      message: msg,
    });

    const msgs = [...messages];
    msgs.push({ fromSelf: true, message: msg });
    setMessages(msgs);
  };

  useEffect(() => {
    if (socket.current) {
      console.log("calling msg recieve");

      socket.current.on("msg-recieve", (msg) => {
        setArrivalMessage({ fromSelf: false, message: msg });
      });
    }
  }, []);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Container>
      <div className="chat-header">
        <div className="user-details">
          <div className="avatar">
            {/* <span className="user-name-sec-acc">
              {currentChat.username?.substr(0, 1)}
            </span> */}
          </div>
          <div className="username">
            <h3>{currentChat.username}</h3>
          </div>
        </div>
      </div>
      <div className="chat-messages">
        {messages.map((message, idx) => {
          return (
            <div ref={scrollRef} key={idx}>
              <div
                className={`message ${
                  message.fromSelf ? "sended" : "recieved"
                }`}
              >
                <div className="content ">
                  <p>{message.message}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ChatInput handleSendMsg={handleSendMsg} />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  min-height: 500px;
  grid-template-rows: 10% 80% 10%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 15% 70% 15%;
  }
  .user-name-sec-acc {
    background: #fff;
    color: #9a86f3;
    font-size: 50px;
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 50px;
    border: 1px solid #9a86f3;
    padding-left: 12px;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 20px;
    padding-top: 20px;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: #000;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      display: flex;
      align-items: center;
      max-width: 100%;
      border: none;
      background: none;
      .content {
        max-width: 40%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: #000;
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: #4f04ff21;
      }
    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: #9900ff20;
      }
    }
  }
`;
