// MedicalRegistration.js
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { useDispatch, useSelector } from "react-redux";
import {
  doctorMedRegistration,
  getDoctorInfoAPI,
  getDoctorProfileDetails,
} from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { Select } from "antd"; // Ant Design Select
import { councils } from "../../utils/constants";
const { Option } = Select;

const MedicalRegistration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();
  const [existingUser, setExistingUser] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState({
    registrationNumber: "",
    registrationCouncil: "",
    registrationYear: "",
  });

  const [errorData, setErrorData] = useState({
    registrationNumber: "",
    registrationCouncil: "",
    registrationYear: "",
  });

  const [secondDocLinkableId, setSecondDocLinkableId] = useState<any>(null);
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const childDocId = searchParams.get("doctorId");
    setSecondDocLinkableId(childDocId);
  }, [location]);

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, []);

  const prefillData = (data: any) => {
    console.log("prefillData : ", data?.payload);
    const searchParams = new URLSearchParams(location.search);
    const childDocId = searchParams.get("doctorId");
    if (childDocId) {
      return;
    }
    setRegistrationDetails({
      registrationNumber:
        data?.payload?.data?.doctor?.registration_number || "",
      registrationCouncil:
        data?.payload?.data?.doctor?.medical_registration_body || "",
      registrationYear: `${
        data?.payload?.data?.doctor?.registration_year || ""
      }`,
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setRegistrationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (value.trim() !== "") {
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {} as any;

    if (!registrationDetails?.registrationNumber?.trim()) {
      errors.registrationNumber = "Please enter your Registration Number";
    }
    if (!registrationDetails?.registrationCouncil?.trim()) {
      errors.registrationCouncil = "Please select Registration Council";
    }
    if (!registrationDetails?.registrationYear?.trim()) {
      errors.registrationYear = "Please select Registration Year";
    }

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        id: doctorId,

        medObj: {
          doctor: {
            registration_year: registrationDetails.registrationYear,
            medical_registration_body: registrationDetails.registrationCouncil,
            registration_number: registrationDetails.registrationNumber,
          },
        },
      };
      const res = await dispatch(doctorMedRegistration(payload));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("resJson : ", resJson);
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        toast.success("Medical registration updated successfully");
        if (secondDocLinkableId) {
          history.push(
            `/register/education-qualification?doctorId=${secondDocLinkableId}`
          );
        } else history.push("/register/education-qualification");
      }
    }
  };

  // useEffect(() => {
  //   const getDocInfo = async () => {
  //     if (!user.phone) {
  //       toast.error("Please save Your Phone Number from dashboard");
  //       return;
  //     }
  //     const res = await dispatch(getDoctorInfoAPI(user.phone));
  //     const resJson = JSON.parse(JSON.stringify(res));
  //     console.log("resJson : ", resJson);
  //     if (!resJson?.payload?.data?.doctorDetails?.id) {
  //       toast.error("Linkable Id not found");
  //     }
  //     setDocLinkableId(resJson?.payload?.data?.doctorDetails?.id);
  //   };
  //   getDocInfo();
  // }, []);

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Medical Registration</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="registrationNumber">
                <Form.Label className="form-label">
                  Registration Number
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Type registration number"
                  name="registrationNumber"
                  value={registrationDetails.registrationNumber}
                  onChange={handleInputChange}
                  isInvalid={!!errorData.registrationNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData.registrationNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="registrationCouncil">
                <Form.Label className="form-label">
                  Registration Council
                </Form.Label>
                <Select
                  showSearch
                  placeholder="Type & select registration council"
                  value={registrationDetails.registrationCouncil}
                  onChange={(value) =>
                    handleInputChange({
                      target: { name: "registrationCouncil", value },
                    })
                  }
                  className={
                    !!errorData.registrationCouncil ? "is-invalid" : ""
                  }
                >
                  {councils.map((council, index) => (
                    <Option key={index} value={council}>
                      {council}
                    </Option>
                  ))}
                </Select>

                {errorData.registrationCouncil && (
                  <div className="invalid-feedback d-block">
                    {errorData.registrationCouncil}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="registrationYear">
                <Form.Label className="form-label">
                  Registration Year
                </Form.Label>
                <Select
                  showSearch
                  placeholder="Select registration year"
                  value={registrationDetails.registrationYear}
                  onChange={(value) =>
                    handleInputChange({
                      target: { name: "registrationYear", value },
                    })
                  }
                  className={!!errorData.registrationYear ? "is-invalid" : ""}
                >
                  {Array.from(
                    { length: new Date().getFullYear() - 1950 + 1 },
                    (_, index) => {
                      const year = new Date().getFullYear() - index; // Reverse the order
                      return (
                        <Option key={year} value={year.toString()}>
                          {year}
                        </Option>
                      );
                    }
                  )}
                </Select>
                {errorData.registrationYear && (
                  <div className="invalid-feedback d-block">
                    {errorData.registrationYear}
                  </div>
                )}
              </Form.Group>

              <div className="button-container flex-row">
                <Button
                  className="back-button"
                  onClick={() => history.push("/register")}
                >
                  <BackIcon /> Back
                </Button>
                <Button className="continue-button" type="submit">
                  Next
                </Button>
              </div>
            </Form>
            <div className="info-box">
              This information enables us to conduct essential checks, ensuring
              only licensed and genuine medical practitioners are listed on
              RaphaCure. Upon verification, your profile will receive a
              “Verified” badge, which leads to 95% more patient views.
            </div>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default MedicalRegistration;
