import React, { useState } from "react";
import "./AttendanceModal.css";
import { Table } from "react-bootstrap";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import { ReactComponent as Calendaricon } from "../../../../assets/icons/calendar.svg";
import Modal from "react-bootstrap/Modal";
import Calendar from "react-calendar";
import { ReactComponent as Present } from "../../../../assets/icons/present.svg";

const History = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearchChange = (event:any) => {
    setSearchTerm(event.target.value);
  };

  const history = [
    {
      date: "20-08-2024",
      name: "Syam Varghese",
      role: "Senior Nurse",
      status: "Present",
      checkin: "9:30",
      checkout: "6:30",
      overtime: "0:30",
    },
    {
      date: "20-08-2024",
      name: "Syam Varghese",
      role: "Senior Nurse",
      status: "Present",
      checkin: "9:30",
      checkout: "6:30",
      overtime: "0:30",
    },
    {
      date: "20-08-2024",
      name: "Syam Varghese",
      role: "Senior Nurse",
      status: "Present",
      checkin: "9:30",
      checkout: "6:30",
      overtime: "0:30",
    },
    {
      date: "20-08-2024",
      name: "Syam Varghese",
      role: "Senior Nurse",
      status: "Present",
      checkin: "9:30",
      checkout: "6:30",
      overtime: "0:30",
    },
    {
      date: "20-08-2024",
      name: "Syam Varghese",
      role: "Senior Nurse",
      status: "Present",
      checkin: "9:30",
      checkout: "6:30",
      overtime: "0:30",
    },
    {
      date: "20-08-2024",
      name: "Syam Varghese",
      role: "Senior Nurse",
      status: "Present",
      checkin: "9:30",
      checkout: "6:30",
      overtime: "0:30",
    },
    {
      date: "20-08-2024",
      name: "Syam Varghese",
      role: "Senior Nurse",
      status: "Present",
      checkin: "9:30",
      checkout: "6:30",
      overtime: "0:30",
    },
    // ... other entries
  ];

  const filteredHistory = history.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="history-list">
      <div className="searchbarfilter">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search Name or Role"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="calendar">
          <Calendaricon onClick={toggleModal} />
          <Modal
            show={showModal}
            onHide={toggleModal}
            centered
            className="custom-modal"
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <Calendar onChange={(date) => console.log(date)} />
            </Modal.Body>
          </Modal>
        </div>
        <div className="filterplace">
          <button>
            Filter <Filter />
          </button>
        </div>
      </div>
      <div className="recent-history-list">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Role</th>
              <th>Status</th>
              <th>Check In</th>
              <th>Check Out</th>
              <th>Over Time</th>
            </tr>
          </thead>
          <tbody>
            {filteredHistory.map((item, index) => (
              <tr key={index}>
                <td>{item.date}</td>
                <td>{item.name}</td>
                <td>{item.role}</td>
                <td>
                  <Present /> {item.status}
                </td>
                <td>{item.checkin}</td>
                <td>{item.checkout}</td>
                <td>{item.overtime}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default History;
