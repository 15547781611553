import styled from "styled-components";

export const LoginStyled = styled.div`
  .profile-page {
    display: flex;
    margin: 50px 30px;
  }

  .page-left {
    width: 552px;
    form > div {
      display: flex;
      flex-direction: column;
    }
  }

  .pageright {
    flex: 1;
    text-align: center;
  }

  h2 {
    color: #313131;
    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.93px;
    margin-bottom: 20px;
  }

  .section-title {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
  }
  .form-checkone {
    border-bottom: 1px solid #e4e4e4;
  }

  label {
    margin-bottom: 5px;
    margin-top: 20px;
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  input[type="text"],
  select {
    padding: 12px;
    border-radius: 5px;
    border: 0.5px solid #b8b8b8;
    background: #fff;
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
  }

  input[type="text"]::placeholder {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
  }

  select::placeholder {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
  }

  .gender-options {
    display: flex;
    align-items: center;
    /* margin-bottom: 20px; */
  }

  .gender-options label {
    margin-right: 20px;
  }

  input[type="radio"] {
    margin-right: 10px;
  }

  button {
    background-color: #8e5be8;
    color: #fff;
    padding: 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .pageright img {
    width: 400px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }

  .back-button {
    background: transparent;
    color: #808080;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .continue-button {
    border-radius: 2px;
    background: #9474ff;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.48px;
    width: 182px;
  }

  .info-box {
    background-color: #dff9fb;
    border-left: 5px solid #22a6b3;
    padding: 15px;
    margin-top: 20px;
    border-radius: 4px;
    font-size: 14px;
    color: #2d3436;
  }
  .pactice-options {
    display: grid;
    background: #fff;
    width: 50%;
    margin-top: 12px;
    border-radius: 2px 2px 0px 0px;
    border: 1px solid #e4e4e4;
  }
  .pactice-options input {
    margin-left: -12px;
  }
  .changebtn {
    background: transparent;
    color: #9474ff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.42px;
    padding: 0;
  }
  .secclass {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  .progress-summary {
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  .identity-proof-options {
    display: grid;
  }
  .upload-photo {
    display: flex;
    gap: 6px;
    margin-top: 12px;
    paddding: 12px;
    text-align: center;
  }
  .upload-photo h6 {
    color: #808080;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .widthset {
    width: 50%;
  }
  .widthset p {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
    margin-top: 6px;
  }
  .support {
    text-align: left;
  }
  .maxi {
    text-align: right;
  }

  .frontphoto,
  .backphoto {
    border-radius: 2px;
    border: 0.5px solid #808080;
    background: #fff;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .updbtn {
    margin: 40px;
    border: 0.5px solid #808080;
    background: #fff;
    color: #313131;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .paragraph-text {
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: left;
  }
  .photo {
    text-align: center;
  }
  .photodet {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    color: #000;
    opacity: 0.6;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .establishment-options {
    display: grid;
  }
  .textdata {
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.42px;
    text-align: left;
  }
  .photoupd {
    margin-top: 16px;
  }
  .map-location-container {
    padding: 20px;
    // max-width: 600px;
  }

  .input-wrapper {
    display: flex;
    position: relative;
    gap: 6px;
  }

  .input-wrapper .form-control {
    flex-grow: 1;
    padding-right: 100px; /* Adjust to ensure space for verified and edit button */
  }

  .input-wrapper .verified {
    position: absolute;
    right: 100px;
    color: green;
    top: 9px;
  }

  .map-container {
    margin-top: 20px;
  }

  .edit-button {
    border-radius: 2px;
    border: 0.5px solid #b8b8b8;
    background: #fff;
    padding: 12px 30px !important;
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    height: 42px;
  }

  .consultant-doctor h2,
  .consultant-doctor h4 {
    margin-bottom: 10px;
  }
  .consultation-form {
    padding: 26px 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: var(--white, #fff);
    box-shadow: 2px 2px 19px 0px rgba(0, 0, 0, 0.1);
  }

  .consultant-doctor .initial-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .consultant-doctor .form-check {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 13%;
  }

  .form-check label {
    margin: 0 !important;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .consultant-doctor .fee-input {
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #c8c8c8;
    background: var(--white, #fff);
    overflow: hidden;
    width: 30%;
  }

  .consultant-doctor .fee-input input {
    margin-bottom: 0;
    border: none;
    outline: none;
  }

  .consultant-doctor .currency {
    padding: 10px;
    border-right: 1px solid #c8c8c8;
    background: #f9f9f9;
  }

  .consultant-doctor .consultation-fee {
    border: none;
    outline: none;
    padding: 10px;
    flex: 1;
  }

  .consultant-doctor .time-inputs-container {
    display: flex;
    flex-direction: column;
  }

  .consultant-doctor .time-inputs-row {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 10px;
    padding-right: 84px;
    justify-content: end;
  }

  .consultant-doctor .weekdays {
    display: flex;
    gap: 5px;
    /* width: 25%; */
  }

  .consultant-doctor .weekdays .day {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #939393;
  }

  .consultant-doctor .time-inputs {
    display: flex;
    gap: 10px;
    /* width: 25%; */
  }

  .consultant-doctor .time-input {
    border-radius: 2px;
    border: 1px solid #c8c8c8;
    background: #fff;
    margin: 0;
    padding: 12px 10px;
  }

  .consultant-doctor .remove-time {
    cursor: pointer;
  }

  .consultant-doctor .add-time {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    .profile-page {
      display: block;
    }
    .page-left {
      width: 100%;
    }
    .pageright {
      display: none;
    }
    .consultation-form {
      padding: 16px;
    }

    .consultant-doctor .initial-row {
      display: block;
    }

    .consultant-doctor .form-check {
      width: 100%;
      margin-bottom: 10px;
    }

    .consultant-doctor .fee-input {
      width: 100%;
      display: block;
    }

    .consultant-doctor .weekdays {
      width: 100%;
      display: block;
      text-align: center;
      margin-top: 12px;
    }

    .consultant-doctor .weekdays .day {
      display: inline-block;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #939393;
    }

    .consultant-doctor .time-inputs-container {
      display: block;
    }

    .consultant-doctor .time-inputs-row {
      display: block;
      margin-bottom: 10px;
      padding-right: 0;
    }

    .consultant-doctor .time-inputs {
      width: 100%;
      display: block;
      margin-bottom: 10px;
    }

    .consultant-doctor .time-input {
      width: calc(100% - 20px); /* Adjust for padding/margins */
      margin: 10px 0;
    }

    .consultant-doctor .add-time {
      display: block;
      margin-top: 5px;
    }
    .pactice-options {
      width: 100%;
    }
  }
  .map-location-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .content {
    width: 60%;
  }

  .pageright {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pageright img {
    max-width: 100%;
    height: auto;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
  }

  .verified {
    margin-left: 10px;
    color: green;
  }

  .edit-button {
    margin-left: auto;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .back-button {
    display: flex;
    align-items: center;
  }

  .continue-button {
    margin-left: auto;
  }
  .react-select {
    margin-bottom: 12px !important;
  }
  .error-message,
  .error {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
    margin-top: 1.5rem;
  }
  .identity-proof-form {
    .ant-row.ant-form-item.ant-form-item-with-help {
      display: flex;
      flex-direction: column;
    }
    label {
      float: left;
    }
  }
  /* Toggle Switch Styles */
  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 25px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #0eb6ca;
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  .initial-row {
    display: flex;
    /* justify-content: space-evenly; */
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
  .weekdays {
    display: flex;
    gap: 0.5rem;
  }
  .day {
    padding: 0.7rem;
    border-radius: 50%;
    border: 1px solid rgb(14, 182, 202);
  }
  .bn-buttons-wrapper {
    background: white;
    min-height: 150px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .time-inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .ant-col.ant-form-item-control {
    width: 100%;
  }
  label.form-check-label {
    cursor: pointer;
  }
`;
