import styled from "styled-components";

export const DocdashboardStyled = styled.div`
  width: 100%;
  padding-top: 12px;

  .maindash {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    gap: 12px;
  }

  .leftsec {
    min-width: 65%;
  }
  .rightsec {
    max-height: 100vh;
    overflow-y: scroll;
  }
  /* .leftsec {
    width: 65%;
  }

  .rightsec {
    width: 35%;
  } */

  //   overview css
  .overview {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 24px;
  }

  .overview-left {
    width: 40%;
  }

  .patient-overview {
    border-radius: 10px;
    border: 1px solid #f1e8fc;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    padding: 10px;
    h4 {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      opacity: 0.8;
      margin: 0;
    }
    .overview-icon {
      text-align: center;
      margin: 0;
    }
    .num {
      color: #000;
      text-align: center;
      margin: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.6px;
    }
    .totpat {
      color: #000;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.42px;
    }
    .pat-cat {
      display: flex;
    }

    .leftcat,
    .rightcat {
      flex: 1;
      p {
        color: #000;
        font-size: 14px;
        font-style: normal;
        ont-weight: 500;
        line-height: 150.472%; /* 18.057px */
        letter-spacing: 0.36px;
        span {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 150.472%; /* 18.057px */
          letter-spacing: 0.36px;
        }
      }
    }
  }
  .leftcat {
    text-align: center;
  }

  .overview-right {
    width: 60%;
    display: flex;
  }

  .Totalconsult,
  .upcomingconsut {
    width: 50%;
    gap: 6px;
  }
  .consult {
    height: 50%;
    border-radius: 10px;
    border: 1px solid #f1e8fc;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 4px;
    margin: 0 6px 6px 6px;
    cursor: pointer;
    position: relative; /* Add this line */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .consult .icon-svg {
    margin-bottom: 8px;
  }

  .totnumber {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.6px;
    margin: 0;
  }

  .consult .percentage {
    position: absolute; /* Add this line */
    top: 4px; /* Adjust as needed */
    right: 4px; /* Adjust as needed */
    color: #168803;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .consult .iconred {
    color: red !important;
  }

  // Appoint Order
  .appoint-order {
  }

  .search-bar {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(204, 204, 204, 0.8);
    overflow: hidden;
  }

  .search-bar .search-icon {
    margin: 0 10px;
    font-size: 16px;
    color: #ccc;
    cursor: pointer;
  }

  .search-bar input[type="text"] {
    flex: 1;
    padding: 10px;
    border: none;
    font-size: 14px;
    outline: none;
    background: transparent;
  }

  .search-bar input[type="text"]::placeholder {
    color: #ccc;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
  .viewbtn {
    text-align: end;
  }
  .viewbtn button {
    border-radius: 5px;
    border: 1px solid #f8e4e4;
    background: #fff;
    color: #896e6e;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 6px 24px;
  }
  .ordertable {
    margin-top: 12px;
  }

  .ordertable Table {
    --bs-table-bg: transparent !important;
    border-radius: 5px;
    border: 1px solid #d6cece;
  }
  .ordertable thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
  }
  .ordertable th {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ordertable td {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ordertable tr {
    border: 1px solid #d6cece;
  }
  .ordertable .action-report {
    text-align: center;
  }
  .orderTable .actions {
    display: flex;
    align-items: center;
  }
  .ordertable .actions {
    text-align: center;
    text-wrap: nowrap;
  }
  .ordertable .actions svg {
    cursor: pointer !important;
  }
  .ordertable .icon-space {
    margin: 0 8px;
  }
  .ordertable .name {
    cursor: pointer !important;
  }

  // Appontment-leftsection
  .appoint-left {
    border-radius: 10px;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    margin-right: 12px;
    padding: 8px;
    max-height: 500px;
    overflow: scroll;
  }
  .filtersec {
    border-radius: 8px 8px 0px 0px;
    background: #d3edfc;
    padding: 12px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  .filtersec label {
    margin-right: 50px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  select {
    // max-width: 200px;
    flex: 1;
    border: none;
    padding: 2px;
  }

  .appointdet {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    margin-bottom: 6px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .appointdet img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;
  }
  .appoint-info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .name {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.7;
  }
  .name2 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .time {
    color: #000;
    font-size: 14px;
    font-style: normal;

    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .datesection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .dateinside {
    flex: 1;
    border-radius: 10px;
    background: #fff;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0 6px;
    padding: 4px 0;
    cursor: pointer;
  }
  .active-date {
    border-radius: 10px;
    background: #9747ff;
    scale: 1.2;
    box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
  }
  .active-date p {
    color: #fff !important;
  }
  .dateinside p {
    margin: 0;
  }
  .dateinside .week {
    color: #072549;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    opacity: 0.5;
  }
  .dateinside .date {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    opacity: 0.9;
  }
  .lefticon,
  .righticon {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 0 6px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .view-allbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 12px;
  }
  .view-allbtn button {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    width: 100%;
    padding: 10px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media (max-width: 768px) {
    .maindash {
      flex-direction: column;
      gap: 12px;
    }

    .dashboardnav {
      width: 100%;
      height: auto;
      margin-bottom: 12px;
    }

    .leftsec,
    .rightsec {
      width: 100%;
    }

    .overview {
      flex-direction: column;
      gap: 12px;
    }

    .overview-left,
    .overview-right {
      width: 100%;
    }

    .Totalconsult,
    .upcomingconsut {
      width: 100%;
    }
    .Totalconsult {
      height: 100%;
    }
    .ordertable .icon-space {
      margin: 0 0;
    }
  }
  .selected-date-text {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
    color: #000;
    opacity: 0.7;
    margin: 1rem auto;
  }

  @media screen and (max-width: 1500px) {
    .filtersec {
      flex-direction: column;
      align-items: center;
      label {
        margin-right: 0px;
      }
    }
    .rightsec {
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    .maindash {
      flex-direction: column-reverse;
      align-items: center;
      .appoint-left.chk {
        padding: 1.5rem;
        & > div {
          margin: auto;
          max-width: 80%;
        }
        .datesection {
          margin: 1.5rem auto;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .overview {
      flex-direction: column;
      align-items: center;
    }
  }
`;
