import React, { useState } from "react";
import { ReportStyled } from "./Reports.Styled";
import StackedBarChart from "./Components/StackedBarChart/StackedBar";
import OverviewBarChart from "./Components/OverviewBarChart/OverviewBar";
import AttedanceOverview from "./Components/Attendance/AttendanceOverview";
import AppointmentOverview from "./Components/Attendance/AppointmentOverview";
import "react-calendar/dist/Calendar.css";

const Reports = () => {
  const [activeChart, setActiveChart] = useState("stacked");

  const handleChartClick = (chart:any) => {
    setActiveChart(chart);
  };

  return (
    <ReportStyled>
      <div className="report-sec">
        <div
          className="reportappointment"
          onClick={() => handleChartClick("stacked")}
        >
          <StackedBarChart />
        </div>
        <div
          className="reportattendance"
          onClick={() => handleChartClick("overview")}
        >
          <OverviewBarChart />
        </div>
      </div>

      <div className="chart-content">
        {activeChart === "stacked" && <AppointmentOverview />}
        {activeChart === "overview" && <AttedanceOverview />}
      </div>
    </ReportStyled>
  );
};

export default Reports;
