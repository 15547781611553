import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { toast } from "react-toastify";
import {
  addClinicAPI,
  addDocToClinicAPI,
  doctorEstablishmentDetails,
  getAllClinicsAPI,
  getAllDoctorClinicsAPI,
  getDoctorProfileDetails,
} from "../../redux/slices/doctor/doctorService";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Input,
  Select as AntdSelect,
  TimePicker,
  Button as AntdButton,
} from "antd";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";

const { Option } = AntdSelect;

const ConnectPractice = () => {
  const history = useHistory();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();
  const dispatch = useDispatch();
  const [showClinicModal, setShowClinicModal] = useState(false);
  const [clinicName, setClinicName] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [clinicState, setClinicState] = useState("");
  const [clinicCity, setClinicCity] = useState("");
  const [clinicPin, setClinicPin] = useState("");
  const [startTime, setStartTime] = useState<any>("");
  const [endTime, setEndTime] = useState<any>("");
  const [errors, setErrors] = useState<any>({});
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [cities, setCities] = useState<any>(configInfo?.cities || []);
  // State to hold practice details
  const [practiceDetails, setPracticeDetails] = useState({
    establishment: "",
  });

  const [errorData, setErrorData] = useState({
    establishment: "",
  });

  const generateTimeOptions = () => {
    const options = [];
    const startHour = 7; // 7 AM
    const endHour = 21; // 9 PM

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 30) {
        const time = new Date(2024, 0, 1, hour, minutes);
        const timeString = time.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
        options.push({
          value: time.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
          label: timeString,
        });
      }
    }
    return options;
  };

  const [clinicOptions, setClinicOptions] = useState([]); // To store clinic dropdown options
  const [clinicData, setClinicData] = useState([]); // To store full clinic details
  const [selectedClinicId, setSelectedClinicId] = useState(""); // Selected clinic for "visitor"
  const [selectedClinic, setSelectedClinic] = useState<any>(""); // Selected clinic for "visitor"

  const [secondDocLinkableId, setSecondDocLinkableId] = useState<any>(null);
  const location = useLocation();
  const [message, setMessage] = useState(null); // Stores message object
  const [messageType, setMessageType] = useState(""); // 'error' or 'success'

  const displayMessage = (msg: any, type: any) => {
    setMessage(msg);
    setMessageType(type);
    setTimeout(() => {
      setMessage(null);
      setMessageType("");
    }, 5000); // Clear message after 3 seconds
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const childDocId = searchParams.get("doctorId");
    setSecondDocLinkableId(childDocId);
  }, [location]);

  useEffect(() => {
    setPracticeDetails({
      establishment: "visitor",
    });
  }, [secondDocLinkableId]);

  // Fetch clinics data
  const getClinics = async () => {
    const res = await dispatch(getAllDoctorClinicsAPI(doctorId));
    const resJson = JSON.parse(JSON.stringify(res));
    console.log("resJson : ", resJson);

    if (resJson.payload) {
      const clinics = resJson?.payload?.doctorClinics;
      setClinicData(clinics); // Store full clinic data

      const formattedClinics = clinics.map((clinic: any) => ({
        label: clinic.name, // Display clinic name
        value: clinic.id, // Use clinic id for selection
      }));
      setClinicOptions(formattedClinics); // Set clinic options for dropdown
    }
  };

  useEffect(() => {
    getClinics();
    dispatch(getConfigInfoAPI());
    const searchParams = new URLSearchParams(location.search);
    const childDocId = searchParams.get("doctorId");
    if (!childDocId) {
      setPracticeDetails({
        establishment: "owner",
      });
    }
  }, [dispatch, doctorId]);

  useEffect(() => {
    if (configInfo?.cities) {
      setCities(configInfo.cities);
    }
  }, [configInfo]);

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);

      // Prefill data from response
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, [user, dispatch]);

  const prefillData = (res: any) => {
    const establishmentType = res?.payload?.data?.doctor?.establishment_type;
    console.log("prefillData : ", establishmentType);
    const searchParams = new URLSearchParams(location.search);
    const childDocId = searchParams.get("doctorId");
    if (childDocId) {
      return;
    }
    if (establishmentType) {
      // Set the establishment type based on the fetched data
      setPracticeDetails((prevDetails) => ({
        ...prevDetails,
        establishment: establishmentType,
      }));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setPracticeDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (value.trim() !== "") {
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {} as any;

    if (!practiceDetails.establishment.trim()) {
      errors.establishment = "Please select an option";
    }

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      if (practiceDetails.establishment === "visitor") {
        if (!secondDocLinkableId) {
          console.error("Visitor Linkable Id not found");
        }
        if (!doctorId) {
          console.error("Doctor Linkable Id not found");
        }
        if (!secondDocLinkableId && !doctorId) {
          return;
        }
        const payload = {
          id: doctorId,
          establishmentObj: {
            establishment_name: selectedClinic?.name || "",
            establishment_city: selectedClinic?.city,
            establishment_locality: selectedClinic?.address,
            establishment_type: "visitor",
          },
        };
        const res0 = await dispatch(doctorEstablishmentDetails(payload));
        console.log("resj0 : ", JSON.parse(JSON.stringify(res0)));

        const linkClinicBody = {
          visiting_doctor_id: `${secondDocLinkableId || doctorId}`,
          clinic_id: `${selectedClinicId}`,
        };
        const res = await dispatch(addDocToClinicAPI(linkClinicBody));
        const resJson = JSON.parse(JSON.stringify(res));
        if (resJson?.error?.message) {
          toast.error(resJson?.error.message);
          return;
        }
        console.log("resj : ", resJson);
        history.push(`/register/progress-summary`);
      }
      // else {
      //   history.push(
      //     `/register/progress-summary?practice=${practiceDetails.establishment}`
      //   );
      // }
    }
  };

  useEffect(() => {
    if (practiceDetails.establishment === "owner") {
      setShowClinicModal(true);
    } else {
      setShowClinicModal(false);
    }
  }, [practiceDetails.establishment]);

  const handleSaveChanges = async () => {
    const newErrors: any = {};
    if (!clinicName.trim()) newErrors.clinicName = "Clinic name is required";
    if (!clinicAddress.trim())
      newErrors.clinicAddress = "Clinic address is required";
    if (!clinicState) newErrors.clinicState = "State is required";
    if (!clinicCity) newErrors.clinicCity = "City is required";
    if (!clinicPin) newErrors.clinicPin = "Pin is required";
    if (!startTime || !endTime) newErrors.timings = "Timings are required";

    setErrors(newErrors);

    const formatTime = (time: any) => {
      console.log("time : ", time);
      return time ? `${time}:00` : ""; // Ensure 'ss' is always '00'
    };

    const clinicData = {
      clinicName,
      address: clinicAddress,
      state: clinicState,
      city: clinicCity,
      pinCode: clinicPin,
      timings: {
        start_time: formatTime(startTime),
        end_time: formatTime(endTime),
      },
    };

    if (Object.keys(newErrors).length === 0) {
      console.log("clinicData : ", clinicData);
      const res = await dispatch(addClinicAPI(clinicData));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res : ", resJson);
      if (resJson.error) {
        // toast.error(resJson?.error?.message);
        displayMessage(resJson.error.message, "error");
        return;
      } else {
        toast.success("Clinic added successfully");
        history.push(
          `/register/establishment-details?practice=${practiceDetails.establishment}`
        );
      }
    }
  };

  const stateList = [
    {
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
    { label: "Daman and Diu", value: "Daman and Diu" },
    { label: "Delhi", value: "Delhi" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Puducherry", value: "Puducherry" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
  ];

  const handleSelectClinic = async (value: any) => {
    setSelectedClinicId(value);
    const selectedClinic = clinicData.find(
      (clinic: any) => clinic.id === value
    );
    console.log("Selected Clinic Details: ", selectedClinic); // Log full clinic details
    setSelectedClinic(selectedClinic);
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Connect a practice</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="form-label">
                  Please select any one of the following
                </Form.Label>
                <div className="practice-options">
                  <Form.Check
                    inline
                    type="radio"
                    label="I own an establishment"
                    name="establishment"
                    value="owner"
                    checked={
                      practiceDetails.establishment === "owner" ||
                      !secondDocLinkableId
                    }
                    onChange={handleInputChange}
                    isInvalid={!!errorData.establishment}
                    disabled={secondDocLinkableId}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="I visit an establishment"
                    name="establishment"
                    value="visitor"
                    checked={practiceDetails.establishment === "visitor"}
                    onChange={handleInputChange}
                    isInvalid={!!errorData.establishment}
                    disabled={!secondDocLinkableId}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errorData.establishment}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              {/* Conditionally render clinic details form */}
              {practiceDetails.establishment === "owner" && (
                <div className="clinic-details-form d-flex align-items-center gap-3 mt-3">
                  <h3>Clinic Details</h3>

                  <Input
                    placeholder="Clinic Name"
                    value={clinicName}
                    onChange={(e) => {
                      setErrors({ ...errors, clinicName: "" });
                      setClinicName(e.target.value);
                    }}
                    status={errors.clinicName && "error"}
                  />
                  {errors.clinicName && (
                    <p className="error">{errors.clinicName}</p>
                  )}

                  <Input
                    placeholder="Clinic Address"
                    value={clinicAddress}
                    onChange={(e) => {
                      setErrors({ ...errors, clinicAddress: "" });
                      setClinicAddress(e.target.value);
                    }}
                    status={errors.clinicAddress && "error"}
                  />
                  {errors.clinicAddress && (
                    <p className="error">{errors.clinicAddress}</p>
                  )}

                  {/* State Dropdown */}
                  <AntdSelect
                    showSearch
                    placeholder="Select State"
                    value={clinicState}
                    onChange={(value) => {
                      setErrors({ ...errors, clinicState: "" });
                      setClinicState(value);
                    }}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {stateList.map((state) => (
                      <Option key={state.value} value={state.value}>
                        {state.label}
                      </Option>
                    ))}
                  </AntdSelect>
                  {errors.clinicState && (
                    <p className="error">{errors.clinicState}</p>
                  )}

                  {/* City Dropdown */}
                  <AntdSelect
                    showSearch
                    placeholder="Select City"
                    value={clinicCity}
                    onChange={(value) => {
                      setErrors({ ...errors, clinicCity: "" });
                      setClinicCity(value);
                    }}
                    style={{ width: "100%" }}
                    options={cities.map((city: any) => ({
                      value: city.id,
                      label: city.name,
                    }))}
                    filterOption={(input: any, option: any) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                  {errors.clinicCity && (
                    <p className="error">{errors.clinicCity}</p>
                  )}

                  {/* Pin Code Input */}
                  <Input
                    placeholder="Pin Code"
                    value={clinicPin}
                    maxLength={6} // Limit to 6 digits
                    onChange={(e) => {
                      setErrors({ ...errors, clinicPin: "" });
                      const value = e.target.value.replace(/\D/g, ""); // Filter non-numeric characters
                      setClinicPin(value); // Update state with valid input
                    }}
                    status={errors.clinicPin ? "error" : ""} // Apply error status if validation fails
                  />
                  {errors.clinicPin && (
                    <p className="error">{errors.clinicPin}</p>
                  )}

                  {/* Timings */}
                  {/* Timings */}
                  <div className="row">
                    <div className="col-6">
                      <AntdSelect
                        showSearch
                        style={{ width: "max-content", minWidth: "100px" }}
                        placeholder="Select start time"
                        optionFilterProp="label"
                        value={startTime}
                        onChange={(value) => {
                          setErrors({ ...errors, timings: "" });
                          setStartTime(value);
                          if (endTime && value >= endTime) setEndTime(""); // Reset end time if it becomes invalid
                        }}
                        options={generateTimeOptions()}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        dropdownAlign={{
                          points: ["tl", "bl"],
                          offset: [0, 4],
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <AntdSelect
                        showSearch
                        style={{ width: "max-content", minWidth: "100px" }}
                        placeholder="Select end time"
                        optionFilterProp="label"
                        value={endTime}
                        onChange={(value) => {
                          setErrors({ ...errors, timings: "" });
                          setEndTime(value);
                        }}
                        options={generateTimeOptions().filter(
                          (option) => option.value > startTime
                        )}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        disabled={!startTime}
                        dropdownAlign={{
                          points: ["tl", "bl"],
                          offset: [0, 4],
                        }}
                      />
                    </div>
                  </div>
                  {errors.timings && <p className="error">{errors.timings}</p>}

                  <Button
                    style={{ width: "fit-content" }}
                    onClick={handleSaveChanges}
                  >
                    Save
                  </Button>
                  {message && (
                    <div
                      style={{
                        marginTop: "20px",
                        padding: "10px",
                        borderRadius: "5px",
                        color: "#fff",
                        backgroundColor:
                          messageType === "error" ? "red" : "green",
                      }}
                    >
                      {message}
                    </div>
                  )}
                </div>
              )}

              {/* Conditionally render "Select Clinic" dropdown when visiting clinic */}
              {practiceDetails.establishment === "visitor" && (
                <div className="select-clinic-form d-flex align-items-start gap-3">
                  <h3>Select a Clinic</h3>
                  <AntdSelect
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select Clinic"
                    className="clinic-dropdown"
                    options={clinicOptions}
                    onSelect={(value: any) => {
                      handleSelectClinic(value);
                    }}
                    filterOption={(input: any, option: any) =>
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </div>
              )}

              <div className="button-container flex-row">
                <Button
                  className="back-button"
                  onClick={() =>
                    history.push("/register/education-qualification")
                  }
                >
                  <BackIcon /> Back
                </Button>
                {practiceDetails.establishment === "visitor" && (
                  <Button className="continue-button" type="submit">
                    Next
                  </Button>
                )}
              </div>
            </Form>
            <div className="info-box">
              Patients frequently look for doctors by locality. Thus, listing
              all your practice locations ensures your profile appears in all
              relevant searches.
            </div>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default ConnectPractice;
