import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { FooterStyled } from "./Footer.styled";
import { bindActionCreators } from "redux";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import bag from "../../img/bag.png";

import { Link, useHistory, withRouter } from "react-router-dom";
const Footer = (props) => {
  useEffect(() => {}, []);

  return (
    <>
      <FooterStyled>
        <div className="footer-wrapper">
          <div className="footer-section-nme">
            <div className="footer-section-content">
              <div className="footer-row">
                <h5>COMPANY</h5>
                <ul>
                  <li>
                  <Link to="/aboutus" className="text-decoration-none">
                      About Us
                    </Link>
                  </li>
                  <li>
                    
                    <Link to="/career" className="text-decoration-none">
                      Careers
                    </Link>
                  </li>
                  <li>
                    <a href="/contactus">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="footer-row">
                <h5>FOR PATIENTS</h5>
                <ul>
                  <li>
                    <Link to="/doctor?tab=opd">Search for Doctors</Link>
                  </li>
                  <li>
                    <Link to="/doctor?tab=opd">Search for Clinics</Link>
                  </li>
                  <li>
                    <Link to="/doctor">Search for Hospitals</Link>
                  </li>
                  <li>
                    <Link to="/labtest">Book Diagnostic Tests</Link>
                  </li>
                  <li>
                    <Link to="/labtest/allBodyTests">Book Full Body Checkups</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-row">
                <h5>ABOUT</h5>
                <ul>
                  <li>
                    <a href="/health-articles">Read Health Articles</a>
                  </li>
                  <li>Read About Medicines</li>
                  <li>RaphaCure app</li>
                </ul>
              </div>
              <div className="footer-row">
                <h5>OUR POLICIES</h5>
                <ul>
                  <li>
                    <a href="/privacy-policy">Privacy & Policies</a>
                  </li>
                  <li>
                    <a href="/terms">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="/return-refund">Return & Refund Policies</a>
                  </li>
                  <li>
                    <a href="/shipping-policy">Shipping Policies</a>
                  </li>
                  <li>
                    <a href="/security-policy">Security Policies</a>
                  </li>
                  <li>
                    <a href="/disclaimer">Disclaimer</a>
                  </li>
                  <li>
                    <a href="/return-refund">Responsible Disclosure Policy</a>
                  </li>
                </ul>
              </div>
              <div className="footer-row">
                <h5>Contact Us</h5>
                <ul>
                  <li>
                    38/3, 6th Cross, Vibgyor High School Road,
                    <br /> opp. Thomas Square, HSR Extension,
                    <br /> Reliable Tranquil Layout,
                    <br /> Bengaluru, Karnataka-560102.
                  </li>
                  <li>+91 95551 66000</li>
                  <li>wellness@raphacure.com</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="bottom-footer">
            <p>
              ©2024. All Rights Reserved by RaphaCure - Registered Trademark of
              Cure And Care Primary Care PVT LTD
            </p>
          </div>
        </div>
      </FooterStyled>
    </>
  );
};

export default Footer;
