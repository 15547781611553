import React, { useEffect, useState } from "react";
// import { ReactComponent as Overviewsvg } from "../../../../assets/icons/overviewsvg.svg";
import { ReactComponent as Orange } from "../../../../assets/icons/orange.svg";
import { ReactComponent as Purple } from "../../../../assets/icons/purple.svg";
import { ReactComponent as Red } from "../../../../assets/icons/red.svg";
import { ReactComponent as Green } from "../../../../assets/icons/green.svg";
import { ReactComponent as Doc } from "../../../../assets/icons/docicon.svg";
import { ReactComponent as Greenarrow } from "../../../../assets/icons/greenarrow.svg";
import { ReactComponent as Redarrow } from "../../../../assets/icons/redarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import PiCharts from "../PiChart/PiCharts";
import PatientDetailModal from "../../../../components/PatientDetailModal/Patientmodal";
import { getAllBookingListAPI } from "../../../../redux/slices/dashboard/dashboardService";

const appointreject = [
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
  {
    name: "Alex Peter",
    age: 32,
    gender: "Male",
    city: "Bangalore",
  },
];

const Overview = () => {
  const [lgShow, setLgShow] = useState(false);
  const dispatch = useDispatch();
  const [patient, setPatient] = useState({
    name: "Priyanka",
    age: "23",
    gender: "Female",
    city: "BLR",
  });
  const { dashboardInfo } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  const pInfo = dashboardInfo?.patientsOverview;
  const cInfo = dashboardInfo?.consultations;

  const handleModalClose = () => {
    setLgShow(false);
  };

  return (
    <>
      <div className="overview">
        <div className="overview-left" key={44}>
          <div className="patient-overview">
            <h4>Patient Overview</h4>
            <p className="overview-icon">
              {" "}
              <PiCharts element={dashboardInfo} />{" "}
            </p>
            <p className="num">{pInfo?.total}</p>
            <p className="totpat">Total patients</p>

            <div className="pat-cat">
              <div className="leftcat">
                <p>
                  <Orange /> Child <span>{pInfo?.child}</span>
                </p>

                <p>
                  <Red /> Adult <span> {pInfo?.adult}</span>
                </p>
              </div>
              <div className="rightcat">
                <p>
                  <Purple /> Teen <span>{pInfo?.teen} </span>
                </p>
                <p>
                  <Green /> Old <span>{pInfo?.old}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* {consulatation.map((item, index) => ( */}
        <div className="overview-right">
          <div className="Totalconsult">
            <div
              className="consult"
              onClick={() => {
                dispatch(getAllBookingListAPI({ count: 20, status: "" }));
              }}
            >
              {/* <p className="percentage">
                {"65%"} <Greenarrow />
              </p> */}
              <p className="icon-svg">
                <Doc />
              </p>
              <p className="totnumber">{cInfo?.total}</p>
              <p>
                Total <br />
                Consultation
              </p>
            </div>

            <div
              className="consult"
              onClick={() => {
                dispatch(
                  getAllBookingListAPI({
                    count: 20,
                    status: "completed,report_delivered,awaiting_report",
                  })
                );
              }}
            >
              {/* <p className="percentage iconred">
                {cInfo?.percent} <Redarrow />
              </p> */}
              <p className="icon-svg">
                <Doc />
              </p>
              <p className="totnumber">{cInfo?.completedConsultations} </p>
              <p>
                Completed <br />
                Consultation
              </p>
            </div>
          </div>
          <div className="upcomingconsut">
            <div
              className="consult"
              onClick={() => {
                dispatch(getAllBookingListAPI({ count: 20, status: "open" }));
              }}
            >
              {/* <p className="percentage">
                {cInfo?.percent} <Greenarrow />
              </p> */}
              <p className="icon-svg">
                <Doc />
              </p>
              <p className="totnumber">{cInfo?.upcomingConsultations}</p>
              <p>
                Upcoming <br />
                Consultation
              </p>
            </div>

            <div
              key={233}
              className="consult"
              onClick={() => {
                dispatch(
                  getAllBookingListAPI({ count: 20, status: "cancelled" })
                );
              }}
            >
              {/* <p className="percentage iconred">
                {cInfo?.percent} <Redarrow />
              </p> */}
              <p className="icon-svg">
                <Doc />
              </p>
              <p className="totnumber">{cInfo?.rejectedConsultations} </p>
              <p>
                Rejected <br />
                Consultation
              </p>
            </div>

            <PatientDetailModal
              show={lgShow}
              onHide={handleModalClose}
              patient={patient}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
