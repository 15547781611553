import React, { useState } from "react";
import "./AttendanceModal.css";
import History from "./History";
import MarkAttendance from "./MarkAttendance";

const Attendance = () => {
  const [activeTab, setActiveTab] = useState("markAttendance");

  return (
    <div className="attendance">
      <div className="attendance-header">
        <div className="Heading">Attendance</div>
        <div className="attendance-actions">
          <button
            className={`mark-attendance ${
              activeTab === "markAttendance" ? "active" : ""
            }`}
            onClick={() => setActiveTab("markAttendance")}
          >
            Mark Attendance
          </button>
          <button
            className={`recent-history ${
              activeTab === "recentHistory" ? "active" : ""
            }`}
            onClick={() => setActiveTab("recentHistory")}
          >
            Recent History
          </button>
        </div>
      </div>

      {activeTab === "markAttendance" && <MarkAttendance />}
      {activeTab === "recentHistory" && <History />}

      <div className="exportbtn">
        <button>Export As Excel</button>
      </div>
    </div>
  );
};

export default Attendance;
