import styled from "styled-components";

export const BookingStyled = styled.div`
  width: 100%;

  // .appoint-order {
  //   padding: 0 20px;
  // }
  .ordertable {
    margin-top: 12px;
  }

  .ordertable Table {
    --bs-table-bg: transparent !important;
    border-radius: 5px;
    border: 1px solid #d6cece;
  }
  .ordertable thead {
    border-radius: 5px 5px 0px 0px;
    background: #d3edfc;
  }
  .ordertable th {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ordertable td {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ordertable tr {
    border: 1px solid #d6cece;
  }
  .ordertable .action-report {
    text-align: center;
  }
  .ordertable .action-report svg {
    cursor: pointer !important;
  }
  .orderTable .actions {
    display: flex;
    align-items: center;
  }
  .ordertable .actions {
    text-align: center;
  }
  .ordertable .actions svg {
    cursor: pointer;
  }
  .ordertable .icon-space {
    margin: 0 6px;
  }
  @media (max-width: 768px) {
    .ordertable .icon-space {
      margin: 0 0;
    }
  }
`;
