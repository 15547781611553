import styled from "styled-components";

export const PatientDetStyled = styled.div`
  .profile-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
  }

  .profile-info {
    display: flex;
  }

  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
    border: 2px solid #7e3ff2;
    /* Purple border */
  }

  .profile-details {
    display: flex;
    flex-direction: column;
  }

  .profile-details h2 {
    margin: 0;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.26px;
    opacity: 0.8;
  }

  .profile-details p {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    opacity: 0.7;
  }

  .profile-details span {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
    opacity: 0.8;
    margin-right: 6px;
  }

  .contact-button {
    background-color: #fff;
    /* Purple background */
    border: 1px solid #e7dada;
    border-radius: 4px;
    padding: 8px 18px;
    cursor: pointer;
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
  }

  .contact-button:hover {
    background-color: #5c2dbf;
    color: #fff;
    /* Darker purple on hover */
  }

  .appointment-details {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header h2 {
    margin: 0;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    opacity: 0.8;
  }

  .appointment-details {
    padding: 20px;
  }

  .header-right {
    display: flex;
    align-items: center;
  }

  .appointment-mode {
    margin-right: 16px;
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    opacity: 0.5;
  }

  .appointment-mode .mode {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    opacity: 0.7;
  }

  .appointment-actions {
    display: flex;
    gap: 8px;
  }

  .report-button,
  .order-history-button,
  .add-prescription-button,
  .prescription-button {
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #e7dada;
    background: #fff;
    box-shadow: 2px 2px 14px 0px rgba(0, 0, 0, 0.1);
    color: #9747ff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    opacity: 0.7;
  }

  .report-button:hover,
  .prescription-button:hover {
    background-color: #7e3ff2;
    color: #fff;
  }

  .appointment-info {
    margin-bottom: 16px;
    width: 50%;
  }

  .appointment-item {
    margin-bottom: 8px;
    flex-basis: 30%;
    border-bottom: 1px solid lightgray;
  }

  .appointment-item label {
    width: 100px;
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
    opacity: 0.7;
  }

  .appointment-item span {
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    opacity: 0.7;
  }

  .summary {
    margin-bottom: 16px;
  }

  .summary h4 {
    margin-bottom: 8px;
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.42px;
    opacity: 0.7;
  }

  .summary p {
    border-radius: 10px;
    border: 1px solid #c8b4b4;
    padding: 16px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.36px;
    opacity: 0.5;
    width: 70%;
  }

  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .appointment-info {
      width: 100%;
    }
    .summary p {
      width: 100%;
    }
  }
  .action-buttons {
    display: flex;
    justify-content: end;
    gap: 1rem;
  }

  .ant-select-dropdown {
    top: 30px !important;
  }
`;
