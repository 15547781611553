import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Select, TimePicker } from "antd";
import moment from "moment";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back-icon.svg";
import { LoginStyled } from "./Login.Styled";
import { useHistory } from "react-router-dom";
import {
  getDoctorProfileDetails,
  updateConsultationFeeAPI,
} from "../../redux/slices/doctor/doctorService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const ConsultantDoctor = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [consultationFeeChat, setConsultationFeeChat] = useState("");
  const [chatTimes, setChatTimes] = useState([
    { startTime: "07:00", endTime: "", selectedDays: [] },
  ]);

  const [consultationFeeCall, setConsultationFeeCall] = useState("");
  const [callTimes, setCallTimes] = useState([
    { startTime: "07:00", endTime: "", selectedDays: [] },
  ]);

  const [consultationFeeVideoCall, setConsultationFeeVideoCall] = useState("");
  const [videoCallTimes, setVideoCallTimes] = useState([
    { startTime: "07:00", endTime: "", selectedDays: [] },
  ]);

  const [consultationFeeHomeCare, setConsultationFeeHomeCare] = useState("");
  const [homeCareTimes, setHomeCareTimes] = useState([
    { startTime: "07:00", endTime: "", selectedDays: [] },
  ]);

  const [consultationFeeInstant, setConsultationFeeInstant] = useState("");
  const [instantTimes, setInstantTimes] = useState([
    { startTime: "07:00", endTime: "", selectedDays: [] },
  ]);

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();

  const [isChatSelected, setIsChatSelected] = useState(false);
  const [isCallSelected, setIsCallSelected] = useState(false);
  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const [isHomeCareSelected, setIsHomeCareSelected] = useState(false);
  const [isInstantSelected, setIsInstantSelected] = useState(false);

  useEffect(() => {
    const getDocDetails = async () => {
      console.log("user : ", user);
      var docId;
      if (!user) {
        return;
      }
      if (user?.roles?.length > 0) {
        if (user.roles[0].linkable_id) {
          docId = user.roles[0].linkable_id;
          setDoctorId(user.roles[0].linkable_id);
        }
      }

      const res = await dispatch(getDoctorProfileDetails(docId));
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("res doc details : ", resJson);
      prefillData(resJson);
      if (resJson?.error?.message) {
        console.log("error : ", resJson?.error);
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson?.payload?.data) {
        console.log("doc profile details : ", resJson?.payload?.data?.doctor);
      }
    };
    getDocDetails();
  }, [dispatch, user]);

  const prefillData = (data: any) => {
    console.log("prefillData : ", data.payload);
  };

  const convertTo24Hour = (timeString: string): string => {
    if (!timeString) return "";
    return moment(timeString, ["h:mm A"]).format("HH:mm");
  };

  const convertTo12Hour = (timeString: string): string => {
    if (!timeString) return "";
    return moment(timeString, "HH:mm").format("h:mm A");
  };

  const handleAddTime = (type: string) => {
    const getTimesArray = () => {
      switch (type) {
        case "chat":
          return chatTimes;
        case "call":
          return callTimes;
        case "video":
          return videoCallTimes;
        case "home_care":
          return homeCareTimes;
        case "instant":
          return instantTimes;
        default:
          return [];
      }
    };

    const timesArray = getTimesArray();
    if (timesArray.length < 7) {
      const newTime = { startTime: "07:00", endTime: "", selectedDays: [] };
      switch (type) {
        case "chat":
          setChatTimes([...chatTimes, newTime]);
          break;
        case "call":
          setCallTimes([...callTimes, newTime]);
          break;
        case "video":
          setVideoCallTimes([...videoCallTimes, newTime]);
          break;
        case "home_care":
          setHomeCareTimes([...homeCareTimes, newTime]);
          break;
        case "instant":
          setInstantTimes([...instantTimes, newTime]);
          break;
      }
    }
  };

  const handleRemoveTime = (type: string, index: number) => {
    if (type === "chat") setChatTimes(chatTimes.filter((_, i) => i !== index));
    if (type === "call") setCallTimes(callTimes.filter((_, i) => i !== index));
    if (type === "video")
      setVideoCallTimes(videoCallTimes.filter((_, i) => i !== index));
    if (type === "home_care")
      setHomeCareTimes(homeCareTimes.filter((_, i) => i !== index));
    if (type === "instant")
      setInstantTimes(instantTimes.filter((_, i) => i !== index));
  };

  const handleDaySelect = (type: string, timeIndex: number, day: string) => {
    const updateTimes = (times: any[]) => {
      const newTimes = [...times];
      const dayIndex = newTimes[timeIndex].selectedDays.indexOf(day);

      // Toggle the day selection
      if (dayIndex === -1) {
        newTimes[timeIndex].selectedDays.push(day);
      } else {
        newTimes[timeIndex].selectedDays.splice(dayIndex, 1);
      }
      return newTimes;
    };

    switch (type) {
      case "chat":
        setChatTimes(updateTimes(chatTimes));
        break;
      case "call":
        setCallTimes(updateTimes(callTimes));
        break;
      case "video":
        setVideoCallTimes(updateTimes(videoCallTimes));
        break;
      case "home_care":
        setHomeCareTimes(updateTimes(homeCareTimes));
        break;
      case "instant":
        setInstantTimes(updateTimes(instantTimes));
        break;
    }
  };

  // Generate time slots rounded to the nearest half-hour interval
  // const generateTimeSlots = (
  //   startTime: moment.Moment | null,
  //   endTime: moment.Moment | null
  // ) => {
  //   const slots = [];
  //   const start = startTime
  //     ? startTime.clone().startOf("minute")
  //     : moment().startOf("minute");
  //   const end = endTime
  //     ? endTime.clone().startOf("minute")
  //     : moment("9:00 PM", "hh:mm A");

  //   // Round start time to nearest half-hour
  //   if (start.minute() !== 0 && start.minute() !== 30) {
  //     if (start.minute() < 15) {
  //       start.minute(0); // Round down to the nearest hour
  //     } else if (start.minute() < 45) {
  //       start.minute(30); // Round to the nearest half-hour
  //     } else {
  //       start.add(1, "hour").minute(0); // Round up to the next hour
  //     }
  //   }

  //   // Ensure the end time is at least 30 minutes after start time
  //   if (end.isBefore(start.clone().add(30, "minutes"))) {
  //     throw new Error("End time must be at least 30 minutes after start time.");
  //   }

  //   while (start.isBefore(end) || start.isSame(end)) {
  //     slots.push(start.clone());
  //     start.add(30, "minutes");
  //   }

  //   return slots;
  // };

  // In the generateTimeSlots function, modify it to accept a start time parameter
  const generateTimeSlots = (startTime?: string) => {
    const slots = [];
    // If startTime is provided, use it as the starting point, otherwise use 7 AM
    let time = startTime
      ? moment(startTime, "HH:mm")
      : moment("07:00", "HH:mm");
    const end = moment("21:00", "HH:mm");

    // When generating for end time, start 30 minutes after the start time
    if (startTime) {
      time.add(30, "minutes");
    }

    while (time <= end) {
      slots.push({
        value: time.format("HH:mm"), // 24-hour format for storage
        label: time.format("h:mm A"), // 12-hour format for display
      });
      time.add(30, "minutes");
    }
    return slots;
  };

  const startTime = moment("07:00 AM", "hh:mm A");
  const endTime = moment("09:00 PM", "hh:mm A");

  // Example of handling time changes
  const handleTimeChange = (
    type: any, // consultation type (chat, call, etc.)
    timeIndex: any, // index of the time slot
    field: any, // startTime or endTime
    time: any // time string in 12-hour format
  ) => {
    const updateTimes = (times: any[]) => {
      const newTimes = [...times];
      const selectedTime = convertTo24Hour(time);

      if (!selectedTime) {
        toast.error("Invalid time selected!");
        return times;
      }

      newTimes[timeIndex][field] = selectedTime;
      return newTimes;
    };

    switch (type) {
      case "chat":
        setChatTimes(updateTimes(chatTimes));
        break;
      case "call":
        setCallTimes(updateTimes(callTimes));
        break;
      case "video":
        setVideoCallTimes(updateTimes(videoCallTimes));
        break;
      case "home_care":
        setHomeCareTimes(updateTimes(homeCareTimes));
        break;
      case "instant":
        setInstantTimes(updateTimes(instantTimes));
        break;
    }
  };

  const renderConsultationForm = (
    label: string,
    consultationFee: string,
    setConsultationFee: any,
    times: any,
    setTimes: any,
    type: string,
    isSelected: boolean,
    setIsSelected: any
  ) => (
    <div className="consultation-form">
      <div className="initial-row">
        <div
          className="d-flex align-items-center"
          // style={{ marginRight: "auto" }}
        >
          <label className="switch">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => setIsSelected(!isSelected)}
            />
            <span className="slider"></span>
          </label>
          <span className="ms-2">{label}</span>
        </div>

        {isSelected && (
          <div className="fee-input">
            <span className="currency">Rs.</span>
            <input
              type="number"
              placeholder="Type consultation fee"
              className="consultation-fee"
              style={{ border: "1px solid gray" }}
              value={consultationFee}
              onChange={(e) => setConsultationFee(e.target.value)}
            />
          </div>
        )}

        {isSelected && (
          <div className="d-flex flex-column gap-3">
            {times.map((time: any, timeIndex: number) => (
              <div key={timeIndex} className="d-flex gap-3 align-items-center">
                <div className="weekdays">
                  {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((day) => (
                    <div
                      className={`day ${
                        time.selectedDays.includes(day) ? "selected" : ""
                      }`}
                      key={day}
                      onClick={() => handleDaySelect(type, timeIndex, day)}
                      style={{
                        backgroundColor: time.selectedDays.includes(day)
                          ? "#0EB6CA"
                          : "",
                        color: time.selectedDays.includes(day) ? "#fff" : "",
                        cursor: "pointer",
                      }}
                    >
                      {day}
                    </div>
                  ))}
                </div>
                <div className="time-inputs">
                  <Select
                    value={convertTo12Hour(time.startTime)}
                    onChange={(value) =>
                      handleTimeChange(type, timeIndex, "startTime", value)
                    }
                    style={{ width: "max-content", minWidth: "100px" }}
                    dropdownStyle={{ maxHeight: 200, overflow: "auto" }}
                    placeholder="Select a time"
                  >
                    {generateTimeSlots().map((slot) => (
                      <Select.Option key={slot.value} value={slot.label}>
                        {slot.label}
                      </Select.Option>
                    ))}
                  </Select>

                  <Select
                    value={time.endTime ? convertTo12Hour(time.endTime) : ""}
                    onChange={(value) =>
                      handleTimeChange(type, timeIndex, "endTime", value)
                    }
                    style={{ width: "max-content", minWidth: "100px" }}
                    dropdownStyle={{ maxHeight: 200, overflow: "auto" }}
                    placeholder="Select an end time"
                    disabled={!time.startTime}
                  >
                    {time.startTime &&
                      generateTimeSlots(time.startTime).map((slot) => (
                        <Select.Option key={slot.value} value={slot.label}>
                          {slot.label}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div
                  className="remove-time"
                  onClick={() => handleRemoveTime(type, timeIndex)}
                >
                  <Minus />
                </div>
              </div>
            ))}
          </div>
        )}

        {isSelected && times.length < 7 && (
          <div className="add-time" onClick={() => handleAddTime(type)}>
            <Button style={{ background: "rgb(14, 182, 202)", padding: "7px" }}>
              + Add Slot
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  const handleNextClick = async () => {
    const formatData = (type: string, fee: string, times: any) => {
      const dayMap: { [key: string]: number } = {
        Mo: 1,
        Tu: 2,
        We: 3,
        Th: 4,
        Fr: 5,
        Sa: 6,
        Su: 7,
      };

      return {
        [type]: [
          {
            consultation_fee: parseInt(fee) || 0,
            availabilities: times
              .map((time: any, index: number) => ({
                days_of_week: time.selectedDays.map(
                  (day: string) => dayMap[day]
                ), // Store array of days
                from_time: time.startTime,
                to_time: time.endTime,
                sort: index + 1,
              }))
              .filter(
                (availability: any) => availability.days_of_week.length > 0
              ), // Ensure non-empty day selections
          },
        ],
      };
    };

    let consultationData: any = {};

    // Add only selected fields
    if (isChatSelected) {
      consultationData = {
        ...consultationData,
        ...formatData("chat", consultationFeeChat, chatTimes),
      };
    }
    if (isCallSelected) {
      consultationData = {
        ...consultationData,
        ...formatData("call", consultationFeeCall, callTimes),
      };
    }
    if (isVideoSelected) {
      consultationData = {
        ...consultationData,
        ...formatData("video", consultationFeeVideoCall, videoCallTimes),
      };
    }
    if (isHomeCareSelected) {
      consultationData = {
        ...consultationData,
        ...formatData("home_care", consultationFeeHomeCare, homeCareTimes),
      };
    }
    if (isInstantSelected) {
      consultationData = {
        ...consultationData,
        ...formatData("instant", consultationFeeInstant, instantTimes),
      };
    }

    const payload = {
      id: doctorId,
      consultationFeeObj: removeEmptyAvailabilities(consultationData),
    };

    const res = await dispatch(updateConsultationFeeAPI(payload));
    console.log("res", res);
    const resJson = JSON.parse(JSON.stringify(res));
    console.log("resJson", resJson);
    if (resJson?.error?.message) {
      toast.error(resJson?.error?.message);
      return;
    }
    if (resJson?.payload?.data) {
      toast.success("Consultant Doctor updated successfully");
      history.push("/register/bank-account");
    }
  };

  const removeEmptyAvailabilities = (data: any) => {
    Object.keys(data).forEach((key) => {
      if (data[key][0].availabilities.length === 0) {
        delete data[key];
      }
    });
    return data;
  };

  return (
    <LoginStyled>
      <div className="form">
        <div className="header">
          {/* <BackIcon onClick={() => history.goBack()} /> */}
          <h2>Consultation Fees</h2>
        </div>
        <div className="cons-forms">
          {renderConsultationForm(
            "Chat Consultation",
            consultationFeeChat,
            setConsultationFeeChat,
            chatTimes,
            setChatTimes,
            "chat",
            isChatSelected,
            setIsChatSelected
          )}
          {renderConsultationForm(
            "Call Consultation",
            consultationFeeCall,
            setConsultationFeeCall,
            callTimes,
            setCallTimes,
            "call",
            isCallSelected,
            setIsCallSelected
          )}
          {renderConsultationForm(
            "Video Consultation",
            consultationFeeVideoCall,
            setConsultationFeeVideoCall,
            videoCallTimes,
            setVideoCallTimes,
            "video",
            isVideoSelected,
            setIsVideoSelected
          )}
          {renderConsultationForm(
            "Home Care Consultation",
            consultationFeeHomeCare,
            setConsultationFeeHomeCare,
            homeCareTimes,
            setHomeCareTimes,
            "home_care",
            isHomeCareSelected,
            setIsHomeCareSelected
          )}
          {renderConsultationForm(
            "Instant Consultation",
            consultationFeeInstant,
            setConsultationFeeInstant,
            instantTimes,
            setInstantTimes,
            "instant",
            isInstantSelected,
            setIsInstantSelected
          )}
        </div>
        <div className="bn-buttons-wrapper">
          <span
            onClick={() => {}}
            className=" text-xl cursor-pointer top-category-sec font-bold mx-2 back-btn-sec opacity-75 "
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.6875 7.7152H4.12125L8.88563 2.10999C9.10841 1.84745 9.21559 1.50898 9.18359 1.16903C9.15159 0.829086 8.98304 0.515513 8.715 0.297298C8.44697 0.0790819 8.10141 -0.0259025 7.75435 0.00544001C7.40729 0.0367826 7.08716 0.201885 6.86437 0.464426L0.301875 8.17802C0.257723 8.23937 0.218242 8.30383 0.18375 8.37086C0.18375 8.43514 0.18375 8.47371 0.0918752 8.53799C0.0323842 8.68539 0.00123522 8.8423 0 9.0008C0.00123522 9.1593 0.0323842 9.31621 0.0918752 9.46362C0.0918752 9.5279 0.0918748 9.56647 0.18375 9.63075C0.218242 9.69778 0.257723 9.76223 0.301875 9.82359L6.86437 17.5372C6.98778 17.6823 7.14231 17.799 7.31699 17.879C7.49166 17.959 7.68218 18.0003 7.875 18C8.18167 18.0006 8.47887 17.896 8.715 17.7043C8.8479 17.5964 8.95776 17.4638 9.03829 17.3143C9.11881 17.1647 9.16842 17.001 9.18427 16.8326C9.20012 16.6643 9.1819 16.4945 9.13065 16.333C9.0794 16.1715 8.99614 16.0215 8.88563 15.8916L4.12125 10.2864H19.6875C20.0356 10.2864 20.3694 10.151 20.6156 9.90986C20.8617 9.66876 21 9.34177 21 9.0008C21 8.65984 20.8617 8.33284 20.6156 8.09175C20.3694 7.85065 20.0356 7.7152 19.6875 7.7152Z"
                fill="#444444"
              />
            </svg>{" "}
            &nbsp;
            {` Back`}
          </span>
          <Button className="btn-next" onClick={handleNextClick}>
            Next
          </Button>
        </div>
      </div>
    </LoginStyled>
  );
};

export default ConsultantDoctor;
