import React from "react";
import { StackbarchartStyled } from "./Stackedbarchart.styled";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";

const data = [
  { name: "Sun 04", offline: 30, online: 50 },
  { name: "Mon 05", offline: 40, online: 70 },
  { name: "Tue 06", offline: 20, online: 90 },
  { name: "Wed 07", offline: 50, online: 80 },
  { name: "Thu 08", offline: 60, online: 70 },
  { name: "Fri 09", offline: 30, online: 40 },
  { name: "Sat 10", offline: 40, online: 50 },
  { name: "Sun 11", offline: 50, online: 60 },
  { name: "Mon 12", offline: 40, online: 50 },
  { name: "Tue 13", offline: 50, online: 70 },
];
const StackedBAr = () => {
  return (
    <StackbarchartStyled>
      <div className="chart-container">
        <h4>Appointment Status</h4>
        <BarChart
          width={420}
          height={250}
          data={data}
          margin={{
            top: 20,
            right: 3,
            left: 2,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="offline" stackId="a" fill="#f0c420" name="Offline" />
          <Bar dataKey="online" stackId="a" fill="#4f9dde" name="Online" />
        </BarChart>
      </div>
    </StackbarchartStyled>
  );
};
export default StackedBAr;
