import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { get, patch, post, put } from "../../../library/Requests/helpers";
import {
  getAllMedicinesQuery,
  getAllTestsQuery,
} from "../../../Scenes/graphqlConfig";

export const getAllMedicinesAPI = createAsyncThunk(
  "auth/getAllMedicinesAPI",
  async () =>
    await post(`${SERVER_IP}/graphql`, JSON.stringify(getAllMedicinesQuery))
);

export const getAllTestsAPI = createAsyncThunk(
  "auth/getAllTestsAPI",
  async () =>
    await post(`${SERVER_IP}/graphql`, JSON.stringify(getAllTestsQuery))
);
