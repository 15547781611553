import React, { useEffect, useState } from "react";
import { MyProfileStyled } from "./MyProfile.styled";
import { BiHomeAlt } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { FaCity } from "react-icons/fa";
import { MdEdit, MdMovieEdit } from "react-icons/md";
import { IoMdWarning } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetails } from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import docImg from "../../assets/images/doctor-male.png";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { MAP_KEY } from "../../config";
import ImageUploadModel from "../../components/ImageUploadModel/ImageUploadModel";
import { MdEditNote } from "react-icons/md";

const initVal = {
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  phone: undefined,
  age: undefined,
  relation: undefined,
  gender: undefined,
};

const MyProfile = () => {
  const dispatch = useDispatch();
  const [docObj, setDocObj] = useState({});
  const history = useHistory();
  const [address, setAddress] = useState("N/A");
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: MAP_KEY,
  });
  const [showImageMoodel, setShowImageMoodel] = useState(false);
  const [signUpData, setSignUpData] = useState(initVal);
  const { user } = useSelector((ReduxState) => ReduxState.auth);

  const updateOnboardingDetails = () => {
    history.push("/register");
  };

  const getAddressFromLatLng = async (lat, lng) => {
    if (!isLoaded) return;

    try {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setAddress(results[0].formatted_address);
          } else {
            console.log("No results found");
            setAddress("No address found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
          setAddress("Error retrieving address");
        }
      });
    } catch (error) {
      console.log("Geocoding error: ", error);
      setAddress("Error retrieving address");
    }
  };

  useEffect(() => {
    // Assuming the lat and long are part of docObj
    if (docObj?.lat && docObj?.long && isLoaded) {
      getAddressFromLatLng(docObj?.lat, docObj?.long);
    }
  }, [docObj, isLoaded]);

  useEffect(() => {
    const getDoctorProfileInfo = async () => {
      try {
        const response = await dispatch(getProfileDetails());
        const resJson = JSON.parse(JSON.stringify(response));
        setDocObj(resJson?.payload?.data?.doctor);
      } catch (error) {
        console?.error(error);
        toast.error(error);
      }
    };
    getDoctorProfileInfo();
  }, []);

  useEffect(() => {
    console.log("docObj : ", docObj);
  }, [docObj]);

  const handleOverlayClick = () => {
    console.log("Overlay clicked");
    handleOpenImage();
  };

  const handleOpenImage = () => {
    setShowImageMoodel(true);
  };

  const handleCloseImage = () => {
    setShowImageMoodel(false);
  };

  const handleImageUploadSuccess = (img) => {
    console.log("img img img", img);
  };
  return (
    <MyProfileStyled>
      <div className="bannnerGradient"></div>
      <div className="userDiv spacedDiv">
        <div className="imgDiv">
          <img src={docObj?.image || user?.image || docImg} alt="" />
          <div className="profileOverlay" onClick={handleOverlayClick}>
            <p>Click to Update</p>
          </div>
        </div>

        <h2>{docObj?.name || "NA"}</h2>
        <p>{docObj?.user?.email || docObj?.clinic_email || "NA"}</p>
        <p>{docObj?.user?.phone || "NA"}</p>
      </div>
      <div className="addrWrapper">
        <MdEdit
          className="ml-auto cursor-pointer"
          size={23}
          onClick={() => {
            window.open("/register/map-location", "_self");
          }}
        />
        {/* <div className="addrItem">
          <BiHomeAlt size={23} />
          <div>{docObj?.home || "NA"}</div>
        </div> */}
        <div className="addrItem">
          <CiLocationOn size={30} />
          <div>{address || "NA"}</div>
        </div>
        <div className="addrItem">
          <FaCity size={23} />
          <div>{docObj?.city || "NA"}</div>
        </div>
      </div>
      <div className="otherInfo">
        <div className="medInfo">
          <MdEdit
            className="ml-auto cursor-pointer"
            size={23}
            onClick={() => {
              history.push("/register/medicalregistration");
            }}
          />
          <div>
            <h2>Medical Registration Number </h2>
            <p>{docObj?.registration_number || "NA"}</p>
          </div>
          <div>
            <h2>Medical Council </h2>
            <p>{docObj?.medical_registration_body || "NA"} </p>
          </div>
          <div>
            <h2>Registration Year</h2>
            <p>{docObj?.registration_year || "N/A"}</p>
          </div>
        </div>
        <div className="eduInfo">
          <MdEdit
            className="ml-auto cursor-pointer"
            size={23}
            onClick={() => {
              history.push("/register/education-qualification");
            }}
          />
          <div>
            <h2>Educational Qualification </h2>
            <p style={{ textWrap: "nowrap" }}>
              {docObj?.specialization || "NA"}
            </p>
          </div>
          <div>
            <h2>College </h2>
            <p>{docObj?.highest_education || "NA"} </p>
          </div>
          <div>
            <h2>Completion Year</h2>
            <p>{docObj?.college_completion_year || "N/A"}</p>
          </div>
        </div>
      </div>
      <div className="doctorInfo">
        <div className="userDoctor">
          <img src={docObj?.image || docImg} alt="" />
          <div>
            <h2>Dr {docObj?.name}</h2>
            <p>{docObj?.specialization}</p>
          </div>
        </div>
        <div className="verifyProfile">
          <h2>
            {docObj?.status === "requested" && (
              <IoMdWarning color="#F4A93C" size={23} />
            )}
            {docObj?.status === "requested"
              ? "Profile is under Verification"
              : docObj?.status === "approved"
              ? "Profile has been Verified"
              : ""}
          </h2>
          {docObj?.status === "requested" && (
            <p>
              This profile is currently under verification. Our team is
              reviewing the documents you've uploaded. You will be notified once
              the verification process is complete. Thank you for your patience.
            </p>
          )}
          <Button onClick={updateOnboardingDetails}>
            Update Onboarding Details
          </Button>
        </div>
      </div>
      <ImageUploadModel
        show={showImageMoodel}
        handleClose={handleCloseImage}
        handleImageUploadSuccess={handleImageUploadSuccess}
        selectedMember={signUpData}
      />
    </MyProfileStyled>
  );
};

export default MyProfile;
