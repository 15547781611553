import React, { useState, useEffect } from "react";
import { OrderStyled } from "./Orders.Styled";
import SearchOrder from "./SearchOrder";
import OrderDetail from "./Orderdetail";
import { useDispatch } from "react-redux";
import { getDoctorOrdersAPI } from "../../redux/slices/doctor/doctorService";

const Orders = () => {
  const [searchQuery, setSearchQuery] = useState<{
    text: string;
    status: string;
  }>({ text: "", status: "" });
  const [clientOrders, setClientOrders] = useState<any[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchClientOrders = async () => {
      const res = await dispatch(getDoctorOrdersAPI());
      const resJson = JSON.parse(JSON.stringify(res));
      console.log("fetchClientOrders : ", resJson?.payload?.data);

      if (resJson?.payload?.data) {
        // Extracting bookings from client orders
        const ordersWithBookings = resJson.payload.data.flatMap((client: any) =>
          client.bookings.map((booking: any) => ({
            ...booking,
            clientName: client.first_name, // Adding client name for display/filtering
            clientEmail: client.email,
          }))
        );
        setClientOrders(ordersWithBookings);
      }
    };
    fetchClientOrders();
  }, [dispatch]);

  useEffect(() => {
    let orders = clientOrders;

    // Filter by client name or email
    if (searchQuery.text) {
      orders = orders.filter(
        (order) =>
          order.clientName
            .toLowerCase()
            .includes(searchQuery.text.toLowerCase()) ||
          order.clientEmail
            .toLowerCase()
            .includes(searchQuery.text.toLowerCase())
      );
    }

    // Filter by status
    if (searchQuery.status) {
      orders = orders.filter((order) => order.status === searchQuery.status);
    }

    setFilteredOrders(orders);
  }, [searchQuery, clientOrders]);

  return (
    <OrderStyled>
      <div className="order-page">
        <h2 className="sectionHeading">Orders</h2>
        <SearchOrder
          searchQuery={searchQuery.text}
          setSearchQuery={setSearchQuery}
          clientOrders={clientOrders}
        />
        <OrderDetail clientOrders={filteredOrders} />
      </div>
    </OrderStyled>
  );
};

export default Orders;
